import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import AccommodationNeeds from './accommodation-needs-form'
import AccommodationUtility from './accommodation-appliances-form'
import AccommodationDamp from './accommodation-damp'
import UtilityFeesHelp from './utility-fees-help-form'
import UtilityNeeds from './utility-needs-form'
import Notes from '../../form/application/notes'

const ForthStepForm = () => {
  return (
    <VerticalSpace>
      <AccommodationNeeds />
      <AccommodationUtility />
      <AccommodationDamp />
      <UtilityFeesHelp />
      <UtilityNeeds />
      <Notes formItemName={'page4notes'} />
    </VerticalSpace>
  )
}

export default ForthStepForm
