import React, { useCallback } from 'react'
import { Checkbox, Col, Form, Row } from 'antd'
import { FormItem } from '../../../form/form-item'
import { Input } from '../../../input'

const ReasonsForNotBeingAccounted = ({ nameItem, row }) => {
  const form = Form.useFormInstance()

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForNotBeing = Form.useWatch(nameItem, {
    preserve: true,
  })

  // eslint-disable-next-line no-unused-vars
  const selectPoliclinicServingTheAreaInput = Form.useWatch(
    'policlinicServingTheAreaInput',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeHouseholdNonRegisteredPolyclinicReasons = useCallback(
    (checked, row) => {
      if (checked) {
        form.setFieldsValue({
          householdNonRegisteredPolyclinicReasons: [
            ...form.getFieldValue('householdNonRegisteredPolyclinicReasons'),
            { id: row.id, personId: null, comment: null },
          ],
        })
      } else {
        form.setFieldsValue({
          householdNonRegisteredPolyclinicReasons: form
            .getFieldValue('householdNonRegisteredPolyclinicReasons')
            .filter((r) => r.id !== row.id),
        })
      }
    },
  )

  const onInputPoliclinicServingTheAreaInput = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdNonRegisteredPolyclinicReasons')
        .findIndex((r) => r.id === id)
      const newData = [
        ...form.getFieldValue('householdNonRegisteredPolyclinicReasons'),
      ]
      newData[index].comment = value
      form.setFieldsValue({ householdNonRegisteredPolyclinicReasons: newData })
    },
    [form],
  )

  return (
    <FormItem
      name={[nameItem, row.label]}
      key={row.id}
      style={{ marginLeft: '25px' }}
    >
      <FormItem
        name="householdNonRegisteredPolyclinicReasons"
        hidden
        initialValue={[]}
      />

      <FormItem name={[nameItem, row.label, 'id']} valuePropName="checked">
        <Checkbox
          onChange={(e) => {
            onChangeHouseholdNonRegisteredPolyclinicReasons(
              e.target.checked,
              row,
            )
            form.resetFields([`policlinicServingTheAreaInput${row.id}`])
          }}
          value={row.id}
        >
          {row.name}
        </Checkbox>
      </FormItem>

      {selectReasonsForNotBeing?.[row.label]?.id ? (
        row?.hasComment === true ? (
          <Row
            style={{
              marginTop: '20px',
              marginLeft: '25px',
            }}
          >
            <Col span={9}>
              <FormItem
                rules={[{ required: true }]}
                name={`policlinicServingTheAreaInput${row.id}`}
              >
                <Input
                  placeholder={'Նշել'}
                  onChange={(e) =>
                    onInputPoliclinicServingTheAreaInput(e.target.value, row.id)
                  }
                />
              </FormItem>
            </Col>
          </Row>
        ) : (
          <FormItem hidden />
        )
      ) : (
        <FormItem hidden />
      )}
    </FormItem>
  )
}

export default ReasonsForNotBeingAccounted
