import React, { useCallback } from 'react'
import { useApplicationSteps } from '../../context/applicaton-steps-context'
import { Form, Input } from 'antd'
import { SubmitButton } from '../button'
import { VerticalFormSpace } from '../space/vertical-form-space'
import {
  createUrlForCurrentStep,
  useSaveApplication,
} from '../../api/application/use-save-application'
import { errorMessage, successModal } from '../../helpers/utils'
import { FormItem } from '../form/form-item'
import {
  modifyPage10Data,
  modifyPage12Data,
  modifyPage14Data,
  modifyPage2Data,
  modifyPage3Data,
  modifyPage7Data,
  transformBeforeDataToArray,
  transformDataArray,
  transformDataToArray,
  transformObjectToList,
} from './utils'
import { useLocation } from 'react-router-dom'

const FillApplicationFormWrapper = ({ children, ...props }) => {
  const { step, jump } = useApplicationSteps()
  const location = useLocation()
  const data = location.state
  const [form] = Form.useForm()
  const id = form.getFieldValue('appId')
  const { mutate } = useSaveApplication(createUrlForCurrentStep(step + 1), {
    onSuccess: (data) => {
      if (data.data.serialNumber) {
        successModal(data.data.serialNumber)
        return
      }
      if (data.data) {
        form.setFieldsValue({
          appId: data.data,
        })
        if (step === 0) {
          form.setFieldsValue({
            appId: data.data.householdId,
          })
        }
      }
      if (step !== 14) jump(step + 1)
    },
    onError: (error) => {
      errorMessage(error)
    },
  })

  const onFinish = useCallback(
    (values) => {
      if (step === 0) {
        const dataToSave = {
          householdId: data?.householdId || null,
          location: values?.location || {
            longitude: null,
            latitude: null,
            address: '',
          },
          personAgreement: {
            id: values?.personAgreement.id,
            label: values?.personAgreement.label,
          },
          userEmail: values?.userEmail,
          personName: values.personName,
          personSurname: values?.personSurname,
          personPatronymic: values?.personPatronymic,
          periodId: values?.possibleDate?.id || null,
          periodComment: values?.possibleDateComment || null,
          isYourEmail: values?.isYourEmail || null,
          emailOwnerName: values?.emailOwnerName || null,
          emailFamilyMemberTypeId: values?.emailFamilyMemberTypeId || null,
          emailFamilyMemberTypeComment:
            values?.emailFamilyMemberTypeComment || null,
          rejectionReason: {
            ...values?.rejectionReason,
            comment: values?.rejectionReasonComment || '',
          },
          document: values?.fileBase64 || null,
          phoneNumbers: values?.phoneNumberList || [],
          note: values?.page1notes || '',
        }
        mutate(dataToSave)
      }
      if (step === 1) {
        let dataToSave = modifyPage2Data(values, id)
        mutate({
          ...dataToSave,
        })
      }

      if (step === 2) {
        const dataToSave = modifyPage3Data(values)
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 3) {
        let dataToSave = {
          householdId: id,
          accommodationAdaptation: values?.accommodationAdaptation,
          stateId: values?.prefferedLocation?.regionId || null,
          communityId: values?.prefferedLocation?.communityId || null,
          isDamp:
            values.isDamp === 1
              ? true
              : values.isDamp === 2
                ? false
                : values.isDamp === 3
                  ? null
                  : undefined,
          notes: values?.page4notes || '',
          householdAccommodationRequirements:
            values?.householdAccommodationRequirements
              ? Object.values(values?.householdAccommodationRequirements)
                  .filter((item) => item?.id)
                  .map((item) => {
                    return {
                      id: item.id,
                      comment: item.comment ? item.comment.toString() : '',
                    }
                  })
              : [],
          ...(values.state ? { stateId: values.state } : {}),
          ...(values.community ? { communityId: values.community } : {}),
          ...(values.address ? { addressId: values.address } : {}),
          hasUtilityServiceSupport: values?.hasUtilityServiceSupport || null,
          hasHousekeepingNeeds: values?.hasHousekeepingNeedsRadio,
          changeAccommodationReason: values?.changeAccommodationReason || '',
          utilityServices: values.utilityServices
            ? Object.values(values.utilityServices)
            : [],
          products: values.products
            ? transformObjectToList(values.products).filter(
                (item) => item !== null,
              )
            : [],
          accommodationAppliances: values?.accommodationAppliances,
        }
        mutate({ ...dataToSave })
      }

      if (step === 4) {
        let dataToSave = {
          householdId: id,
          foodSuppliesPeriodId: values.foodSuppliesPeriodId,
          useCash: values.useCashValue || false,
          useCashless: values.useCashlessValue || false,
          useCredit: values.useCreditValue || false,
          foodActions: values.foodActionsValue,
          foodSource: values.foodSourceValue,
          foodStrategies: values.foodStrategiesValues,
        }

        mutate(dataToSave)
      }

      if (step === 9) {
        const dataToSave = modifyPage10Data(values)
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 6) {
        const dataToSave = modifyPage7Data(
          values?.householdPersonEducationTypes,
        )
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 5) {
        let dataToSave = {
          householdId: id,
          // 1 in block
          personHealthNeeds: values.personHealthNeeds,
          // 2 rd block
          personHealthSituations: values.personHealthSituations,
          // 3 rd block
          visitDoctor: values.visitDoctor,
          householdNonVisitDoctorReasons:
            values.visitDoctor === false
              ? values.householdNonVisitDoctorReasons
              : [],
          registeredPolyclinicId:
            values.visitDoctor === true ? values.registeredPolyclinicId : null,
          isSatisfiedPolyclinic:
            values.registeredPolyclinicId === 1
              ? values.isSatisfiedPolyclinic === 1
                ? true
                : false
              : null,
          householdNonSatisfiedPolyclinicReasons:
            values.isSatisfiedPolyclinic === 1
              ? values.householdNonSatisfiedPolyclinicReasons
              : [],
          householdNonRegisteredPolyclinicReasons:
            values.registeredPolyclinicId === 2 ||
            values.registeredPolyclinicId === 3
              ? values.householdNonRegisteredPolyclinicReasons.filter(
                  (item) => item?.personId,
                )
              : [],
          // 4 rd block
          hasChildrenHealthProblem: values.hasChildrenHealthProblem,
          childrenVisitPolyclinic:
            values.hasChildrenHealthProblem === 2 ||
            values.hasChildrenHealthProblem === 3
              ? null
              : values.childrenVisitPolyclinic,
          householdNonChildrenVisitPolyclinicReasons:
            values.hasChildrenHealthProblem === 1 ||
            values.hasChildrenHealthProblem === 3
              ? []
              : values.householdNonChildrenVisitPolyclinicReasons,
          // 5 rd block
          householdPharmacyTypes: values.householdPharmacyTypes,
        }

        mutate(dataToSave)
      }

      if (step === 7) {
        let dataToSave = {
          householdId: id,
          personWorks: transformDataArray(values?.personWorks),
        }

        mutate(dataToSave)
      }

      if (step === 8) {
        let dataToSave = {
          householdId: id,
          beforeMonthlyIncomePeriodId: values?.beforeMonthlyIncomePeriodId,
          afterMonthlyIncomePeriodId: values?.afterMonthlyIncomePeriodId,
          householdBeforeIncomeTypes: transformBeforeDataToArray(
            values?.householdBeforeIncomeTypes,
          ),
          householdAfterIncomeTypes: transformDataToArray(
            values?.householdAfterIncomeTypes,
          ),
          householdHaveToActions: values?.householdHaveTypeValue,
          householdGoods: values?.householdGoodsValues,
        }

        mutate(dataToSave)
      }

      if (step === 10) {
        let dataToSave = {
          householdId: id,
          isDifficultyLanguage: values.isDifficultyLanguage,
          householdDifficultyLanguages:
            values.isDifficultyLanguage === true
              ? values.householdDifficultyLanguages
              : [],
          hasForeignLanguage: values.hasForeignLanguage,
          hasDrivingLicense: values.hasDrivingLicense,
          householdDrivingLicenses:
            values.hasDrivingLicense === true
              ? values.householdDrivingLicenses
              : [],
          householdLanguages:
            values.hasForeignLanguage === true ? values.householdLanguages : [],
          householdPersonAbilityQuestions:
            values.householdPersonAbilityQuestions,
        }

        mutate(dataToSave)
      }

      if (step === 11) {
        const dataToSave = modifyPage12Data(values)
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 12) {
        let dataToSave = {
          householdId: id,
          question132AnswareId: values.question132AnswareId,
          question132AnswareComment: values.question132AnswareComment
            ? values.question132AnswareComment
            : null,
          question134AnswareId: values.question134AnswareId,
          question134AnswareComment: values.question134AnswareComment
            ? values.question134AnswareComment
            : null,
          question135AnswareId: values.question135AnswareId,
          householdCitizenshipCircumstances:
            values.citizenshipCircumstancesValue,
          states: values.states ? values.states : null,
          area: values.area ? values.area : null,
        }

        mutate(dataToSave)
      }

      if (step === 13) {
        const dataToSave = modifyPage14Data(values)

        mutate({ ...dataToSave, householdId: id })
      }
      if (step === 14) {
        let dataToSave = {
          ...values,
          householdId: id,
          householdObservationDirections:
            values.householdObservationDirections.filter(
              (item) => item?.answerId !== undefined && item?.id !== undefined,
            ),
          householdResidenceConditions:
            values?.householdResidenceConditions.filter(
              (item) => item?.answerId !== undefined,
            ),
          householdInformationAffectedFactors:
            values?.householdInformationAffectedFactors.filter(
              (item) => item?.id !== undefined,
            ),
          householdTerminationReasons:
            values?.householdTerminationReasons?.filter(
              (item) => item?.terminationReasonId !== undefined,
            ),
          householdWorkerActions: transformObjectToList(
            values.householdWorkerActions,
          ).filter((item) => item !== null || undefined),
          householdAnyFamilyMember: values.householdAnyFamilyMember,
        }
        mutate(dataToSave)
      }
    },
    [data, id, mutate, step],
  )

  return (
    <Form
      name="application-fill-form"
      layout="vertical"
      onFinish={onFinish}
      form={form}
      {...props}
    >
      {children}
      <VerticalFormSpace>
        <SubmitButton
          submitText={step === 14 ? 'Ավարտել' : 'Հաջորդ'}
          canceltext="Նախորդ"
          showCancelButton={false}
          onCancel={() => jump(step - 1)}
          step={step}
        />
      </VerticalFormSpace>

      <FormItem name={'appId'}>
        <Input type={'hidden'} />
      </FormItem>
    </Form>
  )
}

export default FillApplicationFormWrapper
