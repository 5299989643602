import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import CurrentResidence from './current-residence'
import AccommodationUseType from './accommodation-use-type'
import StayPeriod from './stay-period'
import AccommodationRiskType from './accommodation-risk-type'
// import BuildingTypes from './building-types'
import NonSafeReasons from './non-safe-reasons'
import { Form } from 'antd'
import BuildingsNearby from './buildings-nearby'

const ThirdStepForm = () => {
  const accommodationUseTypeId = Form.useWatch(['accommodationUseTypeId'])
  const accommodationTypeId = Form.useWatch(['accommodationTypeId'])
  const isHideUseType = [4, 9, 10, 11].includes(accommodationTypeId)
  const hasNoAccommodation = accommodationTypeId === 12

  return (
    <VerticalSpace>
      <CurrentResidence />

      {hasNoAccommodation === false && isHideUseType === false && (
        <AccommodationUseType />
      )}

      {hasNoAccommodation === false && <StayPeriod />}

      {hasNoAccommodation === false && accommodationUseTypeId !== 1 && (
        <AccommodationRiskType />
      )}

      {hasNoAccommodation === false && <BuildingsNearby />}
      {/* {hasNoAccommodation === false && <BuildingTypes />} */}

      {hasNoAccommodation === false && <NonSafeReasons />}
    </VerticalSpace>
  )
}

export default ThirdStepForm
