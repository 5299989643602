import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_ACCOMMONDATION_RISK_TYPE } from './constants'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions from '../../../api/application/use-get-questions'
import NestedRadio from './nested-radio'

const AccommodationRiskType = () => {
  const { data, isLoading } = useGetQuestions(URL_ACCOMMONDATION_RISK_TYPE)

  const selectedId = Form.useWatch(['accommodationRiskTypeId'])

  return (
    <FormSectionCard
      title="Արդյոք հանգիստ եք, որ Ձեր ներկայիս կացարանից Ձեզ չեն վտարի կամ ստիպված չեք լինի հեռանալ"
      showRequired={false}
    >
      <Spin spinning={isLoading}>
        <FormItem name="accommodationRiskTypeId" rules={[{ required: true }]}>
          <Radio.Group>
            <VerticalSpace>
              {data.map((row) => (
                <NestedRadio
                  row={row}
                  key={row.id}
                  level={1}
                  nestedFormItemName="nestedAccommodationRiskTypeId"
                  selectedId={selectedId}
                />
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
      </Spin>
    </FormSectionCard>
  )
}

export default AccommodationRiskType
