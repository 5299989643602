import { Layout } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../helpers/constants'
import { ApplicationSteps } from '../steps/application-steps'
import { SidebarProfile } from '../Profile/SidebarProfile'

const { Sider: SiderComponent } = Layout

const Sider = styled(SiderComponent)`
  padding-top: 145px;
  && {
    background-color: ${COLORS.PRIMARY.BLUE_LIGHT};
    box-shadow: 4px 4px 10px rgba(71, 26, 127, 0.1);
    min-height: 100%;
  }

  .ant-steps {
    padding-left: 35px;
  }

  &.ant-layout-sider-collapsed {
    .ant-steps {
      padding-left: 20px;
    }
  }
  @media (max-width: 801px) {
    position: ${(props) =>
      props.iscollapsible ? 'static' : 'absolute !important'};
    z-index: ${(props) => !props.iscollapsible && 10};
  }
`

export const ApplicationSider = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 801)
  return (
    <Sider
      width={window.innerWidth < 801 ? '100%' : 400}
      collapsible
      iscollapsible={collapsed}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      {!collapsed && <SidebarProfile />}
      <ApplicationSteps collapsed={collapsed} />
    </Sider>
  )
}
