import React from 'react'
import { VerticalSpace } from '../../components/space/vertical-space'
import { ContentWrapper } from '../../components/layouts/content-wrapper'
import FillApplicationFormWrapper from '../../components/fill-application/fill-application-form-wrapper'
import ManageSteps from './manage-steps'

const FillApplication = () => {
  return (
    <VerticalSpace>
      <ContentWrapper style={{ padding: '40px 24px 64px' }} fullHeight>
        <FillApplicationFormWrapper>
          <ManageSteps />
        </FillApplicationFormWrapper>
      </ContentWrapper>
    </VerticalSpace>
  )
}

export default FillApplication
