import React, { useCallback } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Col, Form, Radio, Row, Spin } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button } from '../../button'
import { COLORS } from '../../../helpers/constants'
import { URL_GET_QUESTIONS } from './constants'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Text } from '../../typography'
import { FormItem } from '../../form/form-item'
import { Select } from '../../select'
import { VerticalSpace } from '../../space/vertical-space'

//! Page should be rewritten
const RequestMemberName = ({ dataFamily }) => {
  const form = Form.useFormInstance()
  const { data: dataQuestions, isLoading } = useGetQuestions(URL_GET_QUESTIONS)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSelectRequestMemberData = useCallback(
    (checked, id) => {
      const index = form
        .getFieldValue('householdPersonAbilityQuestions')
        ?.findIndex((r) => r.rowId === id)

      const newData = [...form.getFieldValue('householdPersonAbilityQuestions')]

      if (index === undefined || newData.length === 0 || index === -1) {
        if (checked) {
          form.setFieldsValue({
            householdPersonAbilityQuestions: [
              ...form.getFieldValue('householdPersonAbilityQuestions'),
              {
                rowId: id,
                personId: checked,
                abilityQuestions: [],
              },
            ],
          })
        } else {
          form.setFieldsValue({
            householdPersonAbilityQuestions: form
              .getFieldValue('householdPersonAbilityQuestions')
              .filter((r) => r.rowId !== id),
          })
        }
      } else {
        newData[index].personId = checked
        form.setFieldsValue({
          householdPersonAbilityQuestions: newData,
        })
      }
    },
    [form],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSelectRadioGroup = useCallback(
    (checked, rowId, id) => {
      const index = form
        .getFieldValue('householdPersonAbilityQuestions')
        ?.findIndex((r) => r.rowId === id)

      const newData = [...form.getFieldValue('householdPersonAbilityQuestions')]

      if (index === undefined || newData.length === 0 || index === -1) {
        if (checked) {
          form.setFieldsValue({
            householdPersonAbilityQuestions: [
              ...form.getFieldValue('householdPersonAbilityQuestions'),
              {
                rowId: id,
                personId: null,
                abilityQuestions: [
                  {
                    abilityQuestionId: rowId,
                    answearId: checked,
                  },
                ],
              },
            ],
          })
        } else {
          form.setFieldsValue({
            householdPersonAbilityQuestions: form
              .getFieldValue('householdPersonAbilityQuestions')
              .filter((r) => r.rowId !== id),
          })
        }
      } else {
        const existingIndex = newData[index].abilityQuestions.findIndex(
          (item) => item.abilityQuestionId === rowId,
        )

        if (existingIndex > -1) {
          newData[index].abilityQuestions[existingIndex] = {
            abilityQuestionId: rowId,
            answearId: checked,
          }
        } else {
          newData[index].abilityQuestions.push({
            abilityQuestionId: rowId,
            answearId: checked,
          })
        }

        form.setFieldsValue({
          householdPersonAbilityQuestions: newData,
        })
      }
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormItem
        name="householdPersonAbilityQuestions"
        hidden
        initialValue={[]}
      />

      <Form.List name="requestMemberItems" initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => (
            // eslint-disable-next-line react/jsx-key
            <FormSectionCard
              title={
                fields.length > 1 && index !== 0 ? (
                  <Col
                    span={2}
                    style={{ alignSelf: 'center', marginBottom: '-10px' }}
                  >
                    <Button
                      style={{ borderRadius: '0' }}
                      onClick={() => {
                        remove(field.name)
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                ) : (
                  <Col
                    span={2}
                    style={{ alignSelf: 'center', marginBottom: '-10px' }}
                  >
                    <Button style={{ borderRadius: '0' }} onClick={() => add()}>
                      <PlusOutlined
                        style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
                      />
                    </Button>
                  </Col>
                )
              }
              showRequired={false}
              style={{ marginBottom: '20px' }}
            >
              <Row gutter={16} key={index}>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  style={{ alignSelf: 'center' }}
                >
                  <Text
                    style={{ fontSize: '20px' }}
                    color={COLORS.PRIMARY.BLUE}
                  >
                    Ցանկից ընտրել հարցվող անդամի անունը
                  </Text>
                  <FormItem
                    name={[field.name, 'personId']}
                    rules={[{ required: true }]}
                    style={{ marginTop: '20px' }}
                  >
                    <Select
                      placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                      onChange={(e) => {
                        changeSelectRequestMemberData(e, index)
                      }}
                    >
                      {dataFamily?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.firstName + ' ' + item.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24} style={{ alignSelf: 'center' }}>
                  {dataQuestions?.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <FormItem
                          name={['requestMemberFamilyRadio', item.name]}
                          style={{ width: '100%' }}
                          label={
                            <Text color={COLORS.PRIMARY.BLUE}>
                              {item.id}. {item.name}
                            </Text>
                          }
                        >
                          {item?.hasComment === true ? (
                            <FormItem
                              name={[
                                field.name,
                                `requestMemberFamilyRadio[${item.id}][]`,
                              ]}
                              rules={[{ required: true }]}
                              style={{ marginLeft: '20px' }}
                            >
                              <Radio.Group
                                onChange={(e) => {
                                  changeSelectRadioGroup(
                                    e.target.value,
                                    item.id,
                                    index,
                                  )
                                }}
                              >
                                <VerticalSpace>
                                  <Radio value={1}>
                                    Ոչ, ոչ մի դժվարություն
                                  </Radio>
                                  <Radio value={2}>
                                    Այո, որոշ դժվարություններ
                                  </Radio>
                                  <Radio value={3}>
                                    Այո, շատ դժվարություններ
                                  </Radio>
                                  <Radio value={4}>Ընդհանրապես չեմ կարող</Radio>
                                </VerticalSpace>
                              </Radio.Group>
                            </FormItem>
                          ) : (
                            <FormItem
                              name={[
                                field.name,
                                `requestMemberFamilyYesNo${item.id}`,
                              ]}
                              style={{ marginLeft: '20px' }}
                            >
                              <Radio.Group
                                onChange={(e) => {
                                  changeSelectRadioGroup(
                                    e.target.value,
                                    item.id,
                                    index,
                                  )
                                }}
                              >
                                <VerticalSpace>
                                  <Radio value={1}>Այո</Radio>
                                  <Radio value={2}>Ոչ</Radio>
                                  <Radio value={3}>
                                    Չի ցանկանում պատասխանել
                                  </Radio>
                                </VerticalSpace>
                              </Radio.Group>
                            </FormItem>
                          )}
                        </FormItem>
                      </React.Fragment>
                    )
                  })}
                </Col>
              </Row>
            </FormSectionCard>
          ))
        }
      </Form.List>
    </Spin>
  )
}

export default RequestMemberName
