import React, { useEffect, useMemo, useState } from 'react'
import { Select } from '.'
import { errorMessage } from '../../helpers/utils'
import { URL_HOUSE_PERSONS } from '../fill-application/seventh-step/constants'
import { client } from '../../api/client'
import { Form } from 'antd'

export const FamilyPersons = ({ name, onChange, mode = '' }) => {
  const [data, setData] = useState([])
  const form = Form.useFormInstance()
  const fillPersons = form
    ?.getFieldValue(name)
    ?.map((person) => person?.personId)
    .filter(Boolean)

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await client.get(URL_HOUSE_PERSONS, {
          params: { householdId: +form.getFieldValue('appId') },
        })
        setData(response.data)
      } catch (error) {
        errorMessage(error)
      }
    }

    getData()
  }, [form])

  const selectProps = useMemo(
    () => ({
      mode,
      style: { width: 220 },
      maxTagCount: 'responsive',
      placeholder: 'Նշել անդամի տվյալները',
      options: data.map((person) => ({
        label: `${person.firstName} ${person.lastName}`,
        value: person.id,
        disabled: fillPersons?.includes(person.id),
      })),
      onChange,
    }),
    [data, onChange, fillPersons, mode],
  )

  return <Select {...selectProps} />
}
