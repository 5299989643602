import React from 'react'
import { Radio } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'

const NestedRadio = ({ row, level, selectedId, nestedFormItemName }) => {
  const hasChildren = !!row.children.length

  return (
    <React.Fragment>
      <Radio value={row.id}>{row.name}</Radio>

      {selectedId === row.id && hasChildren && (
        <FormItem
          name={nestedFormItemName}
          rules={[{ required: true }]}
          style={{ paddingLeft: 30 * level, margin: '12px 0 0' }}
        >
          <Radio.Group>
            <VerticalSpace>
              {row.children.map((nestedRow) => (
                <NestedRadio
                  key={nestedRow.id}
                  row={nestedRow}
                  level={level + 1}
                />
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
      )}
    </React.Fragment>
  )
}

export default NestedRadio
