import React, { useCallback, useEffect, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Spin, Checkbox, Radio } from 'antd'
import { FormItem } from '../../form/form-item'
import { Form } from '../../form/form'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_SUPPORT_ENTITY } from './constants'
import { createName } from '../../../helpers/utils'
import { Input } from '../../input'
import { Title } from '../../typography'
import { VerticalSpace } from '../../space/vertical-space'
import { EntitySupportTypes } from './entity-support'

const LastMonthSupport = () => {
  const { data, isLoading } = useGetQuestions(URL_SUPPORT_ENTITY)
  const form = Form.useFormInstance()

  const [supportStructures, setSupportStructures] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, parentName) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [...parentName, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form],
  )

  useEffect(() => {
    // item9 - Չի ցանկանում պատասխանել
    if (supportStructures?.item9 && Object.keys(supportStructures).length > 1) {
      form.setFields([
        {
          name: ['hoseholdSupportEntities'],
          value: { item9: { id: 9 } },
        },
      ])
      setSupportStructures({ item9: true })
    } else if (supportStructures?.item9) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [form, supportStructures])

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items, state)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={state[itemName]}
              disabled={disableCheckboxes && item.id !== 9}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  setState,
                  parentName,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          <FormItem name={[...parentName, itemName, 'id']} hidden />
          {!!(state[itemName] && level === 0 && item.id < 7) && (
            <>
              <EntitySupportTypes parentName={[...parentName, itemName]} />
              <FormItem
                name={[...parentName, itemName, 'continue']}
                style={{ marginLeft: marginLeft + 20 }}
                label={
                  <Title>
                    Արդյո՞ք առաջիկա 30 օրերի ընթացքում շարունակելու եք ստանալ
                    այդ աջակցությունը
                  </Title>
                }
              >
                <Radio.Group>
                  <VerticalSpace>
                    <Radio value={1}>Այո</Radio>
                    <Radio value={2}>Ոչ</Radio>
                    <Radio value={3}>Չգիտեմ</Radio>
                  </VerticalSpace>
                </Radio.Group>
              </FormItem>
            </>
          )}

          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard
      title="Վերջին 1 ամիսների ընթացքում ո՞ր կառույցներից/անձանցից եք ստացել աջակցություն"
      showRequired={false}
    >
      <Spin spinning={isLoading}>
        <FormItem name="hoseholdSupportEntities">
          {renderItems(data, 0, supportStructures, setSupportStructures, [
            'hoseholdSupportEntities',
          ])}
        </FormItem>
      </Spin>
    </FormSectionCard>
  )
}

export default LastMonthSupport
