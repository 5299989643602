import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'

import EducationQuestions from './education-questions'

export const SeventhStep = () => {
  return (
    <VerticalSpace>
      <EducationQuestions />
    </VerticalSpace>
  )
}
