import { AUTH_KEYS, PATHS } from '../helpers/constants'
import axios from 'axios'
import { URL_REFRESH_TOKEN } from './auth/constants'
import { isAccessTokenExpired } from '../helpers/utils'

export const client = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
})

client.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(AUTH_KEYS.TOKEN) || ''
    if (accessToken) {
      config.headers.Authorization = `${accessToken}`
    }

    if (accessToken && isAccessTokenExpired(accessToken)) {
      try {
        const response = await axios.post(
          // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_BASE_URL}${URL_REFRESH_TOKEN}`,
          {
            accessToken,
            refreshToken: localStorage.getItem(AUTH_KEYS.REFRESH_TOKEN) || '',
          },
        )
        const { accessToken: refreshToken } = response.data
        localStorage.setItem(AUTH_KEYS.TOKEN, refreshToken)
        config.headers.Authorization = `Bearer ${refreshToken}`
      } catch (error) {
        localStorage.removeItem(AUTH_KEYS.TOKEN)
        localStorage.removeItem(AUTH_KEYS.USER)
        window.location.href = ''
      }
    }

    return config
  },
  (error) => Promise.reject(error),
)

// Add a response interceptor
client.interceptors.response.use(
  async function (response) {
    return response
  },
  async function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem(AUTH_KEYS.USER)
      localStorage.removeItem(AUTH_KEYS.TOKEN)
      localStorage.removeItem(AUTH_KEYS.REFRESH_TOKEN)
      window.location.href = PATHS.SIGN_IN
    }
    return Promise.reject(error)
  },
)
