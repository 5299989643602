import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { Input } from '../../input'
import { VerticalSpace } from '../../space/vertical-space'
import { Form } from '../form'
import { FormItem } from '../form-item'
import { SecondaryText } from '../../typography'
import { Password } from '../../input/password'
import { Button } from '../../button'
import { PATHS } from '../../../helpers/constants'
import { Link } from 'react-router-dom'
import { useSignIn } from '../../../api/auth/use-sign-in'

export const SignInForm = () => {
  const [form] = Form.useForm()
  const { mutate } = useSignIn()

  const onFinish = (values) => {
    mutate(values)
  }
  return (
    <Form
      name="signin"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      requiredMark={false}
    >
      <VerticalSpace size="large">
        <FormItem
          name="username"
          label="Մուտքանուն"
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input placeholder="մուտքանուն" prefix={<UserOutlined />} />
        </FormItem>
        <FormItem
          name="password"
          label="Գաղտնաբառ"
          rules={[{ required: true, min: 6 }]}
          extra={
            <Link to={PATHS.FORGOT_PASSWORD}>
              <SecondaryText>Մոռացել եմ գաղտնաբառը</SecondaryText>
            </Link>
          }
        >
          <Password placeholder="գաղտնաբառ" prefix={<LockOutlined />} />
        </FormItem>
        <VerticalSpace size="middle">
          <Button htmlType="submit" block type="primary" size="large">
            Մուտք
          </Button>
        </VerticalSpace>
      </VerticalSpace>
    </Form>
  )
}
