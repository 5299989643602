import React, { useCallback } from 'react'
import { FormItem } from '../../../form/form-item'
import { Form } from 'antd'
import { Input } from '../../../input'

const FmInput = ({ nameItem, row, style }) => {
  const form = Form.useFormInstance()

  const onInputChangeSupport = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('personHealthSituations')
        .findIndex((r) => r.healthSituationId === id)
      const newData = [...form.getFieldValue('personHealthSituations')]
      newData[index].comment = value
      form.setFieldsValue({ personHealthSituations: newData })
    },
    [form],
  )

  return (
    <FormItem rules={[{ required: true }]} name={nameItem} style={style}>
      <Input
        placeholder={'Նշել'}
        onChange={(e) => onInputChangeSupport(e.target.value, row.id)}
      />
    </FormItem>
  )
}

export default FmInput
