import React from 'react'
import { Radio } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { relocationPlans } from './constant'

export const RelocationPlans = () => {
  return (
    <FormSectionCard
      title={
        'Մոտակա տարվա ընթացքում ի՞նչ պլաններ ունեք ձեր և ձեր ընտանիքի տեղաշարժի հետ կապված'
      }
      showRequired={false}
    >
      <FormItem name="question135AnswareId" rules={[{ required: true }]}>
        <Radio.Group>
          <VerticalSpace>
            {relocationPlans.map((item) => {
              return (
                <Radio key={item.id} value={item.id}>
                  {item.label}
                </Radio>
              )
            })}
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}
