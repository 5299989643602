import { useQuery } from '@tanstack/react-query'
import { client } from '../client'

export const useGetPublic = (url, params, options = { enabled: true }) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.get(url, { params }),
    retry: false,
    ...options,
  })
  const { data, isSuccess } = result
  return {
    ...result,
    data: isSuccess ? data.data : [],
  }
}
