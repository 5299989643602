import { VerticalSpace } from '../../space/vertical-space'
// import { InteractingChildren } from './interacting-children'
import { DescribesNextSteps } from './describes-next-steps'
import { CitizenshipCircumstances } from './citizenship-circumstances'
import { ChanceChooseLivePermanently } from './chance-choose-live-permanently'
import { RelocationPlans } from './relocation-plans'

export const ThirteenthStep = () => {
  return (
    <VerticalSpace>
      {/*<InteractingChildren />*/}

      <DescribesNextSteps />

      <CitizenshipCircumstances />

      <ChanceChooseLivePermanently />

      <RelocationPlans />
    </VerticalSpace>
  )
}
