import { Col, Row } from 'antd'
import { SigninCard } from '../../components/card/signin-card'
import { SignInForm } from '../../components/form/sign-in/form'

export const SignIn = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
      <Col>
        <SigninCard>
          <SignInForm />
        </SigninCard>
      </Col>
    </Row>
  )
}
