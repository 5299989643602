import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_DATA_OF_CURRENT_RESIDANCE } from './constants'
import { Title } from '../../typography'
import { Form, Radio, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { VerticalSpace } from '../../space/vertical-space'
import { Input } from '../../input'
import { PersonAddress } from '../../form/person-address'
import useGetQuestions from '../../../api/application/use-get-questions'
import useEffectNotFirstRender from '../../../hooks/use-effect-not-first-render'

const CurrentResidence = () => {
  const { data, isLoading } = useGetQuestions(URL_DATA_OF_CURRENT_RESIDANCE)
  const selectedId = Form.useWatch(['accommodationTypeId'])
  const form = Form.useFormInstance()

  useEffectNotFirstRender(() => {
    form.resetFields(['accommodationTypeComment'])
  }, [selectedId, form])

  return (
    <FormSectionCard title="Ներկայիս բնակավայրի տվյալներ" showRequired={false}>
      <Spin spinning={isLoading}>
        <PersonAddress />

        <Title style={{ marginBottom: '20px' }}>Նշեք կացարանի տեսակ</Title>

        <FormItem name="accommodationTypeId" rules={[{ required: true }]}>
          <Radio.Group>
            <VerticalSpace>
              {data.map((row) => (
                <Radio key={row.id} value={row.id}>
                  {row.name}
                </Radio>
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>

        {selectedId === 13 && (
          <FormItem
            name="accommodationTypeComment"
            rules={[{ required: true }]}
          >
            <Input placeholder="Նշել" />
          </FormItem>
        )}
      </Spin>
    </FormSectionCard>
  )
}

export default CurrentResidence
