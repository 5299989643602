import { FormItem } from './form-item'
import { phonePattern } from '../../helpers/constants'

export const PhoneNumberFormItem = ({ rules = [], ...props }) => {
  return (
    <FormItem
      label="Հեռախոսի համար"
      tooltip="Թույլատրվող ֆորմատ +37400000000"
      rules={[
        {
          pattern: phonePattern,
          message: 'Սխալ հեռախոսի ֆորմատ, պետք է լինի +374XXXXXXXX ֆորմատով',
        },
        ...rules,
      ]}
      {...props}
    ></FormItem>
  )
}
