import React from 'react'
import { FormItem } from '../../form/form-item'
import { Title } from '../../typography'
import { Radio, Spin } from 'antd'
import { Form } from '../../form/form'
import BeforeWorkDescription from './before-work-description'
import useGetQuestions, {
  URL_GET_WORK_TYPES,
} from '../../../api/application/use-get-questions'
import { VerticalSpace } from '../../space/vertical-space'
import { Info } from '../../typography/info'
const BeforeRelocationWorkForm = ({ field }) => {
  const { data: questions, isLoading } = useGetQuestions(URL_GET_WORK_TYPES)

  const beforeHasWork = Form.useWatch(
    ['personWorks', field.name, 'beforeHasWork'],
    {
      preserve: true,
    },
  )

  return (
    <Spin spinning={isLoading}>
      <FormItem
        label={
          <VerticalSpace>
            <Title>Արդյո՞ք մինչև տեղահանումը իրականացրել է որևէ աշխատանք</Title>
            <Info
              text={
                'ՑՈՒՑՈՒՄ. Նշեք հարցվողի կամ ընտանիքի անդամների վերջին կատարած աշխատանքը/աշխատանքները)'
              }
            />
          </VerticalSpace>
        }
        name={[field.name, 'beforeHasWork']}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={1}>Այո</Radio>
          <Radio value={2}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {beforeHasWork === 1 && (
        <BeforeWorkDescription field={field} questions={questions || []} />
      )}
    </Spin>
  )
}

export default BeforeRelocationWorkForm
