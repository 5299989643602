import { useEffect, useState } from 'react'
import { SidebarProfile } from '../../Profile/SidebarProfile'
import Sider from 'antd/es/layout/Sider'
import { COLORS, MENU_TYPES } from '../../../helpers/constants'
import { Menu } from 'antd'
import styled from 'styled-components'
import { Link, useMatches } from 'react-router-dom'

const SideMenu = styled(Menu)`
  border-radius: 4px;
  color: rgb(69, 111, 174);
  background: #ffffff;
  &.ant-menu {  
    li.ant-menu-item {
    line-height: normal;
    box-shadow: -28px 22px 45px rgba(27, 29, 66, 0.139423);
    background: #ffffff;
      &-selected {
        background: #ffffff;
        border: 1px solid #0b847f; 
        background: #FFFFFF;
        border: 0.56px solid #E2DEF9;
        box-shadow: -28px 22px 45px rgba(27, 29, 66, 0.139423);
        a {
          color: rgb(69, 111, 174);
          background: #ffffff;

        }
        &:after {
          opacity: 0;
        }     
      }
      &-active {
        background-color: #ffffff
        border: 1px solid #ffffff;
        a {
          color: rgb(69, 111, 174); 

        } 
      }
    }
  }
`
export const SocEmployeeViewSider = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 801)
  const match = useMatches()

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth < 801)
    }
    // Add event listener to window resize event
    window.addEventListener('resize', handleResize)

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <Sider
      width={window.innerWidth < 801 ? '100%' : 400}
      collapsible
      collapsed={collapsed}
      style={{ backgroundColor: COLORS.PRIMARY.BLUE_LIGHT, zIndex: 10 }}
      onCollapse={(value) => setCollapsed(value)}
    >
      {!collapsed && <SidebarProfile />}
      <SideMenu
        disabled
        mode="inline"
        style={{
          borderRight: 0,
          background: 'transparent',
          padding: '28px 43px',
        }}
        selectedKeys={match.map((item) => item.pathname)}
        items={Object.entries(MENU_TYPES).map(([, menu]) => {
          return {
            key: menu.url,
            label: <Link to="#">{menu.label}</Link>,
            icon: menu.icon,
          }
        })}
      />
    </Sider>
  )
}
