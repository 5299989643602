import React, { useCallback, useState } from 'react'
import { Checkbox, Col, Form, Row, Spin } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { URL_GET_NON_VISIT_DOCTOR_REASON } from './constants'
import { Input } from '../../input'
import useGetQuestions from '../../../api/application/use-get-questions'

const ReasonForNotSeeingDoctor = () => {
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)

  const { data: seeingDoctorData, isLoading } = useGetQuestions(
    URL_GET_NON_VISIT_DOCTOR_REASON,
  )

  // eslint-disable-next-line no-unused-vars
  const selectFamilyNotSeeingDoctor = Form.useWatch(
    'reasonForNotSeeingDoctor',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeReasonForNotSeeingDoctor = useCallback((checked, row) => {
    if (checked) {
      form.setFieldsValue({
        householdNonVisitDoctorReasons: [
          ...form.getFieldValue('householdNonVisitDoctorReasons'),
          { id: row.id, comment: null },
        ],
      })
    } else {
      form.setFieldsValue({
        householdNonVisitDoctorReasons: form
          .getFieldValue('householdNonVisitDoctorReasons')
          .filter((r) => r.id !== row.id),
      })
    }
  })

  const onInputChangeNonVisitDoctor = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdNonVisitDoctorReasons')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('householdNonVisitDoctorReasons')]
      newData[index].comment = value
      form.setFieldsValue({ householdNonVisitDoctorReasons: newData })
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Ինչն է եղել բժշկին չդիմելու պատճառը"
        showRequired={false}
      >
        <FormItem
          name="householdNonVisitDoctorReasons"
          hidden
          initialValue={[]}
        />

        <FormItem
          name={['reasonForNotSeeingDoctor']}
          rules={[{ required: true }]}
        >
          {seeingDoctorData?.map((row) => {
            return (
              <FormItem
                name={['reasonForNotSeeingDoctor', row.label]}
                key={row.id}
              >
                <FormItem
                  name={['reasonForNotSeeingDoctor', row.label, 'id']}
                  valuePropName="checked"
                >
                  <Checkbox
                    // checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked)
                      onChangeReasonForNotSeeingDoctor(e.target.checked, row)
                      form.resetFields([
                        `reasonForNotSeeingDoctorInput${row.id}`,
                      ])
                    }}
                    value={row.id}
                  >
                    {row.name}
                  </Checkbox>
                </FormItem>
                {selectFamilyNotSeeingDoctor?.[row.label]?.id ? (
                  row?.hasComment === true ? (
                    <Row style={{ marginTop: '15px', marginLeft: '25px' }}>
                      <Col span={9}>
                        <FormItem
                          rules={[{ required: true }]}
                          name={`reasonForNotSeeingDoctorInput${row.id}`}
                        >
                          <Input
                            placeholder={'Նշել'}
                            onChange={(e) =>
                              onInputChangeNonVisitDoctor(
                                e.target.value,
                                row.id,
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  ) : (
                    <FormItem hidden />
                  )
                ) : (
                  <FormItem hidden />
                )}
              </FormItem>
            )
          })}
        </FormItem>
      </FormSectionCard>
    </Spin>
  )
}

export default ReasonForNotSeeingDoctor
