import { forwardRef } from 'react'
import { Select } from '.'
import { FIELD_NAMES_DEFAULT } from './constants'
import { URL_ALL_STATES } from '../../api/application/constants'
import { useGetPublic } from '../../api/application/use-get-public'

export const RegionsSelect = forwardRef(({ onChange, ...props }, ref) => {
  const { data } = useGetPublic(URL_ALL_STATES)
  return (
    <Select
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      options={data}
      placeholder="Ընտրել"
      onChange={onChange}
      {...props}
      ref={ref}
    />
  )
})
