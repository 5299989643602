import { Col, Row, Spin } from 'antd'
import { Datepicker } from '../datepicker'
import { Input } from '../input'
import { FormItem } from './form-item'
import { Select } from '../select'
import { createOptionsFromArray, errorMessage, noop } from '../../helpers/utils'
import { genders } from '../fill-application/constatns'
import { Form } from './form'
import { FormSectionCard } from '../card/form-section-card'
import { VerticalSpace } from '../space/vertical-space'
import useGetQuestions, {
  URL_GET_DOCUMENT_TYPES,
} from '../../api/application/use-get-questions'
import { Button } from '../button'
import { useEffect, useState } from 'react'
import useValidatePerson from '../../api/application/use-validate-person'
import { URL_VALIDATE_PERSON } from '../../api/application/constants'
import dayjs from 'dayjs'
import { URL_GET_DOCUMENT_AVAILABLE_TYPE } from '../fill-application/second-step/constants'

export const PersonMainData = ({
  title,
  formItemProp = [],
  parentFormItem = [],
  requireDocs = true,
}) => {
  const { data: hasDocumentOptions } = useGetQuestions(
    URL_GET_DOCUMENT_AVAILABLE_TYPE,
  )
  const [disableForm, setDisableForm] = useState(true)
  const [hasValidated, setHasValidated] = useState(false)
  const form = Form.useFormInstance()
  const hasDocumentId = Form.useWatch(
    [...parentFormItem, ...formItemProp.hasDocument.name],
    {
      preserve: true,
    },
  )
  const docNumber = Form.useWatch(
    [...parentFormItem, ...formItemProp.documentNumber.name],
    {
      preserve: true,
    },
  )

  const { data: documentTypes, isLoading } = useGetQuestions(
    URL_GET_DOCUMENT_TYPES,
  )

  useEffect(() => {
    if (hasDocumentId === 1 && !hasValidated) {
      form.resetFields([
        [...parentFormItem, ...formItemProp.patronymicName.name],
        [...parentFormItem, ...formItemProp.fname.name],
        [...parentFormItem, ...formItemProp.lname.name],
        [...parentFormItem, ...formItemProp.birthdate.name],
        [...parentFormItem, ...formItemProp.gender.name],
        [...parentFormItem, ...formItemProp.documentType.name],
      ])
    }
  }, [
    disableForm,
    form,
    formItemProp,
    hasDocumentId,
    hasValidated,
    parentFormItem,
  ])

  const { mutate, isLoading: isValidationg } = useValidatePerson(
    URL_VALIDATE_PERSON,
    {
      onSuccess: ({ data }) => {
        form.setFields([
          {
            name: [...parentFormItem, ...formItemProp.fname.name],
            value: data?.firstName || '',
          },
          {
            name: [...parentFormItem, ...formItemProp.lname.name],
            value: data?.lastName || '',
          },
          {
            name: [...parentFormItem, ...formItemProp.birthdate.name],
            value: dayjs(data?.birthDate),
          },
          {
            name: [...parentFormItem, ...formItemProp.patronymicName.name],
            value: data?.patronymicName || '',
          },
          {
            name: [...parentFormItem, ...formItemProp.gender.name],
            value: data?.gender === 'F' ? 1 : 2,
          },
          {
            name: [...parentFormItem, ...formItemProp.documentType.name],
            value: data?.documentTypeID,
          },
        ])
        setHasValidated(true)
      },

      onError: () => {
        errorMessage(
          {
            heading: 'Ծանուցում',
            title: 'Անձը նույնականացված չէ։ Խնդրում ենք լրացնել տվյալները։',
          },
          noop,
        )
        setDisableForm(false)
      },
    },
  )

  const onPersonSearch = () => {
    form
      .validateFields([
        [...parentFormItem, ...formItemProp.documentNumber.name],
      ])
      .then(() => {
        if (!hasValidated) {
          mutate({ doc: docNumber })
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <FormSectionCard title={title} showRequired={false}>
          <VerticalSpace>
            {requireDocs && (
              <>
                <Col span={24}>
                  <FormItem
                    label="Անձը հաստատող փաստաթուղթ"
                    rules={[{ required: true }]}
                    {...formItemProp.hasDocument}
                  >
                    <Select
                      options={createOptionsFromArray(
                        hasDocumentOptions,
                        'name',
                      )}
                      placeholder="Ընտրել"
                      disabled={hasValidated}
                    />
                  </FormItem>
                </Col>
                {hasDocumentId && (
                  <Row gutter={10} align="middle" justify="start">
                    <Col span={hasDocumentId === 1 ? 12 : 24}>
                      <FormItem
                        label="ԱՀՓ համար (Նախընտրելի տարբերակ՝ ՀԾՀ)"
                        {...formItemProp.documentNumber}
                        rules={[{ required: !!(hasDocumentId === 1) }]}
                      >
                        <Input disabled={hasValidated} />
                      </FormItem>
                    </Col>
                    {hasDocumentId === 1 && (
                      <Col
                        span={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          shape="round"
                          size="large"
                          loading={isValidationg}
                          onClick={onPersonSearch}
                        >
                          Որոնել
                        </Button>
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
            {!!(requireDocs && hasDocumentId) && (
              <Col span={24}>
                <FormItem
                  label="ԱՀՓ տեսակ"
                  rules={[{ required: !!(hasDocumentId === 1) }]}
                  {...formItemProp.documentType}
                >
                  <Select
                    options={documentTypes.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    placeholder="Ընտրել"
                    disabled={hasDocumentId === 1 && disableForm}
                  />
                </FormItem>
              </Col>
            )}
            {!!(!requireDocs || hasDocumentId) && (
              <Row gutter={10} align="middle" justify="start">
                <Col span={8}>
                  <FormItem
                    label="Անուն"
                    rules={[{ required: true }]}
                    {...formItemProp.fname}
                  >
                    <Input disabled={hasDocumentId === 1 && disableForm} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Ազգանուն"
                    rules={[{ required: true }]}
                    {...formItemProp.lname}
                  >
                    <Input disabled={hasDocumentId === 1 && disableForm} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label="Հայրանուն" {...formItemProp.patronymicName}>
                    <Input disabled={hasDocumentId === 1 && disableForm} />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Ծննդյան ամսաթիվ"
                    rules={[{ required: true }]}
                    {...formItemProp.birthdate}
                  >
                    <Datepicker
                      style={{ width: '100%' }}
                      disabled={hasDocumentId === 1 && disableForm}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Սեռ"
                    {...formItemProp.gender}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={createOptionsFromArray(genders)}
                      placeholder="Ընտրել"
                      disabled={hasDocumentId === 1 && disableForm}
                    />
                  </FormItem>
                </Col>
              </Row>
            )}
          </VerticalSpace>
        </FormSectionCard>
      </Spin>
    </>
  )
}
