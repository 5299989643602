import React, { useCallback } from 'react'
import { Form, Row, Spin } from 'antd'
import useGetQuestions from '../../../api/application/use-get-questions'
import { GET_INFORMATION_AFFECTED_FACTOR } from './constants'
import { FormItem } from '../../form/form-item'
import NestedCheckboxes from './nested-checkboxes'
import { VerticalSpace } from '../../space/vertical-space'
import { Title } from '../../typography'

export const InformationAffectedFactor = () => {
  const form = Form.useFormInstance()
  const { data: questions, isLoading } = useGetQuestions(
    GET_INFORMATION_AFFECTED_FACTOR,
  )

  const onCheckboxChange = useCallback(
    (checked, row) => {
      if (checked) {
        if (!row.children.length) {
          form.setFieldsValue({
            householdInformationAffectedFactors: [
              ...form.getFieldValue('householdInformationAffectedFactors'),
              { id: row.id, comment: null },
            ],
          })
        }
      } else {
        form.setFieldsValue({
          householdInformationAffectedFactors: [
            ...form
              .getFieldValue('householdInformationAffectedFactors')
              .filter((r) => r.id !== row.id),
          ],
        })
      }
    },
    [form],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdInformationAffectedFactors')
        .findIndex((r) => r.id === id)
      const newData = [
        ...form.getFieldValue('householdInformationAffectedFactors'),
      ]
      newData[index].comment = value
      form.setFieldsValue({ householdInformationAffectedFactors: newData })
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormItem
        hidden
        name={'householdInformationAffectedFactors'}
        initialValue={[]}
      />
      <Row>
        <Title>
          Գործոններ, որոնք կարող են ազդել տեղեկատվության հավաստիության վրա
        </Title>
        <React.Fragment>
          <FormItem
            rules={[{ required: true }]}
            name={'householdInformationAffectedFactors'}
          >
            {questions.map((row) => (
              <FormItem
                name={['householdInformationAffectedFactors', 'id']}
                key={row.id}
                style={{ marginTop: 20 }}
              >
                <VerticalSpace>
                  <NestedCheckboxes
                    row={row}
                    onCheckboxChange={onCheckboxChange}
                    onInputChange={onInputChange}
                    level={0}
                  />
                </VerticalSpace>
              </FormItem>
            ))}
          </FormItem>
        </React.Fragment>
      </Row>
    </Spin>
  )
}
