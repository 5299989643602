import React, { useEffect, useState } from 'react'
import FormItem from 'antd/es/form/FormItem'
import { utilityServices } from '../constatns'
import { Form, Radio } from 'antd'
import { SecondaryText, SmallText, Text, Title } from '../../typography'
import { VerticalSpace } from '../../space/vertical-space'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { Info } from '../../typography/info'
import { COLORS } from '../../../helpers/constants'
import { Input } from '../../input'

const UtilityFeesHelp = () => {
  const [utilityList, setUtilityList] = useState({})
  const checkList = Form.useWatch('utilityServices', { preserve: true })

  const form = Form.useFormInstance()
  const handleSelection = (parentId, id) => {
    setUtilityList((prev) => ({
      ...prev,
      [parentId]: { id: parentId, compensationId: id },
    }))
  }

  useEffect(() => {
    form.setFieldsValue({ utilityServices: utilityList })
  }, [checkList, form, utilityList])

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <Title>
          Հետևյալ կոմունալ վարձավճարների հարցում ունե՞ք աջակցության կարիք։
        </Title>
        <Info
          text={
            <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>
              ԶՐՈՒՑԱՎԱՐ ԿԱՐԴԱԼ ՏԱՐԲԵՐԱԿՆԵՐԸ։
            </SmallText>
          }
        />
      </VerticalSpace>

      <VerticalSpace>
        {utilityServices.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              <Text color={COLORS.PRIMARY.BLUE}>
                {index + 1}. {item.label}
              </Text>
              <FormItem
                name={['utilityServicesList', item.name]}
                label={<SecondaryText>Ինչ չափով</SecondaryText>}
                style={{ marginLeft: '20px' }}
                rules={[{ required: true }]}
              >
                <Radio.Group
                  onChange={(e) => handleSelection(item.id, e.target.value)}
                >
                  <VerticalSpace>
                    <Radio value={1}>Այո, ամբողջական փոխհատուցման կարիք</Radio>
                    <Radio value={2}>Այո, մասնակի փոխհատուցման կարիք</Radio>
                    <Radio value={3}>Ոչ, կարիք չունեմ</Radio>
                  </VerticalSpace>
                </Radio.Group>
              </FormItem>
            </React.Fragment>
          )
        })}
      </VerticalSpace>
      <FormItem hidden name={'utilityServices'}>
        <Input />
      </FormItem>
    </FormSectionFullCard>
  )
}

export default UtilityFeesHelp
