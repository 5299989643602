import React, { useCallback, useState } from 'react'
import { FormItem } from '../../../form/form-item'
import { Checkbox, Col, Form, Row } from 'antd'
import SnInput from './sn-input'
import SnSelect from './sn-select'

const ProfessionalSupportChild = ({ dataFamily, row }) => {
  const form = Form.useFormInstance()

  const [checked, setChecked] = useState(false)
  const [checkedFamily, setCheckedFamily] = useState(true)

  const selectProfessionalSupportSelection = Form.useWatch(
    `selectionSupport${row.id}`,
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeProfessionalSupport = useCallback((checked, row) => {
    if (checked) {
      form.setFieldsValue({
        personHealthNeeds: [
          ...form.getFieldValue('personHealthNeeds'),
          { personId: null, healthNeedId: row.id, comment: null },
        ],
      })
    } else {
      form.resetFields([
        `professionalSupportInput${row.id}`,
        `selectionSupportFamilyData${row.id}`,
      ])

      form.setFieldsValue({
        personHealthNeeds: form
          .getFieldValue('personHealthNeeds')
          .filter((r) => r.healthNeedId !== row.id),
      })
    }
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCheckFamilyData = useCallback((checkedFamily, row) => {
    const arrFamilyData = []
    dataFamily?.map((dataFamilyVal) => {
      arrFamilyData.push(dataFamilyVal.id)
    })

    const index = form
      .getFieldValue('personHealthNeeds')
      .findIndex((r) => r.healthNeedId === row.id)
    const newData = [...form.getFieldValue('personHealthNeeds')]

    if (checkedFamily === true) {
      form.setFieldsValue({
        [`selectionSupportFamilyData${row.id}`]: arrFamilyData,
      })

      newData[index].personId = arrFamilyData
      form.setFieldsValue({ personHealthNeeds: newData })
    } else {
      form.setFieldsValue({
        [`selectionSupportFamilyData${row.id}`]: undefined,
      })

      newData[index].personId = undefined
      form.setFieldsValue({ personHealthNeeds: newData })
    }
  })

  return (
    <FormItem name={[`selectionSupport${row.id}`]} rules={[{ required: true }]}>
      {row.id === 21 && (
        <p style={{ marginLeft: '25px' }}>
          Խնդրում եմ նշել անհրաժեշտ աջակցող միջոցի / ների տեսակը
        </p>
      )}
      {row.children.map((rowChildren) => {
        return (
          <FormItem
            name={['selectionSupport', rowChildren.label]}
            key={rowChildren.id}
          >
            <FormItem
              name={[`selectionSupport${row.id}`, rowChildren.label, 'id']}
              style={{ marginLeft: '25px' }}
              valuePropName="checked"
            >
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked)
                  onChangeProfessionalSupport(e.target.checked, rowChildren)
                }}
                value={rowChildren.id}
              >
                {rowChildren.name}
              </Checkbox>
            </FormItem>

            {selectProfessionalSupportSelection?.[rowChildren.label]?.id ? (
              rowChildren.children.length === 0 &&
              rowChildren?.hasComment === false ? (
                <>
                  <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <SnSelect
                        nameItem={`selectionSupportFamilyData${rowChildren.id}`}
                        dataFamily={dataFamily}
                        row={rowChildren}
                        style={{ marginLeft: '50px' }}
                      />
                      <FormItem
                        name={[
                          'professionalSupportFamilyAllData',
                          rowChildren.label,
                          'id',
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          checked={checkedFamily}
                          onChange={(e) => {
                            setCheckedFamily(e.target.checked)
                            setCheckFamilyData(e.target.checked, rowChildren)
                          }}
                          value={rowChildren.id}
                          style={{ marginLeft: '50px' }}
                        >
                          Ընտանիքի բոլոր անդամներն ունեն տվյալ կարիքը
                        </Checkbox>
                      </FormItem>
                    </Col>
                  </Row>
                </>
              ) : rowChildren.children.length === 0 &&
                rowChildren?.hasComment === true ? (
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <SnInput
                      nameItem={`professionalSupportInput${rowChildren.id}`}
                      row={rowChildren}
                      style={{ marginLeft: '50px' }}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <SnSelect
                      nameItem={`selectionSupportFamilyData${rowChildren.id}`}
                      dataFamily={dataFamily}
                      row={rowChildren}
                      style={{ marginLeft: '50px' }}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <FormItem
                      name={[
                        'professionalSupportFamilyAllData',
                        rowChildren.label,
                        'id',
                      ]}
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={checkedFamily}
                        onChange={(e) => {
                          setCheckedFamily(e.target.checked)
                          setCheckFamilyData(e.target.checked, rowChildren)
                        }}
                        value={rowChildren.id}
                        style={{ marginLeft: '50px' }}
                      >
                        Ընտանիքի բոլոր անդամներն ունեն տվյալ կարիքը
                      </Checkbox>
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                <FormItem hidden />
              )
            ) : (
              <FormItem hidden />
            )}
          </FormItem>
        )
      })}
    </FormItem>
  )
}

export default ProfessionalSupportChild
