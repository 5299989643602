import React, { useState } from 'react'
import { Checkbox, Form, Radio, Row, Spin } from 'antd'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import {
  URL_GET_HOUSE_HOLD_PERSONS_ADULTS,
  URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS,
  URL_GET_NON_REGISTERED_POLYCLINIC_REASON,
} from '../constants'
import { VerticalSpace } from '../../../space/vertical-space'
import { COLORS } from '../../../../helpers/constants'
import { Text } from '../../../typography'
import useGetQuestions from '../../../../api/application/use-get-questions'
import ReasonsForNotBeingAccounted from './reasons-for-not-being-accounted'
import ReasonsForDissatisfaction from './reasons-for-dissatisfaction'
import PolyclinicServing from '../polyclinic-serving'

const PolyclinicServingTheArea = () => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(null)
  const [valuePolyclinic, setValuePolyclinic] = useState(null)

  const { data: nonRegisteredPolyclinicReason, isLoading } = useGetQuestions(
    URL_GET_NON_REGISTERED_POLYCLINIC_REASON,
  )

  const { data: dataFamilyChildrens } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  const { data: dataFamilyAdults } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS_ADULTS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  const selectPoliclinicServingTheArea = Form.useWatch(
    'registeredPolyclinicId',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForYourDissatisfaction = Form.useWatch(
    'reasonsForYourDissatisfaction',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForNotBeing = Form.useWatch('reasonsForNotBeing', {
    preserve: true,
  })

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForNotBeingNo = Form.useWatch('reasonsForNotBeingNo', {
    preserve: true,
  })

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForNotBeingNo1 = Form.useWatch('reasonsForNotBeingNo1', {
    preserve: true,
  })

  const radioIsSatisfiedPolyclinic = Form.useWatch('isSatisfiedPolyclinic', {
    preserve: true,
  })

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForNotBeingChildren = Form.useWatch(
    'reasonsForNotBeingChildren',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForNotBeingAdult = Form.useWatch('reasonsForNotBeingAdult')

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Արդյո՞ք Դուք և Ձեր ընտանիքի անդամները հաշվառված եք Ձեր տարածքը սպասարկող պոլիկլինիկայում (ամբուլատորիա, բուժկետ)"
        showRequired={false}
      >
        <FormItem
          name="householdNonSatisfiedPolyclinicReasons"
          hidden
          initialValue={[]}
        />

        <FormItem name="personRegisteredPolyclinics" hidden initialValue={[]} />

        <Row>
          <FormItem
            name={'registeredPolyclinicId'}
            style={{ width: '100%' }}
            rules={[{ required: true }]}
          >
            <Radio.Group
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
                form.resetFields([
                  'isSatisfiedPolyclinic',
                  'reasonsForNotBeingNo',
                  'reasonsForNotBeingNo1',
                  'reasonsForNotBeingChildren',
                  'reasonsForNotBeingAdult',
                ])

                form.setFieldsValue({
                  householdNonRegisteredPolyclinicReasons: [],
                })
              }}
              style={{ width: '100%' }}
            >
              <VerticalSpace>
                <Radio value={1}>Այո, բոլորը</Radio>
                {selectPoliclinicServingTheArea &&
                  parseInt(selectPoliclinicServingTheArea) === 1 && (
                    <>
                      <FormItem
                        name={'isSatisfiedPolyclinic'}
                        style={{ marginLeft: '25px' }}
                        label={
                          <Text color={COLORS.PRIMARY.GRAY_DARK}>
                            Արդյո՞ք բախվել եք որևէ խնդրի պոլիկլինիկայում
                            (ամբուլատորիա, բուժկետ) ծառայություն ստանալիս
                          </Text>
                        }
                        rules={[{ required: true }]}
                      >
                        <Radio.Group
                          value={valuePolyclinic}
                          onChange={(e) => {
                            setValuePolyclinic(e.target.value)

                            form.setFieldsValue({
                              householdNonSatisfiedPolyclinicReasons: [],
                            })

                            form.resetFields(['reasonsForYourDissatisfaction'])
                          }}
                          style={{ marginLeft: '25px' }}
                        >
                          <VerticalSpace>
                            <Radio value={1}>Այո</Radio>
                            {radioIsSatisfiedPolyclinic === 1 ? (
                              <ReasonsForDissatisfaction />
                            ) : (
                              <FormItem hidden />
                            )}
                            <Radio value={2}>Ոչ</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </>
                  )}
                <Radio value={2}>Այո, սակայն ոչ բոլորը</Radio>
                {selectPoliclinicServingTheArea &&
                  parseInt(selectPoliclinicServingTheArea) === 2 && (
                    <>
                      {dataFamilyChildrens.length > 0 && (
                        <FormItem
                          name={['reasonsForNotBeingChildren']}
                          valuePropName="checked"
                          style={{ marginLeft: '25px', marginBottom: 0 }}
                          rules={[{ required: true }]}
                        >
                          <Checkbox
                            value={0}
                            onChange={() => {
                              form.resetFields(['selectChildren'])
                            }}
                          >
                            Երեխաները
                          </Checkbox>
                        </FormItem>
                      )}
                      {selectReasonsForNotBeingChildren ? (
                        <PolyclinicServing
                          dataFamily={dataFamilyChildrens}
                          nameItemSelect={'selectChildren'}
                        />
                      ) : (
                        <FormItem hidden />
                      )}
                      <FormItem
                        name={['reasonsForNotBeingAdult']}
                        valuePropName="checked"
                        style={{ marginLeft: '25px' }}
                        rules={[{ required: true }]}
                      >
                        <Checkbox
                          value={1}
                          onChange={() => {
                            form.resetFields(['selectAdult'])
                          }}
                        >
                          Չափահաս անձինք
                        </Checkbox>
                      </FormItem>
                      {selectReasonsForNotBeingAdult ? (
                        <PolyclinicServing
                          dataFamily={dataFamilyAdults}
                          nameItemSelect={'reasonsForNotBeingAdult'}
                        />
                      ) : (
                        <FormItem hidden />
                      )}
                    </>
                  )}

                <Radio value={3}>Ոչ</Radio>
                {selectPoliclinicServingTheArea &&
                  parseInt(selectPoliclinicServingTheArea) === 3 && (
                    <FormItem
                      name={['reasonsForNotBeingNo1']}
                      style={{ marginLeft: '25px', marginBottom: 0 }}
                      label={
                        <Text color={COLORS.PRIMARY.GRAY_DARK}>
                          Խնդրում եմ նշել՝ որոնք են հաշվառված չլինելու
                          պատճառները
                        </Text>
                      }
                      rules={[{ required: true }]}
                    >
                      {nonRegisteredPolyclinicReason?.map((row) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <ReasonsForNotBeingAccounted
                            nameItem={'reasonsForNotBeingNo1'}
                            row={row}
                          />
                        )
                      })}
                    </FormItem>
                  )}
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
        </Row>
      </FormSectionCard>
    </Spin>
  )
}

export default PolyclinicServingTheArea
