import { FormSectionCard } from '../../card/form-section-card'
import useGetQuestions from '../../../api/application/use-get-questions'
import {
  URL_GET_SUPPORT_DIRECTION,
  URL_GET_SUPPORT_DIRECTION_INFORMATION,
} from './constant'
import React from 'react'
import { Form, Spin } from 'antd'
import RadioButtons from './radio-buttons'
import { FormItem } from '../../form/form-item'
import { useCallback } from 'react'

const SupportDirection = () => {
  const { data, isLoading } = useGetQuestions(URL_GET_SUPPORT_DIRECTION)
  const { data: information } = useGetQuestions(
    URL_GET_SUPPORT_DIRECTION_INFORMATION,
  )

  const form = Form.useFormInstance()

  const onRadioChange = useCallback(
    (checked, id) => {
      if (checked) {
        form.setFieldsValue({
          householdSupportDirections: [
            ...form.getFieldValue('householdSupportDirections'),
            {
              id,
              hasInformation: checked,
              householdSupportDirectionInformations: [],
            },
          ],
        })
      } else {
        form.setFieldsValue({
          householdSupportDirections: form
            .getFieldValue('householdSupportDirections')
            .filter((field) => field.id !== id),
        })
      }
    },
    [form],
  )

  const onCheckboxChange = useCallback(
    (value, row, id) => {
      const fieldRow = form
        .getFieldValue('householdSupportDirections')
        .find((field) => field.id === id)

      if (value) {
        fieldRow.householdSupportDirectionInformations.push({
          id: row.id,
          comment: null,
        })
      } else {
        fieldRow.householdSupportDirectionInformations =
          fieldRow.householdSupportDirectionInformations.filter(
            (field) => field.id !== row.id,
          )
      }

      form.setFieldsValue({
        householdSupportDirections: [
          ...form.getFieldValue('householdSupportDirections'),
        ],
      })
    },
    [form],
  )

  const onInputChange = useCallback(
    (value, rowId, id) => {
      const fieldRow = form
        .getFieldValue('householdSupportDirections')
        .find((field) => field.id === id)

      fieldRow.householdSupportDirectionInformations.find(
        (field) => field.id === rowId,
      ).comment = value

      form.setFieldsValue({
        householdSupportDirections: [
          ...form.getFieldValue('householdSupportDirections'),
        ],
      })
    },
    [form],
  )

  return (
    <FormSectionCard
      title="Գի՞տեք որ կառույցին/ում դիմել, եթե ունենաք/կամ ունեք կարիքներ՝ հետևյալ ուղղություններով"
      showRequired={false}
    >
      <FormItem name="householdSupportDirections" hidden initialValue={[]} />

      <Spin spinning={isLoading}>
        {data.map((row, index) => {
          return (
            <RadioButtons
              key={row.id}
              row={row}
              index={index}
              information={information}
              onCheckboxChange={onCheckboxChange}
              onRadioChange={onRadioChange}
              onInputChange={onInputChange}
            />
          )
        })}
      </Spin>
    </FormSectionCard>
  )
}

export default SupportDirection
