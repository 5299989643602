import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'

function createNumberArray(num) {
  const resultArray = Array.from({ length: num + 1 }, (_, index) => index)
  return resultArray
}

export const ApplicationViewContext = createContext()

const initialState = {
  step: 0,
  stepVisible: false,
  finishedSteps: [],
}

function dataSheetReducer(state, action) {
  const { type, payload } = action
  switch (type) {
    case 'jump':
      return {
        ...state,
        finishedSteps: createNumberArray(payload.step - 1),
        step: payload.step,
      }
    case 'step-visible':
      return {
        ...state,
        stepVisible: !initialState.stepVisible,
      }
    case 'finish':
      return {
        ...state,
        finishedSteps: [...state.finishedSteps, state.step],
        step: state.step + 1,
      }
    default:
      state
  }
}

const ApplicationFillProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(dataSheetReducer, initialState)

  const jump = useCallback((step) => {
    dispatch({ type: 'jump', payload: { step } })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const changeVisibility = useCallback(() => {
    dispatch({ type: 'step-visible', payload: {} })
  }, [])

  const finish = useCallback((tempApplicationId) => {
    dispatch({ type: 'finish', payload: { id: tempApplicationId } })
  }, [])

  const providerValues = useMemo(
    () => ({ jump, finish, changeVisibility, ...state }),
    [jump, finish, changeVisibility, state],
  )
  return (
    <ApplicationViewContext.Provider value={providerValues} {...props}>
      {children}
    </ApplicationViewContext.Provider>
  )
}

function useApplicationSteps() {
  const context = useContext(ApplicationViewContext)
  if (context === undefined) {
    throw new Error(
      `useApplication must be used within a ApplicationFillProvider`,
    )
  }
  return context
}

export { ApplicationFillProvider, useApplicationSteps }
