import { COLORS, DATE_FORMAT_YYYYMMDD, DEFAULT_ICON } from './constants'
import { iconsList } from '../components/icon'
import dayjs from 'dayjs'
import { Modal } from 'antd'
import jwtDecode from 'jwt-decode'
import { Text } from '../components/typography'
import { BellFilled } from '@ant-design/icons'
import { Button } from '../components/button'

export const noop = () => {
  return
}

/** Checks if icon exists in our list */
export const checkIconInList = (iconName) => iconsList.includes(iconName)

export const createName = (id) => `item${id}`

export const getIconName = (iconName) =>
  checkIconInList(iconName) ? iconName : DEFAULT_ICON

export const stripTrailingSlash = (str) => {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

export const dateConvert = (date, format = DATE_FORMAT_YYYYMMDD) =>
  dayjs(date).format(format)

export function validatePassword(password) {
  // Check complexity
  if (
    !/\d/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password) ||
    !/[^a-zA-Z\d]/.test(password)
  ) {
    return false
  }
  // If all checks pass, return true
  return true
}

export const errorMessage = (er, onCancel) => {
  Modal.error({
    title: er.heading || 'Տեղի է ունեցել սխալ',
    content: er.response?.data?.Message || er.title || '',
    footer: false,
    closable: true,
    onCancel: () => onCancel || (window.location.href = ''),
  })
}

export const notificationModal = (lastPage) => {
  Modal.info({
    title: 'Ծանուցում',
    icon: <BellFilled style={{ color: '#B20000' }} />,
    content: (
      <Text>
        Նախորդ անգամ կանգ եք առել <strong>{lastPage + '-րդ' + ' '}</strong>
        էջի վրա։ Խնդրում ենք շարունակել տվյալ էջից։
      </Text>
    ),
    closable: true,
    footer: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Button
          type="primary"
          shape="round"
          key="close"
          onClick={() => Modal.destroyAll()}
          style={{
            backgroundColor: COLORS.PRIMARY.BLUE,
          }}
        >
          Հաստատել
        </Button>
      </div>
    ),
  })
}

export const successModal = (num) => {
  Modal.success({
    title: 'Շնորհակալություն',
    content: <div>Ձեր դիմումի համարն է - {num}</div>,
    closable: true,
    onCancel: () => (window.location.href = '/'),
    afterClose: () => (window.location.href = '/'),
    footer: false,
  })
}

export function isAccessTokenExpired(accessToken) {
  const decodedToken = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000 // convert to Unix time
  return decodedToken.exp * 0.9 > currentTime
}

export const createOptionsFromArray = (data, label = 'label') =>
  data.map((item) => ({ label: item[label], value: item.id }))

export function calculateAge(birthdate) {
  return dayjs().diff(birthdate, 'year')
}

export const setFormItemProp = (key) => ({
  fname: {
    name: [key, 'fname'],
  },
  lname: {
    name: [key, 'lname'],
  },
  patronymicName: {
    name: [key, 'patronymicName'],
  },
  ssn: {
    name: [key, 'ssn'],
  },
  birthdate: {
    name: [key, 'birthdate'],
  },
  gender: {
    name: [key, 'gender'],
  },
  hasDocument: {
    name: [key, 'hasDocument'],
  },
  documentNumber: {
    name: [key, 'documentNumber'],
  },
  documentType: {
    name: [key, 'documentType'],
  },
  hasSocialStatus: {
    name: [key, 'hasSocialStatus'],
  },
  socialStatusDetails: {
    name: [key, 'socialStatus'],
  },
})

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const createListFromObject = (inputObject) => {
  const listData = Object.values(inputObject)
  const result = []
  listData.forEach((item) => {
    if (
      item &&
      Object.prototype.hasOwnProperty.call(item, 'id') &&
      Array.isArray(item.id) &&
      item.id.length
    ) {
      result.push({
        id: item.id[0],
        ...(item?.comment && { comment: item.comment.toString() }),
        ...(item?.compensationId && { compensationId: item.compensationId }),
      })
    }
  })

  return result
}
