import React, { useCallback, useEffect, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_NON_SAFE_REASONS } from './constants'
import { Checkbox, Form, Radio, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Title } from '../../typography'
import { createName } from '../../../helpers/utils'
import { Input } from '../../input'

const NonSafeReasons = () => {
  const { data, isLoading } = useGetQuestions(URL_NON_SAFE_REASONS)
  const form = Form.useFormInstance()
  const [checkIds, setCheckIds] = useState({})
  const safeAccommodation = Form.useWatch(['safeAccommodation'])

  useEffect(() => {
    form.resetFields(['householdNonSafeReasons'])
  }, [form, safeAccommodation])

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, parentName) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [...parentName, itemName, 'nonSafeReasonId'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form],
  )

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items, state)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={state[itemName]}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  setState,
                  parentName,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          <FormItem
            name={[...parentName, itemName, 'nonSafeReasonId']}
            hidden
          />
          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard
      title="Արդյո՞ք ձեր կացարանը (գտնվելու վայրը) համարում եք անվտանգ վայր (ներառյալ երեխաների համար)"
      showRequired={false}
    >
      <Spin spinning={isLoading}>
        <FormItem name="safeAccommodation" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>

        {safeAccommodation === false && (
          <>
            <FormItem
              name="householdNonSafeReasons"
              rules={[{ required: true }]}
              label={<Title>Խնդրում եմ նշել պատճառները</Title>}
            >
              {renderItems(data, 0, checkIds, setCheckIds, [
                'householdNonSafeReasons',
              ])}
            </FormItem>
          </>
        )}
      </Spin>
    </FormSectionCard>
  )
}

export default NonSafeReasons
