import React from 'react'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { FormItem } from '../form-item'
import { Title } from '../../typography'
import { TextArea } from '../../input'

const Notes = ({ formItemName, label, required = false }) => {
  return (
    <FormSectionFullCard>
      <FormItem
        label={<Title>{label || 'Լրացուցիչ նշումներ'}</Title>}
        name={formItemName}
        rules={[{ required }]}
      >
        <TextArea />
      </FormItem>
    </FormSectionFullCard>
  )
}

export default Notes
