import React, { useCallback, useState } from 'react'
import { Col, Form, Radio, Row, Spin } from 'antd'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import { Text } from '../../../typography'
import { COLORS } from '../../../../helpers/constants'
import { capitalizeFirstLetter } from '../../../../helpers/utils'
import { VerticalSpace } from '../../../space/vertical-space'
import { URL_GET_HEALTH_SITUATION } from '../constants'
import FmSelect from '../family-members-situation/fm-select'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { Button } from '../../../button'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import FmInput from './fm-input'

const FamilyMembersSituation = ({ dataFamily }) => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(null)
  const [inputData, setInputData] = useState(false)

  const { data: familyMembersSituation, isLoading } = useGetQuestions(
    URL_GET_HEALTH_SITUATION,
  )

  const selectProfessionalSupport = Form.useWatch('familySituation', {
    preserve: true,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSetValueFamilySituation = useCallback((checked, item) => {
    if (item.id === 12 && checked === 4) {
      setInputData(true)
    } else {
      setInputData(false)
    }

    const index = form
      .getFieldValue('personHealthSituations')
      .filter((r) => r.healthSituationId === item.id)

    if (index.length > 0) {
      const oldData = [...form.getFieldValue('personHealthSituations')]

      const arrObj = []
      // eslint-disable-next-line no-unused-vars
      oldData?.map((valueData, keyData) => {
        if (valueData.healthSituationId === item.id) {
          valueData.answereId = checked
          if (checked === 2 || checked === 3) {
            valueData.healthSituationPeople = []
          }
          if (checked !== 4) {
            valueData.comment = null
          }

          arrObj.push(valueData)
        } else {
          arrObj.push(valueData)
        }
      })
      form.setFieldsValue({ personHealthSituations: arrObj })
    } else {
      form.setFieldsValue({
        personHealthSituations: [
          ...form.getFieldValue('personHealthSituations'),
          {
            healthSituationId: item.id,
            answereId: checked,
            healthSituationPeople: [],
            comment: null,
          },
        ],
      })
    }
  })

  const onChangeSetValueFamilySituationNeedToSeeSpecialist = useCallback(
    (value, id, indexRow) => {
      const index = form
        .getFieldValue('personHealthSituations')
        .findIndex((r) => r.healthSituationId === id)
      const newData = [...form.getFieldValue('personHealthSituations')]

      if (newData[index].healthSituationPeople[indexRow] !== undefined) {
        newData[index].healthSituationPeople[indexRow].periodId = value
      } else {
        newData[index].healthSituationPeople[indexRow] = {
          personId: null,
          periodId: value,
        }
      }

      form.setFieldsValue({ personHealthSituations: newData })
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Հիմա կթվեմ մի շարք իրավիճակներ։ Արդյո՞ք, Դուք կամ Ձեր ընտանիքի անդամները ներկայումս այդպիսի իրավիճակում եք։"
        showRequired={false}
      >
        <FormItem name="personHealthSituations" hidden initialValue={[]} />

        <Row>
          {/* <Radio.Group> */}
          <FormItem name="familySituation" style={{ width: '100%' }}>
            {familyMembersSituation?.map((item, key) => {
              return (
                <React.Fragment key={item.id}>
                  <FormItem
                    name={['familySituation', item.name]}
                    style={{ width: '100%' }}
                    rules={[{ required: true }]}
                    label={
                      <Text color={COLORS.PRIMARY.BLUE}>
                        {key + 1}. {item.name}
                      </Text>
                    }
                  >
                    <Radio.Group style={{ width: '100%' }}>
                      <FormItem
                        name={[
                          'familySituation',
                          item.name,
                          `has${capitalizeFirstLetter(item.label)}`,
                        ]}
                        style={{
                          marginLeft: '20px',
                          width: '-webkit-fill-available',
                        }}
                      >
                        <Radio.Group
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value)
                            onChangeSetValueFamilySituation(
                              e.target.value,
                              item,
                            )

                            form.resetFields([
                              `familyMembersSituation${item.id}`,
                            ])

                            if (e.target.value === 2 || e.target.value === 3) {
                              form.resetFields([
                                `requestMemberItems${item.label}`,
                              ])
                            }
                          }}
                          style={{ width: '100%' }}
                        >
                          <VerticalSpace direction="vertical">
                            {item.id !== 12 && <Radio value={1}>Այո</Radio>}

                            {selectProfessionalSupport?.[item.name]?.[
                              `has${capitalizeFirstLetter(item.label)}`
                            ] === 1 && (
                              <Form.List
                                name={`requestMemberItems${item.label}`}
                                initialValue={[{}]}
                              >
                                {(fields, { add, remove }) =>
                                  fields.map((field, index) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <FormSectionCard
                                      title={
                                        fields.length > 1 && index !== 0 ? (
                                          <Col
                                            span={2}
                                            style={{
                                              alignSelf: 'center',
                                              marginBottom: '-10px',
                                            }}
                                          >
                                            <Button
                                              style={{ borderRadius: '0' }}
                                              onClick={() => {
                                                remove(field.name)
                                              }}
                                            >
                                              <MinusOutlined />
                                            </Button>
                                          </Col>
                                        ) : (
                                          <Col
                                            span={2}
                                            style={{
                                              alignSelf: 'center',
                                              marginBottom: '-10px',
                                            }}
                                          >
                                            <Button
                                              style={{ borderRadius: '0' }}
                                              onClick={() => add()}
                                            >
                                              <PlusOutlined
                                                style={{
                                                  color: COLORS.PRIMARY.BLUE,
                                                  padding: 0,
                                                }}
                                              />
                                            </Button>
                                          </Col>
                                        )
                                      }
                                      showRequired={false}
                                      style={{ marginBottom: '20px' }}
                                    >
                                      <Row gutter={16} key={index}>
                                        <Col
                                          span={24}
                                          style={{ alignSelf: 'center' }}
                                        >
                                          <Text
                                            style={{ fontSize: '20px' }}
                                            color={COLORS.PRIMARY.BLUE}
                                          >
                                            Ցանկից ընտրել հարցվող անդամի անունը
                                          </Text>
                                          <FmSelect
                                            nameItem={[
                                              field.name,
                                              `selectedFamilyMemberValue${item.id}`,
                                            ]}
                                            dataFamily={dataFamily}
                                            item={item}
                                            indexRow={index}
                                            style={{
                                              marginTop: '15px',
                                              marginLeft: '25px',
                                              width: '350px',
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          span={24}
                                          style={{ alignSelf: 'center' }}
                                        >
                                          <FormItem
                                            name={[
                                              field.name,
                                              `familySituationNeedToSeeSpecialist${item.id}`,
                                            ]}
                                            style={{
                                              marginLeft: '25px',
                                              width: '-webkit-fill-available',
                                            }}
                                            label={
                                              <Text
                                                color={COLORS.PRIMARY.GRAY_DARK}
                                              >
                                                Որքան շուտ մասնագետին հանդիպելու
                                                կարիք ունեք
                                              </Text>
                                            }
                                            rules={[{ required: true }]}
                                          >
                                            <Radio.Group
                                              style={{
                                                marginLeft: '20px',
                                                width: '100%',
                                              }}
                                              onChange={(e) => {
                                                setValue(e.target.value)
                                                onChangeSetValueFamilySituationNeedToSeeSpecialist(
                                                  e.target.value,
                                                  item.id,
                                                  index,
                                                )
                                              }}
                                            >
                                              <VerticalSpace direction="vertical">
                                                <Radio value={1}>
                                                  Մինչև 1 շաբաթ
                                                </Radio>
                                                <Radio value={2}>
                                                  2 շաբաթից մինչև 1 ամիս
                                                </Radio>
                                                <Radio value={3}>
                                                  1 ամսից ավել
                                                </Radio>
                                                <Radio value={4}>չգիտի</Radio>
                                              </VerticalSpace>
                                            </Radio.Group>
                                          </FormItem>
                                        </Col>
                                      </Row>
                                    </FormSectionCard>
                                  ))
                                }
                              </Form.List>
                            )}

                            {item.id === 12 && (
                              <Row>
                                <Col span={24}>
                                  <Radio value={1}>Այո</Radio>
                                </Col>
                                <Col span={10}>
                                  {inputData === true && (
                                    <FmInput
                                      nameItem={`familyMembersSituation${item.id}`}
                                      row={item}
                                      style={{
                                        marginLeft: '25px',
                                        marginTop: '25px',
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            )}
                            <Radio value={2}>Ոչ</Radio>
                            <Radio value={3}>Չգիտի</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </Radio.Group>
                  </FormItem>
                </React.Fragment>
              )
            })}
          </FormItem>
          {/* </Radio.Group> */}
        </Row>
      </FormSectionCard>
    </Spin>
  )
}

export default FamilyMembersSituation
