import React from 'react'
import { Form, Radio } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { describesNextSteps } from './constant'
import { Input } from '../../input'

export const DescribesNextSteps = () => {
  const radioList = Form.useWatch('question132AnswareId', {
    preserve: true,
  })

  return (
    <FormSectionCard
      title={
        'ՀՀ կառավարությունն առաջարկում է ժամանակավոր պաշտպանություն ԼՂ-ից բռնի տեղահանվածներին։ Ի՞նչ եք նախատեսում անել ձեր կարգավիճակի հետ կապված: Խնդրում ենք ընտրել այն տարբերակը, որը լավագույնս նկարագրում է ձեր հաջորդ քայլերը'
      }
      showRequired={false}
    >
      <FormItem name="question132AnswareId" rules={[{ required: true }]}>
        <Radio.Group>
          <VerticalSpace>
            {describesNextSteps.map((item) => {
              return (
                <Radio key={item.id} value={item.id}>
                  {item.label}
                </Radio>
              )
            })}
          </VerticalSpace>
          {radioList === 4 && (
            <FormItem
              name={'question132AnswareComment'}
              style={{ margin: '10px 0 0 20px' }}
              label={'Այլ (նշել)'}
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
          )}
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}
