import { SearchOutlined } from '@ant-design/icons'
import Checkbox from 'antd/es/checkbox/Checkbox'
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_PAGE,
} from '../../../helpers/constants'
import dayjs from 'dayjs'
import Highlighter from 'react-highlight-words'
import { DatePicker } from 'antd'
import { useRef } from 'react'
const { RangePicker } = DatePicker

const useTableSearch = (setPage) => {
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    if (dataIndex === 'status') {
      setPage((prev) => ({
        ...DEFAULT_PAGE,
        ...prev,
      }))
    } else if (dataIndex === 'createdDate' && selectedKeys.length) {
      setPage((prev) => ({
        ...DEFAULT_PAGE,
        ...prev,
        dateFrom: dayjs(selectedKeys[0][0]).format(DATE_FORMAT_YYYY_MM_DD),
        dateTo: dayjs(selectedKeys[0][1]).format(DATE_FORMAT_YYYY_MM_DD),
      }))
    } else {
      setPage((prev) => ({
        ...DEFAULT_PAGE,
        ...prev,
        [dataIndex]: selectedKeys[0],
      }))
    }
  }

  return (dataIndex, name, type, options) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {type === 'date' ? (
          <RangePicker
            value={selectedKeys[0]}
            onChange={(dates) => setSelectedKeys(dates ? [dates] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            inputReadOnly={true}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        ) : (
          type === 'checkbox' && (
            <Checkbox.Group
              style={{ display: 'block' }}
              value={selectedKeys}
              onChange={(values) => setSelectedKeys(values)}
            >
              {options.map(({ label, value }) => (
                <div key={value} style={{ marginBottom: 8 }}>
                  <Checkbox value={value}>{label}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          )
        )}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  })
}

export default useTableSearch
