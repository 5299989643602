import dayjs from 'dayjs'
import { EditOutlined } from '@ant-design/icons'
import { COLORS, PATHS } from '../../../helpers/constants'
import { useNavigate } from 'react-router-dom'

export const useColumns = (currentPage, pageSize) => {
  const navigate = useNavigate()
  const getIndex = (record, index) =>
    (currentPage.pageNumber - 1) * pageSize + index + 1
  return [
    {
      title: 'ՀՀ',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      flex: 1,
      render: (_, record, index) => getIndex(record, index),
    },
    {
      title: 'Օր և ժամ',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      flex: 1,
      render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Լրացուցիչ Նշումներ',
      dataIndex: 'customNote',
      key: 'customNote',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Դիմումի համար',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Նշումներ',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      flex: 1,
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'householdStateId',
      key: 'householdStateId',
      align: 'center',
      render: (status) => {
        switch (status) {
          case 4:
            return 'Ամբողջական'
          case 2:
            return 'Թերի'
          case 3:
            return 'Դադարեցված'
          case 1:
            return 'Ընթացիկ'
        }
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      render: (item) => {
        if (item.householdStateId === 1 || item.householdStateId === 2) {
          return (
            <EditOutlined
              onClick={() =>
                navigate(PATHS.FILL, {
                  state: {
                    householdId: item.id,
                    status: item.householdStateId,
                    lastPage: item.lastPage,
                  },
                })
              }
              title="Խմբագրել"
              style={{ color: COLORS.PRIMARY.BLUE, cursor: 'pointer' }}
            />
          )
        }
        return <></>
      },
    },
  ]
}
