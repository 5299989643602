import React from 'react'
import { Text } from '../../typography'
import { Col, Collapse, Radio, Row, Space } from 'antd'
import { Button } from '../../button'
import { DeleteOutlined } from '@ant-design/icons'
import { COLORS } from '../../../helpers/constants'
import { PhoneNumberFormItem } from '../../form/phone-number-form-item'
import { Input } from '../../input'
import FormItem from 'antd/es/form/FormItem'
import { VerticalSpace } from '../../space/vertical-space'
import { Select } from '../../select'
import { createOptionsFromArray } from '../../../helpers/utils'
import { Form } from '../../form/form'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_FAMILY_MEMBER_TYPE } from './constans'

const { Panel } = Collapse

const PhoneNumberForm = ({ field, remove }) => {
  const { data } = useGetQuestions(URL_GET_FAMILY_MEMBER_TYPE)

  const contactPersonId = Form.useWatch(
    ['phoneNumberList', field.name, 'contactPersonId'],
    {
      preserve: true,
    },
  )
  const isOwnPhone = Form.useWatch(
    ['phoneNumberList', field.name, 'isOwnContact'],
    {
      preserve: true,
    },
  )

  const contactPersonName = Form.useWatch(
    ['phoneNumberList', field.name, 'contactPersonName'],
    {
      preserve: true,
    },
  )

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        key={field.name}
        header={contactPersonName || ''}
        extra={
          <Space>
            {field.name !== 0 && (
              <Button
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                onClick={() => remove(field.name)}
                type="text"
              >
                Հեռացնել
              </Button>
            )}
          </Space>
        }
      >
        <Row>
          <Col span={24}>
            <PhoneNumberFormItem
              rules={[{ required: true }]}
              name={[field.name, 'phone']}
            >
              <Input />
            </PhoneNumberFormItem>
          </Col>
          <Col span={24}>
            <FormItem
              name={[field.name, 'isOwnContact']}
              rules={[{ required: true }]}
              label={
                <VerticalSpace>
                  <Text color={COLORS.PRIMARY.BLUE} strong>
                    Նշված է իր հեռախոսահամարը
                  </Text>
                </VerticalSpace>
              }
            >
              <Radio.Group>
                <Radio value={true}>Այո</Radio>
                <Radio value={false}>Ոչ</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          {!isOwnPhone && (
            <>
              <Col span={24}>
                <FormItem
                  name={[field.name, 'contactPersonId']}
                  rules={[{ required: true }]}
                  label={
                    <VerticalSpace>
                      <Text color={COLORS.PRIMARY.BLUE} strong>
                        Կապը Ձեզ հետ
                      </Text>
                    </VerticalSpace>
                  }
                >
                  <Select options={createOptionsFromArray(data)} />
                </FormItem>
              </Col>
              <Col span={24}>
                {contactPersonId === 31 && (
                  <FormItem
                    name={[field.name, 'contactPersonComment']}
                    rules={[{ required: true }]}
                  >
                    <Col span={8}>
                      <Input placeholder="Նշել" />
                    </Col>
                  </FormItem>
                )}
              </Col>
              <Col span={24}>
                <FormItem
                  name={[field.name, 'contactPersonName']}
                  rules={[{ required: true }]}
                  label={
                    <VerticalSpace>
                      <Text color={COLORS.PRIMARY.BLUE} strong>
                        Նշել հեռախոսահամարի տիրոջ անունը
                      </Text>
                    </VerticalSpace>
                  }
                >
                  <Col span={24}>
                    <Input />
                  </Col>
                </FormItem>
              </Col>
            </>
          )}
        </Row>
      </Panel>
    </Collapse>
  )
}

export default PhoneNumberForm
