import { Form, Radio } from 'antd'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import { VerticalSpace } from '../../../space/vertical-space'
import PersonTypesForm from './person-types-form'

const BadAttitude = () => {
  const attitudeId = Form.useWatch(['attitudeId'])

  return (
    <FormSectionCard title="Արդյոք Հայաստանում Ձեր նկատմամբ դրսևորվել է խտրականություն, կանխակալ կամ վատ վերաբերմունք, ատելության խոսք կամ կարծրատիպային մոտեցում">
      <FormItem name="attitudeId" rules={[{ required: true }]}>
        <Radio.Group>
          <VerticalSpace>
            <Radio value={1}>Այո</Radio>
            <Radio value={2}>Ոչ</Radio>
            <Radio value={3}>Դժվարանում է պատասխանել</Radio>
          </VerticalSpace>
        </Radio.Group>
      </FormItem>

      {attitudeId === 1 ? <PersonTypesForm /> : null}
    </FormSectionCard>
  )
}

export default BadAttitude
