import { Skeleton } from 'antd'
import { forwardRef } from 'react'
import { Select } from '.'
import { FIELD_NAMES_DEFAULT } from './constants'
import { URL_COMMUNITY_BY_STATE_ID } from '../../api/application/constants'
import { useGetPublic } from '../../api/application/use-get-public'

export const CommunitySelect = forwardRef(
  ({ stateId, onChange, ...props }, ref) => {
    const { data, isInitialLoading } = useGetPublic(
      URL_COMMUNITY_BY_STATE_ID,
      { stateId },
      { enabled: !!stateId },
    )
    if (isInitialLoading) {
      return <Skeleton.Input active size="large" block />
    }

    return (
      <Select
        fieldNames={FIELD_NAMES_DEFAULT}
        style={{ width: '100%' }}
        options={data}
        placeholder="Ընտրել"
        onChange={onChange}
        {...props}
        ref={ref}
      />
    )
  },
)
