import React, { useEffect, useRef } from 'react'
import { Form } from '../../form'
import { FamilyForm } from './family-member-form'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

const FamilyMemberData = ({
  fields,
  add,
  remove,
  isFamilyMember,
  parentFormItem,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ManageFields fields={fields} add={add} />
      {fields.map((field) => (
        <React.Fragment key={field.name}>
          <FamilyForm
            field={field}
            remove={remove}
            parentFormItem={parentFormItem}
            isFamilyMember={isFamilyMember}
          />
        </React.Fragment>
      ))}
      <Button
        type="primary"
        size="large"
        shape="round"
        onClick={() => add()}
        style={{ margin: '20px auto' }}
      >
        <PlusCircleOutlined />
        {isFamilyMember
          ? 'Ավելացնել ընտանիքի անդամ'
          : 'Ավելացնել ոչ ընտանիքի անդամ'}
      </Button>
    </div>
  )
}

export const AddFamilyMember = ({
  viewMode = false,
  isFamilyMember = true,
}) => {
  return (
    <Form.List
      name={[
        'familyData',
        `${isFamilyMember ? 'familyMembers' : 'displacedPersons'}`,
      ]}
    >
      {(fields, { add, remove }) => (
        <FamilyMemberData
          viewMode={viewMode}
          fields={fields}
          add={add}
          remove={remove}
          isFamilyMember={isFamilyMember}
          parentFormItem={[
            'familyData',
            `${isFamilyMember ? 'familyMembers' : 'displacedPersons'}`,
          ]}
        />
      )}
    </Form.List>
  )
}
