import { Checkbox, Form, Space } from 'antd'
import React, { useId, useState } from 'react'
import { VerticalSpace } from '../../../space/vertical-space'
import { SmallInput } from '../../../input/small-input'

const CheckboxWithComment = ({ row, formItemName }) => {
  const name = useId()
  const [checked, setChecked] = useState(false)
  const [value] = useState({ id: row.id, comment: null })

  return (
    <VerticalSpace>
      <Space>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked)
          }}
          value={value}
        >
          {row.name}
        </Checkbox>

        {row.hasComment && checked && (
          <Form.Item
            name={[...formItemName, name]}
            rules={[{ required: true }]}
            noStyle
          >
            <SmallInput
              size="small"
              onChange={(e) => {
                value.comment = e.target.value
              }}
            />
          </Form.Item>
        )}
      </Space>
    </VerticalSpace>
  )
}

export default CheckboxWithComment
