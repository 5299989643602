import React from 'react'
import FormItem from 'antd/es/form/FormItem'
import { SecondaryText } from '../../typography'
import { Radio } from 'antd'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { VerticalSpace } from '../../space/vertical-space'

const answers = [
  {
    text: 'Այո',
    value: 1,
  },
  {
    text: 'Ոչ',
    value: 2,
  },
  {
    text: 'Դժվարանում է պատասխանել',
    value: 3,
  },
]
const AccommodationDamp = () => {
  return (
    <FormSectionFullCard showRequired={false}>
      <FormItem
        label={<SecondaryText>Արդյո՞ք կացարանը խոնավ է</SecondaryText>}
        name="isDamp"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <VerticalSpace>
            {answers.map((item, i) => (
              <Radio key={i} value={item.value}>
                {item.text}
              </Radio>
            ))}
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionFullCard>
  )
}

export default AccommodationDamp
