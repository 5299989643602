import * as React from 'react'
import { anyFamilyMember } from './constants'
import { FormSectionCard } from '../../card/form-section-card'
import { useCallback } from 'react'
import { Form } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import NestedCheckboxes from './nested-checkboxes'

export const AnyFamilyMember = () => {
  const form = Form.useFormInstance()

  const onCheckboxChange = useCallback(
    (checked, row) => {
      if (checked) {
        if (!row.children.length) {
          form.setFieldsValue({
            householdAnyFamilyMember: [
              ...form.getFieldValue('householdAnyFamilyMember'),
              { id: row.id, comment: '' },
            ],
          })
        }
      } else {
        form.setFieldsValue({
          householdAnyFamilyMember: form
            .getFieldValue('householdAnyFamilyMember')
            .filter((r) => r.id !== row.id),
        })
      }
    },
    [form],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdAnyFamilyMember')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('householdAnyFamilyMember')]
      newData[index].comment = value
      form.setFieldsValue({ householdAnyFamilyMember: newData })
    },
    [form],
  )
  return (
    <FormSectionCard
      title="Ընտանիքի անդամներից որևէ մեկի կամ մի քանիսի համար ճշմարիտ են նշված դատողությունները"
      showRequired={false}
    >
      <FormItem
        initialValue={[]}
        rules={[{ required: true }]}
        name={['householdAnyFamilyMember']}
      >
        {anyFamilyMember.map((member) => (
          <VerticalSpace key={member.id}>
            <FormItem name={['householdAnyFamilyMember', member.id, 'id']}>
              <NestedCheckboxes
                row={member}
                onCheckboxChange={onCheckboxChange}
                onInputChange={onInputChange}
                level={0}
                rules={[{ required: true }]}
              />
            </FormItem>
          </VerticalSpace>
        ))}
      </FormItem>
    </FormSectionCard>
  )
}
