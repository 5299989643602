import React, { useCallback } from 'react'
import { FormItem } from '../../../form/form-item'
import { Select } from '../../../select'
import { Form } from 'antd'

const SnSelect = ({ nameItem, dataFamily, row, style }) => {
  const form = Form.useFormInstance()

  // eslint-disable-next-line no-unused-vars
  const selectionSupportFamilyData = Form.useWatch(
    'selectionSupportFamilyData',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line no-unused-vars
  const selectSelectionSupportFamilyData = Form.useWatch(
    'selectSelectionSupportFamilyData',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSelectValue = useCallback((checked, row) => {
    const index = form
      .getFieldValue('personHealthNeeds')
      .findIndex((r) => r.healthNeedId === row.id)
    const newData = [...form.getFieldValue('personHealthNeeds')]

    newData[index].personId = checked
    form.setFieldsValue({ personHealthNeeds: newData })
  })

  return (
    <FormItem name={[nameItem]} rules={[{ required: true }]} style={style}>
      <Select
        mode={'multiple'}
        placeholder={'Նշել ընտանիքի անդամին(ներին)'}
        onChange={(e) => {
          changeSelectValue(e, row)
        }}
      >
        {dataFamily?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.firstName + ' ' + item.lastName}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  )
}

export default SnSelect
