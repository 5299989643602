import { Col, Divider, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons'

import { useAuth } from '../../context/auth-context'
import { Button } from '../button'
import { PATHS } from '../../helpers/constants'

export const ManagePage = () => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  const logOut = () => {
    logout()
  }

  return (
    <>
      <Row justify="end" align="middle">
        <Col>
          {/* <NotificationManage /> */}
          <Button
            type="text"
            onClick={() => navigate(`${PATHS.ROOT}`)}
            style={{ padding: '0px 10px' }}
          >
            <HomeOutlined style={{ cursor: 'pointer', fontSize: '23px' }} />
          </Button>
          <Button
            type="text"
            // onClick={() => navigate(`${PATHS.ACCOUNT}`)}
            style={{ padding: '0px 10px' }}
          >
            <UserOutlined style={{ cursor: 'pointer', fontSize: '23px' }} />
          </Button>
          <Button
            type="text"
            // onClick={() => navigate(`${PATHS.SETTINGS}`)}
            style={{ padding: '0px 10px' }}
          >
            <SettingOutlined style={{ cursor: 'pointer', fontSize: '23px' }} />
          </Button>
          <Button type="text" onClick={logOut} style={{ padding: '0px 10px' }}>
            <LogoutOutlined style={{ cursor: 'pointer', fontSize: '23px' }} />
          </Button>
        </Col>
      </Row>
      <Divider />
    </>
  )
}
