import React, { useState } from 'react'
import { Checkbox, Form, Space } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { SmallInput } from '../../input/small-input'

const NestedCheckboxes = ({ row, level, onCheckboxChange, onInputChange }) => {
  const form = Form.useFormInstance()
  const [checked, setChecked] = useState(false)

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked
    setChecked(isChecked)
    if (!isChecked && row.hasComment) {
      onInputChange('', row.id)
      form.resetFields(['comment'])
    }
    onCheckboxChange(isChecked, row)
  }

  const handleInputChange = (e) => {
    onInputChange(e.target.value, row.id)
  }

  return (
    <VerticalSpace>
      <Space>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          value={row.id}
          style={{ marginLeft: 30 * level }}
        >
          {row.name}
        </Checkbox>

        {row.hasComment && checked && (
          <SmallInput
            rules={[{ required: true }]}
            size="small"
            onChange={handleInputChange}
          />
        )}
      </Space>

      {row.children.map((child) => (
        <NestedCheckboxes
          key={child.id}
          row={child}
          level={level + 1}
          onCheckboxChange={onCheckboxChange}
          onInputChange={onInputChange}
        />
      ))}
    </VerticalSpace>
  )
}

export default NestedCheckboxes
