import { useMutation } from '@tanstack/react-query'
import { client } from '../client'

const useValidatePerson = (url, options) => {
  const mutation = useMutation({
    mutationFn: (queryParams) => {
      const queryUrl = queryParams
        ? `${url}?${new URLSearchParams(queryParams).toString()}`
        : options.url
      return client.get(queryUrl)
    },
    ...options,
  })
  return mutation
}

export default useValidatePerson
