import { Col, Row } from 'antd'
import { Info } from '../../typography/info'
import { Button } from '../../button'
import { Form } from '../form'
import { Password } from '../../input/password'

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

export const UserPassword = () => {
  return (
    <Form name="user-password" layout="verti">
      <Row gutter={30}>
        <Col span={24}>
          <Form.Item
            {...formItemLayout}
            label="Միանգամյա գաղտնաբառ"
            name="userPassword"
          >
            <Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            {...formItemLayout}
            label="Նոր գաղտնաբառ"
            name="userNewPassword"
          >
            <Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            {...formItemLayout}
            label="Նոր գաղտնաբառ"
            name="userNewPassword1"
          >
            <Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Info text="Գաղտնաբառը պետք է պարունակի մեծատառ եւ փոքրատառ լատիներեն տառեր, թվային եւ սիմվոլային արժեքներ" />
        </Col>
        <Col span={24}>
          <Button block size="large" type="primary">
            Հաստատել
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
