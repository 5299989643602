/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_NON_CAN_GO_THERE_REASON } from './constants'
import { FormItem } from '../../form/form-item'
import { createName } from '../../../helpers/utils'
import { Form } from '../../form/form'
import { Checkbox } from 'antd'
import { Input } from '../../input'

const Difficulties = ({ parentFormItem }) => {
  const { data } = useGetQuestions(URL_NON_CAN_GO_THERE_REASON)
  const [reasons, setReasons] = useState({})
  const form = Form.useFormInstance()
  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, parentName) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [...parentFormItem, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form, parentFormItem],
  )

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items, state)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={state[itemName]}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  setState,
                  parentName,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          <FormItem name={[...parentName, itemName, 'id']} hidden />
          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return renderItems(data, 0, reasons, setReasons, parentFormItem)
}

export default Difficulties
