/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { Form } from '../../../form/form'
import { FormItem } from '../../../form/form-item'
import { Input } from '../../../input'
import { VerticalSpace } from '../../../space/vertical-space'
import { URL_BAD_ATTITUDE_TYPES } from '../constants'
import { Checkbox } from 'antd'
import AttitudeManifestation from './attitude-manifestation'
import { Title } from '../../../typography'

const createName = (id) => `item${id}`

const AttitudeTypes = ({ parentFormItem }) => {
  const { data: types } = useGetQuestions(URL_BAD_ATTITUDE_TYPES)

  const form = Form.useFormInstance()

  const [checkedIds, setCheckedIds] = useState({})

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [
            ...parentFormItem,
            'householdBadAttitudeTypes',
            itemName,
            'id',
          ],
          value: checked ? itemId : false,
        },
      ])
    },
    [form, parentFormItem],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentFormItem, 'householdBadAttitudeTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[
                ...parentFormItem,
                'householdBadAttitudeTypes',
                itemName,
                'comment',
              ]}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          {checkedIds[itemName] && (
            <AttitudeManifestation
              parentFormItem={[
                ...parentFormItem,
                'householdBadAttitudeTypes',
                itemName,
              ]}
            />
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <div style={{ paddingLeft: 30 }}>
        <Title style={{ margin: '12px 0' }}>
          Որո՞նք են եղել նման վերաբերմունքի հիմքերը
        </Title>
        <FormItem name={[...parentFormItem, 'householdBadAttitudeTypes']}>
          {renderItems(types)}
        </FormItem>
      </div>
    </>
  )
}

export default AttitudeTypes
