import { Space } from 'antd'
import { Dropdown } from '.'
import { Button } from '../button'
import { DownOutlined } from '@ant-design/icons'

import './dropdown-list.css'

export const DropdownList = ({ items, name }) => {
  return (
    <Dropdown
      trigger={['click']}
      overlayClassName="filter-dropdown"
      menu={{
        items,
      }}
    >
      <Button type="link" onClick={(e) => e.preventDefault()}>
        <Space>
          {name}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}
