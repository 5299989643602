import { Layout } from 'antd'

import styled from 'styled-components'
import { VerticalSpace } from '../../space/vertical-space'
import { ManagePage } from '../manage-page'

const { Content } = Layout

const Wrapper = styled(Content)`
  padding: 32px;
  @media (max-width: 801px) {
    padding: 20px 15px;
  }
`

export const ApplicationFillContentWrapper = ({ children }) => {
  return (
    <Wrapper>
      <ManagePage />
      <VerticalSpace>{children}</VerticalSpace>
    </Wrapper>
  )
}
