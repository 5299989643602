import { Flex, Layout, Space, Spin, Table } from 'antd'
import { SocEmployeeWrapper } from './socEmployee-wrapper'

import { DEFAULT_PAGE, PAGE_SIZE } from '../../../helpers/constants'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { PATHS } from '../../../helpers/constants'
import useGetData from '../../../api/application/use-get-data'
import { SocEmployeeViewSider } from './SocEmployeeViewSider'
import useTableSearch from './useTableSearch'
import { Button } from '../../button'
import { URL_GET_SOCIAL_EMPLOYEES_DATA } from '../../../api/application/use-get-questions'
import { useColumns } from './use-columns'

export const SocEmployeeLayout = () => {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const isMobile = window.innerWidth <= 801
  const navigate = useNavigate()
  const [result, setResult] = useState({ data: [], totalCount: 0 })
  const getColumnSearchProps = useTableSearch(setPage)

  const columns = useColumns(page, PAGE_SIZE, getColumnSearchProps)
  const initialUrl = URL_GET_SOCIAL_EMPLOYEES_DATA
  const { isInitialLoading } = useGetData(
    Object.keys(page).length === 2 && initialUrl,
    page,
    {
      onSuccess: (data) =>
        setResult(() => ({
          data: data.data,
          totalCount: data.totalCount,
        })),
      enabled: true,
    },
  )

  const moveToFillApplication = () => {
    navigate(PATHS.FILL)
  }

  const onHandleChange = useCallback((pagination) => {
    setPage((prevPage) => ({
      ...prevPage,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    }))
  }, [])

  return (
    <Layout style={{ minHeight: '80vh' }}>
      <SocEmployeeViewSider />
      <SocEmployeeWrapper>
        <Spin spinning={isInitialLoading}>
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Flex justify="flex-end">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={false}
                onClick={moveToFillApplication}
              >
                Ավելացնել <PlusOutlined />
              </Button>
            </Flex>
            <Table
              scroll={isMobile ? { x: 'max-content' } : undefined}
              columns={columns}
              dataSource={result?.data}
              rowKey={(record) => `${record.id}-${record.applicationId || 0}`}
              onChange={onHandleChange}
              pagination={{
                current: page.pageNumber,
                pageSize: page.pageSize,
                total: result.totalCount,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${result.totalCount} ից`,
              }}
            />
          </Space>
        </Spin>
      </SocEmployeeWrapper>
    </Layout>
  )
}
