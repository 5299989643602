import React, { useCallback, useState } from 'react'
import { FormSectionFullCard } from '../../../card/form-section-full-card'
import { FormItem } from '../../form-item'
import { Select } from '../../../select'
import { createOptionsFromArray } from '../../../../helpers/utils'
import {
  nonReuinteReasons,
  reuniteOptions,
  reuniteSupport,
} from '../../../fill-application/constatns'
import { SecondaryText } from '../../../typography'
import { Form } from '../../form'
import { VerticalSpace } from '../../../space/vertical-space'
import { Checkbox } from '../../../checkbox'
import { Input } from '../../../input'

const createName = (id) => `item${id}`

const FamilyReuniteForm = ({ field, parentFormItem }) => {
  const parentProp = [...parentFormItem, field.name]

  const [checkedIds, setCheckedIds] = useState({})
  const reuniteTypeId = Form.useWatch(
    [...parentProp, 'nonReside', 'reuniteTypeId'],
    {
      preserve: true,
    },
  )

  const form = Form.useFormInstance()

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, propName) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [...parentProp, propName, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [...parentProp, propName, itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form],
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 0, propName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, propName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  propName,
                )
              }
            >
              {item.label}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, propName, itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
              label={item?.inputLabel || ''}
            >
              <Input placeholder="Նշել" />
            </FormItem>
          )}

          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, propName)}
        </React.Fragment>
      )
    })
  }

  const handleSelectChange = () => {
    setCheckedIds({})
  }

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <FormItem
          name={[field.name, 'nonReside', 'reuniteTypeId']}
          rules={[{ required: true }]}
          label={
            <SecondaryText>
              Արդյո՞ք նախատեսում եք վերամիավորվել ընտանիքի նշված անդամի հետ
            </SecondaryText>
          }
        >
          <Select
            options={createOptionsFromArray(reuniteOptions)}
            onChange={handleSelectChange}
          />
        </FormItem>
        {reuniteTypeId === 1 && (
          <FormItem
            name={[...parentProp, 'nonReside', 'personReuniteSupportTypes']}
            label={
              <SecondaryText>
                Այդ առումով, ի՞նչ աջակցության կարիք ունեք
              </SecondaryText>
            }
          >
            {renderItems(reuniteSupport, 0, 'personReuniteSupportTypes')}
          </FormItem>
        )}
        {reuniteTypeId === 2 && (
          <FormItem
            name={[...parentProp, 'nonReside', 'personNotReuniteReasons']}
            label={<SecondaryText>Խնդրում եմ նշել պատճառները</SecondaryText>}
          >
            {renderItems(nonReuinteReasons, 0, 'personNotReuniteReasons')}
          </FormItem>
        )}
      </VerticalSpace>
    </FormSectionFullCard>
  )
}

export default FamilyReuniteForm
