import React from 'react'
import RelationshipDescription from './relationship-description/relationship-description'
import { VerticalSpace } from '../../space/vertical-space'
import ViolenceCase from './violence-case'
import NonSafetyType from './non-safety-type/non-safety-type'
import ChildrenInteracting from './children-interacting/children-interacting'
import BadAttitude from './bad-attitude/bad-attitude'

const TwelfthStepForm = () => {
  return (
    <VerticalSpace>
      <RelationshipDescription />

      <ViolenceCase />

      <NonSafetyType />

      <BadAttitude />

      <ChildrenInteracting />
    </VerticalSpace>
  )
}

export default TwelfthStepForm
