import React from 'react'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_EDUCATION_TYPES } from './constants'
import { FormSectionCard } from '../../card/form-section-card'
import { Spin } from 'antd'
import EducationForm from './education-form'
import { FormItem } from '../../form/form-item'
const EducationQuestions = () => {
  const { data, isLoading } = useGetQuestions(URL_EDUCATION_TYPES)

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard title={'Արդյոք ընտանիքում կա'} showRequired={false}>
        {data.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <FormItem name={'householdPersonEducationTypes'}>
                <EducationForm
                  question={item}
                  parentFormItem="householdPersonEducationTypes"
                />
              </FormItem>
            </React.Fragment>
          )
        })}
      </FormSectionCard>
    </Spin>
  )
}

export default EducationQuestions
