import { Button, Form, Modal, message } from 'antd'
import { Title } from '../typography'
import { URL_CHANGE_PASSWORD } from '../../api/auth/constants'
import { client } from '../../api/client'
import { FormItem } from '../form/form-item'
import { Password } from '../input/password'
import { useAuth } from '../../context/auth-context'

const ResetPasswordModal = ({ visible, onClose }) => {
  const { user } = useAuth()
  const { logout } = useAuth()
  const [form] = Form.useForm()

  const onFinish = async ({ password, newPassword }) => {
    try {
      const response = await client.post(URL_CHANGE_PASSWORD, {
        password,
        newPassword,
        userId: user.userId,
      })
      if (response.status === 200) {
        onClose()
        logout()
      }
    } catch (error) {
      message.error('Something went wrong')
    }
  }

  return (
    <Modal
      open={visible}
      closable
      onCancel={onClose}
      footer={null}
      title={<Title>Խնդրում ենք փոխել Ձեր ծածկագիրը</Title>}
      width={478}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          label="Հին ծածկագիր"
          name="password"
          rules={[{ required: true }]}
        >
          <Password />
        </FormItem>
        <FormItem
          label="Նոր ծածկագիր"
          name="newPassword"
          rules={[
            { required: true },
            { min: 6, message: 'պետք է լինի առնվազն 6 սիմվոլ' },
          ]}
        >
          <Password />
        </FormItem>
        <FormItem
          label="Կրկնել ծածկագիր"
          name="newPassword2"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
            },
            { min: 6, message: 'պետք է լինի առնվազն 6 սիմվոլ' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('Ձեր մուտքագրած նոր գաղտնաբառը չի համընկնում:'),
                )
              },
            }),
          ]}
        >
          <Password />
        </FormItem>

        <FormItem>
          <Button type="primary" block htmlType="submit">
            Հաստատել
          </Button>
        </FormItem>
      </Form>
    </Modal>
  )
}

export default ResetPasswordModal
