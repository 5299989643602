import styled from 'styled-components'
import { Title } from '../typography'
import { COLORS } from '../../helpers/constants'

const Fieldset = styled.fieldset`
  border: 2px solid #456fae;
  border-radius: 10px;
  padding: 29px 39px 19px;

  @media (max-width: 801px) {
    padding: 29px 19px;
  }
  legend {
    color: #456fae;
    width: fit-content;
    border-bottom: none;
    padding: 0 12px;
    span {
      color: ${COLORS.ALERT.RED};
    }
  }
`

export const FormSectionCard = ({
  title,
  children,
  style = {},
  showRequired = true,
}) => {
  return (
    <Fieldset style={style}>
      {title && (
        <Title level={1} as="legend">
          {title}
          {showRequired && <span>*</span>}
        </Title>
      )}
      {children}
    </Fieldset>
  )
}
