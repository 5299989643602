import React, { useEffect, useRef } from 'react'
import { Form } from '../../form'
import MemberOccupancy from './occupancy-form'
import { Button } from '../../../button'
import { PlusCircleOutlined } from '@ant-design/icons'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

const FamilyMemberOccupancy = () => {
  return (
    <Form.List name={['personWorks']}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ManageFields fields={fields} add={add} />
            {fields.map((field) => (
              <MemberOccupancy
                key={field.key}
                field={field}
                add={add}
                remove={remove}
                fields={fields}
                parentFormItem={'personWorks'}
              />
            ))}
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => add()}
              style={{ margin: '20px auto' }}
            >
              <PlusCircleOutlined />
              Ավելացնել ընտանիքի անդամ
            </Button>
          </div>
        )
      }}
    </Form.List>
  )
}

export default FamilyMemberOccupancy
