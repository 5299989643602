import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import ProfessionalSupportNeeds from './professional-support-needs/index'
import FamilyMembersSituation from './family-members-situation/index'
import FamilyNeededToDoctor from './family-needed-to-doctor'
import ChildrenHadHealthIssues from './children-had-health-issues'
import PharmacyType from './pharmacy-type'

import useGetQuestions from '../../../api/application/use-get-questions'
import {
  URL_GET_HOUSE_HOLD_PERSONS,
  URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS,
} from './constants'
import { Form } from 'antd'

const SixthStep = () => {
  const form = Form.useFormInstance()

  const { data: dataFamily } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  const { data: dataFamilyChildrens } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  return (
    <VerticalSpace>
      <ProfessionalSupportNeeds dataFamily={dataFamily} />
      <FamilyMembersSituation dataFamily={dataFamily} />
      <FamilyNeededToDoctor />
      {dataFamilyChildrens.length > 0 && <ChildrenHadHealthIssues />}
      <PharmacyType />
    </VerticalSpace>
  )
}

export default SixthStep
