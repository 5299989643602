import React, { useCallback, useState } from 'react'
import { Checkbox, Form, Radio, Row, Spin } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { URL_GET_NON_CHILDREN_VISIT_POLYCLINIC_REASON } from './constants'
import { VerticalSpace } from '../../space/vertical-space'
import { COLORS } from '../../../helpers/constants'
import { Text } from '../../typography'
import { Input } from '../../input'
import useGetQuestions from '../../../api/application/use-get-questions'

const ChildrenHadHealthIssues = () => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)
  const [valueContactedPolyclinic, setValueContactedPolyclinic] = useState(null)

  const { data: healthIssuesData, isLoading } = useGetQuestions(
    URL_GET_NON_CHILDREN_VISIT_POLYCLINIC_REASON,
  )

  const radioChildrenHadHealthIssues = Form.useWatch(
    'hasChildrenHealthProblem',
    {
      preserve: true,
    },
  )

  const selectNonRegisteredPolyclinicReason = Form.useWatch(
    'nonRegisteredPolyclinicReason',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeChildrenHadHealthIssues = useCallback((checked, row) => {
    if (checked) {
      form.setFieldsValue({
        householdNonChildrenVisitPolyclinicReasons: [
          ...form.getFieldValue('householdNonChildrenVisitPolyclinicReasons'),
          { id: row.id, comment: null },
        ],
      })
    } else {
      form.setFieldsValue({
        householdNonChildrenVisitPolyclinicReasons: form
          .getFieldValue('householdNonChildrenVisitPolyclinicReasons')
          .filter((r) => r.id !== row.id),
      })
    }
  })

  const onInputChangeChildrenHadHealthIssues = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdNonChildrenVisitPolyclinicReasons')
        .findIndex((r) => r.id === id)
      const newData = [
        ...form.getFieldValue('householdNonChildrenVisitPolyclinicReasons'),
      ]
      newData[index].comment = value
      form.setFieldsValue({
        householdNonChildrenVisitPolyclinicReasons: newData,
      })
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Արդյոք վերջին երկու շաբաթվա ընթացքում երեխաներից որևէ մեկն ունեցել է առողջության հետ կապված որևէ խնդիր"
        showRequired={false}
      >
        <FormItem
          name="householdNonChildrenVisitPolyclinicReasons"
          hidden
          initialValue={[]}
        />

        <Row>
          <FormItem
            name={'hasChildrenHealthProblem'}
            style={{ width: '100%' }}
            rules={[{ required: true }]}
          >
            <Radio.Group
              value={value}
              onChange={(e) => {
                setValue(e.target.value)

                form.resetFields([
                  `childrenVisitPolyclinic`,
                  `nonRegisteredPolyclinicReason`,
                ])

                form.setFieldsValue({
                  householdNonChildrenVisitPolyclinicReasons: [],
                })
              }}
            >
              <VerticalSpace>
                <Radio value={1}>Այո</Radio>
                {radioChildrenHadHealthIssues === 1 && (
                  <FormItem
                    name={'childrenVisitPolyclinic'}
                    style={{ width: '100%', marginLeft: '25px' }}
                    label={
                      <Text color={COLORS.PRIMARY.GRAY_DARK}>
                        Արդյո՞ք այդ հարցով դիմել եք պոլիկլինիկա (ամբուլատորիա,
                        բուժկետ)
                      </Text>
                    }
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      value={valueContactedPolyclinic}
                      onChange={(e) => {
                        setValueContactedPolyclinic(e.target.value)
                      }}
                      style={{ marginLeft: '25px' }}
                    >
                      <VerticalSpace>
                        <Radio value={true}>Այո</Radio>
                        <Radio value={false}>Ոչ</Radio>
                      </VerticalSpace>
                    </Radio.Group>
                  </FormItem>
                )}
                <Radio value={2}>Ոչ</Radio>
                {radioChildrenHadHealthIssues === 2 && (
                  <FormItem
                    name={['nonRegisteredPolyclinicReason']}
                    style={{ marginLeft: '25px' }}
                    rules={[{ required: true }]}
                    label={
                      <Text color={COLORS.PRIMARY.GRAY_DARK}>
                        Խնդրում եմ նշել պատճառները
                      </Text>
                    }
                  >
                    {healthIssuesData?.map((row) => {
                      return (
                        <FormItem
                          name={['nonRegisteredPolyclinicReason', row.label]}
                          key={row.id}
                          style={{ marginLeft: '25px' }}
                        >
                          <FormItem
                            name={[
                              'nonRegisteredPolyclinicReason',
                              row.label,
                              'id',
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={(e) => {
                                setChecked(e.target.checked)
                                onChangeChildrenHadHealthIssues(
                                  e.target.checked,
                                  row,
                                )
                                form.resetFields([
                                  `nonRegisteredPolyclinicReasonInput${row.id}`,
                                ])
                              }}
                              value={row.id}
                            >
                              {row.name}
                            </Checkbox>
                          </FormItem>
                          {selectNonRegisteredPolyclinicReason?.[row.label]
                            ?.id ? (
                            row.hasComment === true ? (
                              <FormItem
                                rules={[{ required: true }]}
                                name={`nonRegisteredPolyclinicReasonInput${row.id}`}
                                style={{
                                  marginTop: '20px',
                                  marginLeft: '25px',
                                }}
                              >
                                <Input
                                  placeholder={'Նշել'}
                                  onChange={(e) =>
                                    onInputChangeChildrenHadHealthIssues(
                                      e.target.value,
                                      row.id,
                                    )
                                  }
                                />
                              </FormItem>
                            ) : (
                              <FormItem hidden />
                            )
                          ) : (
                            <FormItem hidden />
                          )}
                        </FormItem>
                      )
                    })}
                  </FormItem>
                )}
                <Radio value={3}>Չի ցանկանում պատասխանել</Radio>
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
        </Row>
      </FormSectionCard>
    </Spin>
  )
}

export default ChildrenHadHealthIssues
