import { Radio } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { SecondaryText } from '../../typography'
import { VerticalSpace } from '../../space/vertical-space'

const ViolenceCase = () => {
  return (
    <FormSectionCard title="Արդյոք լսե՞լ եք բռնության որևէ դեպքի մասին">
      <FormItem
        name="violenceInCommunity"
        label={<SecondaryText>Համայնքում</SecondaryText>}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <VerticalSpace>
            <Radio value>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </VerticalSpace>
        </Radio.Group>
      </FormItem>

      <FormItem
        name="violenceInAccommodation"
        label={<SecondaryText>Կացարանում</SecondaryText>}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <VerticalSpace>
            <Radio value>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}

export default ViolenceCase
