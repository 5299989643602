import React, { useState } from 'react'
import { Col, Form, Radio, Row, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { COLORS } from '../../../helpers/constants'
import { FormSectionCard } from '../../card/form-section-card'
import { numberOfDays, URL_GET_FOOD_STRATEGY } from './constant'
import { createOptionsFromArray } from '../../../helpers/utils'
import { VerticalSpace } from '../../space/vertical-space'
import { Select } from '../../select'
import useGetQuestions from '../../../api/application/use-get-questions'
import { BoldText } from '../../typography/text'
import { Info } from '../../typography/info'

export const DuringPastSevenDays = () => {
  const [foodStrategiesValues, setFoodStrategies] = useState([])
  const form = Form.useFormInstance()
  const appliancesChecklist = Form.useWatch(['foodStrategies'])
  const { data: questions, isLoading } = useGetQuestions(URL_GET_FOOD_STRATEGY)
  const [selectedDaysCounts, setSelectedDaysCounts] = useState({})

  form.setFieldsValue({ foodStrategiesValues: foodStrategiesValues })

  const handleRadioChange = (item, selectedValue) => {
    const daysCount = selectedDaysCounts[item] || null // Retrieve daysCount for the specific item

    const selectedResponse = {
      id: item.id,
      // label: item.label,
      answerId: selectedValue,
      daysCount: selectedValue === 1 ? daysCount : null,
    }

    setFoodStrategies((prevState) => {
      const updatedActions = prevState.filter((action) => action.id !== item.id)
      return [...updatedActions, selectedResponse]
    })

    if (selectedValue !== 1) {
      form.resetFields([['foodStrategies', item.label, 'daysCount']])
    }
  }

  const handleDaysCountChange = (item, value) => {
    setSelectedDaysCounts((prevCounts) => ({
      ...prevCounts,
      [item]: value, // Store the selected daysCount for the specific item
    }))

    setFoodStrategies((prevState) => {
      const updatedActions = prevState.map((action) => {
        if (action.id === item.id && action.answerId === 1) {
          return {
            ...action,
            daysCount: value,
          }
        }
        return action
      })
      return updatedActions
    })
  }

  const getAnswerId = (id) =>
    appliancesChecklist?.[`FoodStrategy${id}`]?.answerId

  return (
    <Spin spinning={isLoading}>
      <FormItem
        hidden
        initialValue={[]}
        name={'foodStrategiesValues'}
      ></FormItem>
      <FormSectionCard
        title={
          'Վերջին 7 օրերի ընթացքում եղե՞լ են օրեր (և եթե այո, ապա քանիսը), երբ ձեր ընտանիքը ստիպված է եղել կիրառել հետևյալ ռազմավարություններից որևէ մեկը բավականաչափ սնունդ կամ այն ձեռք բերելու համար բավականաչափ գումար չունենալու դեմ պայքարելու համար'
        }
        showRequired={false}
      >
        <VerticalSpace>
          <Info
            text={
              'Հարցում նշված 5 ռազմավարություններ են նկարագրված, որոնք տնային տնտեսությունը կիրառել է կամ ոչ նախորդ 7 օրերին։ Եթե հարցվողը նշել է, որ կիրառել է, ապա դրանցից յուրաքանչյուրի համար հարցնել հաճախականությունը, այսպիսին, թե 7 օրերից քանի՞ օր են ստիպված եղել կիրառել այդ ռազմավարությունը։'
            }
          />
          <Row>
            <FormItem name={'foodStrategies'}>
              {questions.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <FormItem
                      name={['foodStrategies', item.label]}
                      rules={[{ required: true, message: '' }]}
                      label={
                        <BoldText color={COLORS.PRIMARY.BLUE}>
                          {item.id}. {item.name}
                        </BoldText>
                      }
                    >
                      <Radio.Group>
                        <FormItem
                          name={['foodStrategies', item.label, 'answerId']}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              handleRadioChange(item, e.target.value)
                            }}
                          >
                            <VerticalSpace direction="vertical">
                              <Radio value={1}>Այո</Radio>
                              {!!(getAnswerId(item.id) === 1) && (
                                <VerticalSpace>
                                  <Row gutter={16}>
                                    <Col>
                                      Նշեք հաճախականությունը (օրերի քանակը 0-7)
                                    </Col>
                                    <Col>
                                      <FormItem
                                        name={[
                                          'foodStrategies',
                                          item.label,
                                          `daysCount`,
                                        ]}
                                        style={{ marginLeft: '20px' }}
                                        rules={[{ required: true }]}
                                      >
                                        <Select
                                          options={createOptionsFromArray(
                                            numberOfDays,
                                          )}
                                          value={selectedDaysCounts[item.id]}
                                          onChange={(value) => {
                                            handleDaysCountChange(item, value)
                                          }}
                                          placeholder={'Ընտրել'}
                                        />
                                      </FormItem>
                                    </Col>
                                  </Row>
                                </VerticalSpace>
                              )}
                              <Radio value={2}>Ոչ</Radio>
                              <Radio value={3}>Չգիտի</Radio>
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      </Radio.Group>
                    </FormItem>
                  </React.Fragment>
                )
              })}
            </FormItem>
          </Row>
        </VerticalSpace>
      </FormSectionCard>
    </Spin>
  )
}
