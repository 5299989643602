import { Col, Row, Space } from 'antd'
import { Button } from '.'
import { Form } from '../form/form'

export const SubmitButton = ({
  canceltext = 'Չեղարկել',
  submitText = 'Ավարտել',
  onCancel,
  showCancelButton = true,
  onSubmit,
  step,
}) => {
  const hasAgreed = Form.useWatch('personAgreement')

  return (
    <Row align="center" justify="center">
      <Col span={8}>
        <Space
          size="middle"
          align="center"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          {showCancelButton && (
            <Button type="default" onClick={onCancel}>
              {canceltext}
            </Button>
          )}

          <Button htmlType="submit" type="primary" onClick={onSubmit}>
            {step === 0 && hasAgreed?.id !== 1 ? 'Ավարտել' : submitText}
          </Button>
        </Space>
      </Col>
    </Row>
  )
}
