import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import SupportFriend from './support-friends'
import SupportWays from './support-ways'
import LastMonthSupport from './last-month-support'

export const TenthStep = () => {
  return (
    <VerticalSpace>
      <LastMonthSupport />
      <SupportFriend />
      <SupportWays />
    </VerticalSpace>
  )
}
