export const URL_GET_CHILDREN_INTERACTING_TYPE =
  'api/Resource/Page13/GetChildrenInteractingType'
export const URL_GET_CITIZENSHIP_CIRCUMSTANCE =
  'api/Resource/Page13/GetCitizenshipCircumstance'
export const URL_GET_STATES = 'api/Resource/Page13/GetStates'

export const subItems = [
  {
    label:
      'Այո, ես կամ ՏՏ այլ անդամ կիրառել ենք այս մեթոդը վերջին մեկ ամսվա ընթացքում',
    id: 4,
  },
]

export const describesNextSteps = [
  {
    id: 1,
    label: 'Ստանալ ժամանակավոր պաշտպանություն ստացած փախստականի կարգավիճակ',
  },
  {
    id: 2,
    label: 'Ստանալ Հայաստանի Հանրապետության քաղաքացիություն',
  },
  {
    id: 3,
    label: 'Չեմ որոշել',
  },
  {
    id: 4,
    label: 'Այլ',
  },
]

export const chanceChooseLivePermanently = [
  {
    id: 1,
    label: 'Կվերադառնամ նախկին մշտական բնակության վայր (ԼՂ)',
  },
  {
    id: 2,
    label: 'Կմնամ Հայաստանում մշտական բնակության համար',
  },
  {
    id: 3,
    label: 'Կմեկնեմ Ռուսաստանի դաշնություն',
  },
  {
    id: 4,
    label: 'Կմեկնեմ Եվրոպական որևէ երկիր',
  },
  {
    id: 5,
    label: 'Կմեկնեմ ԱՄՆ',
  },
  {
    id: 6,
    label: 'Այլ',
  },
  {
    id: 7,
    label: 'Դժվարանում եմ պատասխանել',
  },
]

export const relocationPlans = [
  {
    id: 1,
    label: 'Պլանավորում ենք վերադառնալ նախկին մշտական բնակության վայր (ԼՂ)',
  },
  {
    id: 2,
    label: 'Պլանավորում ենք մնալ Հայաստանում մշտական բնակության համար',
  },
  {
    id: 3,
    label: 'Պլանավորում ենք մեկնել արտերկիր',
  },
  {
    id: 4,
    label: 'Դեռ չենք որոշել',
  },
]
