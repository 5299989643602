import { Col, Row } from 'antd'
import { ContentWrapper } from '../../layouts/content-wrapper'

export const ContentContainerSmall = ({ text }) => {
  return (
    <Row justify="center">
      <Col span={6}>
        <ContentWrapper>{text}</ContentWrapper>
      </Col>
    </Row>
  )
}
