import React from 'react'
import { NeedsAssessmentTerminationCheck } from './needs-assessment-termination-check'
import { ObservationDirection } from './observation-direction'
import { AnyFamilyMember } from './any-family-member'
import { RecidenceConditions } from './recidence-conditions'
import { Summary } from './summary'

const FifteenStep = () => {
  return (
    <>
      <NeedsAssessmentTerminationCheck />
      <ObservationDirection />
      <AnyFamilyMember />
      <RecidenceConditions />
      <Summary />
    </>
  )
}
export default FifteenStep
