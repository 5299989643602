import { DeleteOutlined } from '@ant-design/icons'
import { Collapse, Radio, Space, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import {
  COLORS,
  COMMENT_TYPES,
  FIELD_TYPES,
} from '../../../../helpers/constants'
import { ManageFamilyMemberButton } from '../../../button'
import { Input } from '../../../input'
import { FamilyRelationshipSelect } from '../../../select/family-reletionship-select'
import { VerticalSpace } from '../../../space/vertical-space'
import { Form } from '../../form'
import { FormItem } from '../../form-item'
import { EducationSelect } from '../../../select/education-select'
import { Checkbox } from '../../../checkbox'
import { calculateAge, setFormItemProp } from '../../../../helpers/utils'
import { PersonMainData } from '../../person-main-data'
import { FormSectionCard } from '../../../card/form-section-card'
import PsychicSupportForm from './psychic-support-form'
import PhysicalSupportForm from './physical-support-form'
import NeedToSearchMember from './need-to-search-member'
import ChildNonReside from './child-non-reside'
import { useGetPublic } from '../../../../api/application/use-get-public'
import { URL_GET_SOCIAL_STATUSES } from '../../../../api/application/use-get-questions'
import { Info } from '../../../typography/info'
import { InputNumber } from 'antd/lib'

const { Panel } = Collapse
const createName = (id) => `item${id}`

export const FamilyForm = ({
  field,
  remove,
  parentFormItem,
  isFamilyMember,
}) => {
  const [checkedIds, setCheckedIds] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)

  const form = Form.useFormInstance()
  const gender = Form.useWatch([...parentFormItem, field.name, 'gender'])
  // 1 - female 2 - male
  const age = calculateAge(
    Form.useWatch([...parentFormItem, field.name, 'birthdate'], {
      preserve: true,
    }),
  )

  useEffect(() => {
    // item39 - Սոցիալական առանձին խումբ չունի
    if (checkedIds?.item39 && Object.keys(checkedIds).length > 1) {
      form.setFields([
        {
          name: [...parentFormItem, field.name, 'personSocialStatuses'],
          value: { item39: { id: 39 } },
        },
      ])
      setCheckedIds({ item39: true })
    } else if (checkedIds?.item39) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [checkedIds, field.name, form, parentFormItem])

  const { data: socialStatuses, isLoading } = useGetPublic(
    URL_GET_SOCIAL_STATUSES,
    {
      age,
      gender,
    },
    {
      enabled: !!gender,
    },
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }
  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, isRadio = false, radioOptions = []) => {
      if (isRadio) {
        form.setFields([
          {
            name: [
              ...parentFormItem,
              field.name,
              'personSocialStatuses',
              itemName,
              'id',
            ],
            value: itemId,
          },
        ])
        const idList = radioOptions.reduce((acc, item) => {
          if (item.id !== itemId) {
            acc.push(item.id)
          }
          return acc
        }, [])
        idList.forEach((id) => {
          form.setFields([
            {
              name: [
                ...parentFormItem,
                field.name,
                'personSocialStatuses',
                createName(id),
                'id',
              ],
              value: false,
            },
          ])
        })
        return
      }
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [
            ...parentFormItem,
            field.name,
            'personSocialStatuses',
            itemName,
            'id',
          ],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [field.name, 'personSocialStatuses', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, parentFormItem, field.name],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'personSocialStatuses', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={disableCheckboxes && item.id !== 39}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'personSocialStatuses', itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
              label={item?.inputLabel || ''}
            >
              {item.commentFieldType === COMMENT_TYPES.NUMBER ? (
                <InputNumber placeholder="Նշել" min={0} max={43} />
              ) : (
                <Input placeholder="Նշել" />
              )}
            </FormItem>
          )}
          {item.children &&
            item.children[0]?.fieldType === FIELD_TYPES.RADIO &&
            checkedIds?.[itemName] && (
              <FormItem
                name={[field.name, 'personSocialStatuses', itemName, 'radio']}
                rules={[{ required: true }]}
                style={{ marginLeft }}
              >
                <Radio.Group
                  options={item.children.map((i) => {
                    return {
                      label: i.name,
                      value: i.id,
                    }
                  })}
                  onChange={(e) =>
                    handleCheckboxChange(
                      createName(e.target.value),
                      e.target.checked,
                      e.target.value,
                      true,
                      item.children,
                    )
                  }
                />
              </FormItem>
            )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            item.children[0]?.fieldType !== FIELD_TYPES.RADIO &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  const removeFamilyMember = () => {
    remove(field.name)
  }

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        header={
          isFamilyMember
            ? 'Ընտանիքի անդամի տվյալներ'
            : 'Ընտանիքի հետ ապրող ԼՂ-ից բռնի տեղահանված անձի տվյալներ'
        }
        key={field.name}
        extra={
          field.name !== 0 && (
            <Space>
              <ManageFamilyMemberButton
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                doAction={removeFamilyMember}
              />
            </Space>
          )
        }
      >
        <VerticalSpace>
          <PersonMainData
            title="Ընտանիքի անդամի տվյալներ"
            formItemProp={setFormItemProp(field.name)}
            parentFormItem={parentFormItem}
          />

          <FormItem
            initialvalue={field.name + 1}
            name={[field.name, 'id']}
            hidden
          >
            <Input />
          </FormItem>
          <FamilyRelationshipSelect
            formItemProp={[field.name, 'relativeType']}
            formItemCommentProp={[field.name, 'relativeTypeComment']}
            isFamilyMember={isFamilyMember}
          />
          <EducationSelect formItemProp={[field.name, 'education']} />
          {gender && (
            <Spin spinning={isLoading}>
              <FormSectionCard
                title={'Սոցիալական կարգավիճակ'}
                showRequired={false}
              >
                <VerticalSpace>
                  <Info
                    text={
                      'ՑՈՒՑՈՒՄ. ԿԱՐԴԱԼ ԲՈԼՈՐ ՏԱՐԲԵՐԱԿՆԵՐԸ։ ԸՆՏՐԵԼ ԲՈԼՈՐ ՀՆԱՐԱՎՈՐ ՏԱՐԲԵՐԱԿՆԵՐԸ։'
                    }
                  />
                  <FormItem name={[field.name, 'personSocialStatuses']}>
                    {renderItems(socialStatuses)}
                  </FormItem>
                </VerticalSpace>
              </FormSectionCard>
            </Spin>
          )}
          {isFamilyMember && (
            <>
              <PhysicalSupportForm parentProp={parentFormItem} field={field} />
              <PsychicSupportForm parentProp={parentFormItem} field={field} />
            </>
          )}
          {!!(!isFamilyMember && age >= 18) && (
            <NeedToSearchMember parentProp={parentFormItem} field={field} />
          )}
          {!!(!isFamilyMember && age < 18) && (
            <ChildNonReside parentProp={parentFormItem} field={field} />
          )}
        </VerticalSpace>
      </Panel>
    </Collapse>
  )
}
