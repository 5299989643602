import React, { useState } from 'react'
import { Form, message, Row, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_GET_CITIZENSHIP_CIRCUMSTANCE } from './constant'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Input } from '../../input'
import { Info } from '../../typography/info'
import { VerticalSpace } from '../../space/vertical-space'

const createName = (id) => `item${id}`

export const CitizenshipCircumstances = () => {
  const form = Form.useFormInstance()
  const { data: questions, isLoading } = useGetQuestions(
    URL_GET_CITIZENSHIP_CIRCUMSTANCE,
  )
  const [citizenshipCircumstancesValue, setCitizenshipCircumstances] = useState(
    [],
  )
  const appliancesChecklist = Form.useWatch(
    'householdCitizenshipCircumstances',
    {
      preserve: true,
    },
  )

  form.setFieldsValue({
    citizenshipCircumstancesValue: citizenshipCircumstancesValue,
  })

  const handleFirstCheckboxChange = (id, checked, commentValue) => {
    const updatedFoodSource = {
      id: id,
      comment: commentValue || null, // Set comment or null if no value provided
    }
    const arr = []
    arr.push(checked)

    const numChecked = citizenshipCircumstancesValue.length

    if (checked && numChecked >= 3) {
      message.error('Անհրաժեշտ է նշել առավելագույնը երեք արժեք')
      if (checked) {
        return
      }
    }

    setCitizenshipCircumstances((prevState) => {
      // Check if the checkbox is checked or unchecked
      if (checked) {
        // If checked, update or add the item
        const existingIndex = prevState.findIndex((action) => action.id === id)

        if (existingIndex !== -1) {
          // If the item already exists, update it
          const updatedActions = [...prevState]
          updatedActions[existingIndex] = updatedFoodSource
          return updatedActions
        } else {
          // If the item doesn't exist, add it
          return [...prevState, updatedFoodSource]
        }
      } else {
        form.resetFields(['citizenshipList', 'comment'])
        // If unchecked, remove the item
        return prevState.filter((action) => action.id !== id)
      }
    })
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem
        hidden
        initialValue={[]}
        name={'citizenshipCircumstancesValue'}
      ></FormItem>
      <FormSectionCard
        title={
          'Ո՞ր հանգամանքն է կարևոր ՀՀ քաղաքացիություն ստանալու համար․ (Ընտրել մինչև 3 պատասխան)'
        }
        showRequired={false}
      >
        <VerticalSpace>
          <Info text={'Անհրաժեշտ է նշել առավելագույնը երեք արժեք'} />
          <Row>
            <FormItem
              name={'householdCitizenshipCircumstances'}
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    return new Promise((resolve, reject) => {
                      if (value === undefined || value === null) {
                        reject('')
                        return
                      }

                      const result = Object.values(value)
                      const filteredResult = result.filter(
                        (item) => item.id !== false,
                      )
                      if (filteredResult.length > 3) {
                        reject('Անհրաժեշտ է նշել առավելագույնը երեք արժեք')
                      } else {
                        resolve()
                      }
                    })
                  },
                },
              ]}
            >
              {questions.map((item) => {
                return (
                  <FormItem
                    name={['householdCitizenshipCircumstances', item.label]}
                    key={item.id}
                  >
                    <React.Fragment>
                      <FormItem
                        name={[
                          'householdCitizenshipCircumstances',
                          item.label,
                          'id',
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          onChange={(e) =>
                            handleFirstCheckboxChange(item.id, e.target.checked)
                          }
                          value={item.id}
                        >
                          {item.name}
                        </Checkbox>
                      </FormItem>
                      {item.hasComment &&
                      appliancesChecklist?.[item.label]?.id ? (
                        <FormItem
                          name={[
                            'citizenshipList',
                            createName(item.id),
                            'comment',
                          ]}
                          style={{ margin: '10px 0 0 20px' }}
                          label={item.name}
                          // rules={[{ required: true }]}
                          hidden
                        >
                          <Input
                            onChange={(e) =>
                              handleFirstCheckboxChange(
                                item.id,
                                '',
                                e.target.value,
                              )
                            }
                          />
                        </FormItem>
                      ) : (
                        <FormItem hidden />
                      )}
                    </React.Fragment>
                  </FormItem>
                )
              })}
            </FormItem>
          </Row>
        </VerticalSpace>
      </FormSectionCard>
    </Spin>
  )
}
