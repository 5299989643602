import { VerticalSpace } from '../../space/vertical-space'
import { HowLongFoodSupplies } from './how-long-food-supplies'
import { DuringPastSevenDays } from './during-past-seven-days'
import { WhatThreeMainSources } from './what-three-main-sources'
import { NotEnoughMoneyHousehold } from './not-enough-money-household'

export const FifthStep = () => {
  return (
    <VerticalSpace>
      <HowLongFoodSupplies />

      <DuringPastSevenDays />

      <WhatThreeMainSources />

      <NotEnoughMoneyHousehold />
    </VerticalSpace>
  )
}
