/* eslint-disable */
import { Steps as StepsComponent } from 'antd'
import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'
import { ReactComponent as StepIn } from '../../icons/step-in.svg'
import { ReactComponent as StepNotVisited } from '../../icons/step-not-visited.svg'
import { useMemo } from 'react'
import { useApplicationSteps } from '../../context/applicaton-steps-context'

const Steps = styled(StepsComponent)`
  .ant-steps-item-container {
    padding-bottom: 40px;
  }
`

const steps = [
  {
    title: 'Անձի համաձայնությունը՝ մասնակցելու գնահատմանը',
  },
  {
    title: 'Ընտանիքի անդամների տվյալներ',
  },
  {
    title: 'Ներկայիս բնակավայրը',
  },
  {
    title: 'Կոմունալ կենցաղային պայմաններ',
  },
  {
    title: 'Սննդապահովման կարիքի հրատապություն',
  },
  {
    title: 'Մասնագիտական աջակցության և առողջապահական կարիքներ',
  },
  {
    title: 'Կրթության կազմակերպում և կրթական կարիքներ',
  },
  {
    title: 'Զբաղվածություն',
  },
  {
    title: 'Ընտանիքի եկամուտները և խնդիրների հաղթահարման ռազմավարությունը',
  },
  {
    title: 'Աջակցող ցանց',
  },
  {
    title: 'Ընտանիքի անդամների կարողությունները ունակությունները',
  },
  {
    title: 'Փոխհարաբերությունների բնույթը',
  },
  {
    title: 'Կարգավիճակ և տեղաշարժի միտումներ',
  },
  {
    title: 'Իրազեկվածությունը և տեղեկատվության ստացման նախընտրելի եղանակները',
  },
  {
    title: 'Ամփոփում',
  },
]

export const ApplicationSteps = ({ collapsed }) => {
  const { step, jump, finishedSteps } = useApplicationSteps()

  //! uncomment only in dev environment
  // const onChange = (value) => {
  //   jump(value)
  // }

  const items = useMemo(
    () =>
      steps.map((item, index) => ({
        ...(collapsed ? { title: '' } : item),
        ...(finishedSteps.includes(index)
          ? { icon: <CheckCircleFilled style={{ fontSize: 32 }} /> }
          : index === step
            ? { icon: <StepIn /> }
            : { icon: <StepNotVisited /> }),
      })),
    [finishedSteps, step, collapsed],
  )

  return (
    <Steps
      direction="vertical"
      size="default"
      current={step}
      // onChange={onChange}
      items={items}
    />
  )
}
