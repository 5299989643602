export const antTheme = {
  components: {
    Input: {
      borderRadius: 10,
      colorBorder: '#C0C1C2',
      colorBgContainer: '#ffffff',
      colorPrimaryHover: '#C0C1C2',
      colorPrimaryActive: '#C0C1C2',
      colorPrimary: '#C0C1C2',
      colorError: '#ED1B1B',
      colorErrorBorderHover: '#ED1B1B',
      controlOutline: '',
      controlOutlineWidth: 0,
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorTextDescription: 'rgba(0, 0, 0, 0.45)',
      colorText: '#13002E',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      controlHeight: 48,
      colorFillAlter: '#ffffff',
      colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
      colorBgContainerDisabled: '#F8F8F8',
    },
    InputNumber: {
      borderRadius: 10,
      colorError: '#ED1B1B',
      colorErrorBorderHover: '#ED1B1B',
      colorErrorOutline: '',
      controlOutlineWidth: 0,
      colorText: '#13002E',
      colorPrimaryHover: '#C0C1C2',
      colorBorder: '#C0C1C2',
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      colorBgContainerDisabled: '#ffffff',
      controlHeight: 48,
    },
    Select: {
      borderRadius: 10,
      colorBorder: '#C0C1C2',
      colorBgContainer: '#ffffff',
      colorPrimaryHover: '#C0C1C2',
      colorPrimaryActive: '#C0C1C2',
      colorPrimary: '#C0C1C2',
      colorError: '#ED1B1B',
      controlOutline: '',
      controlOutlineWidth: 0,
      controlHeight: 48,
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorTextDescription: 'rgba(0, 0, 0, 0.45)',
      colorText: '#13002E',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      colorFillAlter: '#ffffff',
      colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
      colorBgContainerDisabled: '#ffffff',
    },
    DatePicker: {
      borderRadius: 10,
      colorBorder: '#C0C1C2',
      colorTextPlaceholder: 'rgba(35, 47, 106, 0.5)',
      colorError: '#ED1B1B',
      colorPrimary: '#C0C1C2',
      colorPrimaryBorder: '#C0C1C2',
      colorText: '#13002E',
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      controlHeight: 48,
      controlOutlineWidth: 0,
      colorLinkActive: '#0958d9',
      colorPrimaryHover: '#C0C1C2',
    },
    Radio: {
      lineWidth: 1,
      colorPrimary: '',
      colorBgContainerDisabled: '#ffffff',
      colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
      fontSize: 16,
      colorWhite: '#456FAE',
      controlOutlineWidth: 2,
      colorBorder: '#456FAE',
      colorPrimaryActive: '#ffffff',
      lineWidthBold: 2,
      colorText: '#091024',
      controlOutline: 'rgba(1, 9, 28, 0.37)',
      padding: 16,
      colorPrimaryBorder: '',
      colorBgContainer: '',
      colorPrimaryHover: '#ffffff',
      controlHeight: 24,
    },
    Button: {
      colorPrimaryHover: '#456FAE',
      colorPrimaryActive: '#456FAE',
      colorLink: '#0F579E',
      colorLinkHover: '#0F579E',
      colorLinkActive: '#0F579E',
      controlOutlineWidth: 0,
      controlHeight: 32,
      controlHeightLG: 40,
      borderRadiusLG: 2,
      borderRadius: 28,
      colorBgContainerDisabled: '#456FAE20',
      colorTextDisabled: '#ffffff',
      colorText: '#456FAE',
      colorBgTextHover: '#ffffff',
      fontSize: 13,
      fontSizeLG: 16,
      lineWidthBold: 2,
      colorBorder: '#456FAE',
      lineWidth: 1,
    },
    Tabs: {
      controlHeight: 64,
      colorBgContainer: '#F2F2F2',
      colorPrimary: '#456FAE',
      colorPrimaryActive: '#131a45',
      colorTextHeading: 'rgba(0, 0, 0, 0.88)',
      fontSize: 14,
      lineHeight: 1.6,
      padding: 16,
    },
    TreeSelect: {
      fontSize: 15,
      fontSizeLG: 20,
      colorText: '#13002E',
      colorTextDisabled: 'rgba(35, 47, 106, 0.5)',
      colorBorder: '#C0C1C2',
    },

    Typography: {
      fontWeightStrong: 700,
      colorError: '#ED1B1B',
      fontSize: 16,
    },
    Dropdown: {
      paddingXS: 0,
    },
    Form: {
      colorBorder: '#C0C1C2',
      colorError: '#ED1B1B',
      colorText: '#091024',
      colorTextDescription: '#091024',
      colorTextHeading: '#091024',
      controlHeightLG: 48,
    },
    Checkbox: {
      controlInteractiveSize: 21,
      colorPrimary: '',
      colorBgContainer: '#fff',
      colorWhite: '#456FAE',
      colorText: '#091024',
      colorPrimaryHover: '#fff',
      colorPrimaryBorder: '#767d91',
      colorBorder: '#456FAE',
      borderRadiusSM: 0,
      lineWidth: 1,
      lineWidthBold: 2,
      lineWidthFocus: 4,
    },
    Table: {
      colorTextHeading: '#456FAE',
      controlHeight: 44,
      fontSize: 16,
      colorSplit: 'rgba(5, 5, 5, 0.06)',
      controlItemBgActive: '#a1a4ab',
      colorPrimary: '#456FAE',
      colorText: '#091024',
    },
    Steps: {
      colorPrimary: '#456FAE',
      colorSplit: '#456FAE',
      colorText: '#456FAE',
      fontWeightStrong: 700,
      fontSizeLG: 14,
    },
  },
  token: {
    colorError: '#ED1B1B',
    colorErrorBorder: '#ED1B1B',
    colorSuccess: '#456FAE',
    colorPrimary: '#456FAE',
    borderRadius: 2,
    fontFamily:
      '"GHEA Grapalat", "Arian AMU", "Arial AMU", Sylfaen, sans-serif;',
    fontSize: 16,
    fontSizeLG: 20,
    fontSizeSM: 12,
    lineHeight: 1.5,
    lineHeightHeading1: 1.3,
    fontSizeHeading1: 20,
    fontSizeHeading2: 20,
    fontSizeHeading3: 16,
    lineHeightHeading3: 1.3,
    fontWeightStrong: 700,
  },
}
