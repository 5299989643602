/* eslint-disable no-console */
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../context/auth-context'
import { PATHS } from '../../helpers/constants'
import ResetPasswordModal from '../modal/resset-pass-modal'
import { useEffect, useState } from 'react'

export const PrivateRoute = () => {
  const [visible, setVisible] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    if (user?.resetPassword) {
      setVisible(true)
    }
  }, [user?.resetPassword])

  if (!user) {
    return <Navigate to={PATHS.SIGN_IN} />
  }

  return (
    <>
      <Outlet />
      <ResetPasswordModal visible={visible} onClose={() => setVisible(false)} />
    </>
  )
}
