import React, { useCallback, useLayoutEffect, useRef } from 'react'
import PersonAgrrement from './person-agreement-form'
import PersonContact from './person-contact-form'
import { VerticalSpace } from '../../space/vertical-space'
import { Form } from '../../form/form'
import { useLocation } from 'react-router-dom'
import { useApplicationSteps } from '../../../context/applicaton-steps-context'
import { notificationModal } from '../../../helpers/utils'
import PersonAgreementView from './person-agreement-view'
import Notes from '../../form/application/notes'
import { Info } from '../../typography/info'

const FirstStepForm = () => {
  const form = Form.useFormInstance()
  const { jump } = useApplicationSteps()
  const firstUpdate = useRef(true)
  const location = useLocation()
  const data = location.state

  const personAgreement = Form.useWatch('personAgreement', {
    preserve: true,
  })

  const makeAddress = useCallback(
    async (lat, long) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`,
          {
            method: 'GET',
            headers: {
              'Accept-Language': 'hy',
            },
          },
        )
        const data = await response.json()
        form.setFields([
          {
            name: 'location',
            value: {
              longitude: long.toString(),
              latitude: lat.toString(),
              address: data?.display_name,
            },
          },
        ])
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching address:', error)
      }
    },
    [form],
  )

  useLayoutEffect(() => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        if (position?.coords) {
          const lat = position.coords.latitude
          const long = position.coords.longitude
          makeAddress(lat, long)
        }
      })
    }
    if (
      firstUpdate.current &&
      data?.householdId &&
      data?.lastPage &&
      data.status
    ) {
      firstUpdate.current = false
      form.setFieldValue('appId', data?.householdId)
      if (data.lastPage && data.status === 1) {
        jump(data.lastPage)
        notificationModal(data.lastPage + 1)
      }
    }
  }, [form, jump, data, makeAddress])

  // status = 1 -> go to last saved page
  // status = 2 -> go to first page and show history
  return (
    <>
      <VerticalSpace>
        {data?.status === 2 && (
          <PersonAgreementView householdId={data.householdId} />
        )}
        <PersonAgrrement />
        {personAgreement?.id === 1 && (
          <>
            <PersonContact />
          </>
        )}
        {/* {!(personAgreement?.id === 1) && <PersonInfo />} */}
        {personAgreement?.id === 3 && (
          <Notes
            formItemName={'page1notes'}
            required
            label={
              <VerticalSpace>
                <Info text="ՑՈՒՑՈՒՄ. ԼՐԱՑՆԵԼ ԴԻՄՈՒՄԱՏՈՒԻ ՀԱՍՑԵՆ " />
                Լրացուցիչ նշումներ
              </VerticalSpace>
            }
          />
        )}
      </VerticalSpace>
    </>
  )
}

export default FirstStepForm
