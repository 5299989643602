import { Layout } from 'antd'

import styled from 'styled-components'
import { ManagePage } from '../manage-page'
import { Form } from '../../form/form'
import axios from 'axios'
const { Content } = Layout

const Wrapper = styled(Content)`
  padding: 32px;
  @media (max-width: 801px) {
    padding: 20px 15px;
  }
`
export const client = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const SocEmployeeWrapper = ({ children, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Wrapper>
      <ManagePage />
      <Form
        name="application-form"
        form={form}
        disabled
        layout="vertical"
        {...props}
      >
        {children}
      </Form>
    </Wrapper>
  )
}
