import { Checkbox, Input } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React, { useCallback, useEffect, useState } from 'react'
import { Form } from '../../form/form'
import { Title } from '../../typography'
import WorkInvestment from './work-investment-form'

const createName = (id) => `item${id}`

const WorkNeedsForm = ({ field, questions }) => {
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})

  // Resetting filed because both WorkNeedsForm and CurrentWorkDescription use same "name"
  useEffect(() => {
    form.resetFields([['personWorks', field.name, 'afterWorkTypes']])
  }, [field.name, form])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['personWorks', field.name, 'afterWorkTypes', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [
              'personWorks',
              field.name,
              'afterWorkTypes',
              itemName,
              'comment',
            ],
            value: '',
          },
        ])
      }
    },
    [form, field.name],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'afterWorkTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'afterWorkTypes', itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
          {!!item?.children && level === 0 && checkedIds[itemName] && (
            <WorkInvestment
              field={field}
              parentProp={[
                'personWorks',
                field.name,
                'afterWorkTypes',
                itemName,
              ]}
            />
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormItem
      name={'afterWorkTypes'}
      label={
        <Title>
          Խնդրում եմ նշել` ի՞նչ աշխատանք կցանկանաք և կկարողանաք իրականացնել
        </Title>
      }
    >
      {renderItems(questions)}
    </FormItem>
  )
}

export default WorkNeedsForm
