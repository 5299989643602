import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { FormItem } from '../../form/form-item'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_GET_INCOME_TYPE } from './constant'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Checkbox } from '../../checkbox'

const createName = (id) => `item${id}`

// if (checked && checkedCount >= 3) {
//     message.error('Անհրաժեշտ է նշել առավելագույնը երեք արժեք')
//     if (checked) {
//         return
//     }
// }

export const BeforeIncomeTypes = () => {
  const { data: questions } = useGetQuestions(URL_GET_INCOME_TYPE)

  const [checkedIds, setCheckedIds] = useState({})
  const [disable, setDisable] = useState(false)
  const [disableLast, setDisableLast] = useState(false)
  const form = Form.useFormInstance()

  useEffect(() => {
    const checkedKeys = Object.keys(checkedIds)

    if (checkedIds?.item10 && checkedKeys.length > 1) {
      form.setFields([
        {
          name: ['foodSource'],
          value: { item10: { id: 10 } },
        },
      ])
      setCheckedIds({ item10: true })
    } else if (checkedIds?.item10) {
      setDisable(true)
    } else if (checkedKeys.some((key) => key !== 'item10' && checkedIds[key])) {
      setDisableLast(true)
    } else {
      setDisableLast(false)
      setDisable(false)
    }
  }, [checkedIds, form])

  const validateCheckboxGroup = (items) => {
    const checkedItems = Object.values(checkedIds).filter((item) => item)
    if (checkedItems.length > 3) {
      return false
    }
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }

    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: ['householdBeforeIncomeTypes', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['householdBeforeIncomeTypes', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, setCheckedIds],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['householdBeforeIncomeTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('Անհրաժեշտ է նշել առավելագույնը երեք արժեք'),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={item.id !== 10 ? disable : disableLast}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={['householdBeforeIncomeTypes', itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionCard
      title={
        'Մինչ տեղահանումը որոնք են եղել Ձեր ընտանիքի ԵՐԵՔ հիմնական եկամտի աղբյուրները'
      }
      showRequired={false}
    >
      {renderItems(questions)}
    </FormSectionCard>
  )
}
