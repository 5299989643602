import { UserOutlined } from '@ant-design/icons'

export const PATHS = {
  ROOT: '/',
  HOME: '/home',
  UI: '/ui',
  SIGN_IN: '/sign-in',
  CHANGE_PASSWORD: '/change-password',
  ACCOUNT: '/account',
  SETTINGS: '/settings',
  FORGOT_PASSWORD: '/forgot-password',
  RECOVER_PASSWORD: '/recover-password',
  APPLICATION: '/applications',
  FILL: '/fill',
}
export const MENU_TYPES = {
  APPLICATIONS: {
    label: 'Տունայց',
    url: `${PATHS.APPLICATIONS}`,
    icon: <UserOutlined />,
  },
}
export const PAGE_SIZE = 10
export const PAGE_NUMBER = 1
export const DEFAULT_PAGE = { pageNumber: PAGE_NUMBER, pageSize: PAGE_SIZE }
export const COLORS = {
  PRIMARY: {
    BLUE: '#456FAE',
    BLUE_LIGHT: '#DBE9FD',
    BLUE_DARK: '#091024',
    GRAY_DARK: '#414141',
    GRAY: '#F4F4F4',
    GRAY_LIGHT: '#969393',
    GRAY_MEDIUM: '#F0F2F5',
    WHITE: '#FFFFFF',
    SILVER: '#BFBFBF',
    SILVER_LIGHT: '#E2E0E8',
  },
  ALERT: {
    RED: '#ed1b1b',
    GRAY: '#808080',
    GREEN: '#5ACA75',
    WHITE_LIGHT: '#F2F2F2',
  },
  SECONDARY: {
    BLUE: '#4D7EC7',
    AQUA_BLUE: '#4874CC',
    ALICE_BLUE: '#F0F0F2',
    CYAN: '#4DC7B5',
    MAGENTA: '#F27281',
    MAGENTA_LIGHT: '#D789D9',
    YELLOW: '#F5B452',
    YELLOW_LIGHT: '#EDE06D',
    GRAY_DARK: '#555965',
    GRAY_MIDLIGHT: '#717B8A',
    GRAY_LIGHT: '#8A91AE',
    MAXIMUM_BLUE: '#4DAAC7',
    GREEN_LIGHT: '#4DC78C',
    BLUE_MEDIUM: '#7F6DED',
    LAVANDER: '#AC89D9',
    PURPLE_MIDDLE: '#D989AF',
    PEARL_AQUA: '#89D9C6',
    PASTEL_BLUE: '#B8D4D6',
    PASTEL_LIGHT: '#DEE6F1',
    PURPLE_BLUE: '#A2ACDE',
    AERO_BLUE: '#BBDACB',
    LAVANDER_DARKL: '#C7BDD4',
    PINK: '#DBAFA1',
  },
}

export const AUTH_KEYS = {
  USER: 'home-visit-user',
  RESET_PASS: true,
  TOKEN: 'home-visit-token',
  REFRESH_TOKEN: 'home-visit-refresh-token',
  TOKEN_DATE: 'home-visit-token-date',
}

export const FIELD_TYPES = {
  RADIO: 1,
}

export const COMMENT_TYPES = {
  STRING: 1,
  NUMBER: 2,
}

export const DEFAULT_ICON = 'logo-small'
export const DEFAULT_COLOR = COLORS.PRIMARY.GRAY_LIGHT

export const screenSize = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
}

export const VALIDATE_MESSAGES = {
  required: 'Պարտադիր դաշտ',
  types: {
    email: '${label} դաշտը վավեր չէ',
    number: '${label} դաշտը վավեր չէ',
  },
  number: {
    range: '${label}ը պետք է լինի ${min} և ${max}',
    min: '${label} պետք է լինի առնվազն ${min} սիմվոլ',
    max: '${label} պետք է լինի առավելագույնը ${max} սիմվոլ',
  },
  string: {
    range: '${label}ը պետք է լինի ${min} և ${max}',
    min: '${label} պետք է լինի առնվազն ${min} սիմվոլ',
    max: '${label} պետք է լինի առավելագույնը ${max} սիմվոլ',
  },
}

export const DATE_FORMAT_YYYYMMDD = 'DD/MM/YYYY'
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD'

export const REQUEST_TYPES = {
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  GET: 'get',
}

export const VARIABLES = {
  MAX_PROJECT_TITLE_LENGTH: 15,
}

export const phonePattern = /^\+374\d{8}$/
