import { Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'

import { PATHS } from '../../helpers/constants'
import { ReactComponent as MyProfile } from './images/profile.svg'
import { useAuth } from '../../context/auth-context'
import { Title } from '../../components/typography'

export const Profile = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  return (
    <Button
      type="link"
      onClick={() => navigate(`/${PATHS.DASHBOARD}`)}
      style={{ height: '100%' }}
    >
      <Space direction="vertical" size="small" id="profile" align="center">
        <MyProfile />
        <Title level={3}>{user?.firstName}</Title>
        <Title level={3}>{user?.lastName}</Title>
      </Space>
    </Button>
  )
}
