/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import { FormItem } from '../../form-item'
import { SecondaryText } from '../../../typography'
import { Form } from '../../form'
import { Checkbox } from '../../../checkbox'
import { Input } from '../../../input'
import { nonResideReasons } from '../../../fill-application/constatns'
import { Select } from '../../../select'
import { createOptionsFromArray } from '../../../../helpers/utils'
import RedCrossAgreement from './agree-to-pass-red-cross'

const createName = (id) => `item${id}`

const NonResideReasons = ({ field, parentFormItem }) => {
  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, fieldName) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [
            ...parentFormItem,
            field.name,
            'personNotResideReasons',
            itemName,
            fieldName,
          ],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [field.name, 'personNotResideReasons', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, parentFormItem, field.name],
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'personNotResideReasons', itemName]}
            style={{ marginLeft }}
            label={
              item.title ? <SecondaryText>{item.title}</SecondaryText> : ''
            }
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            {level !== 0 && item.type === 'select' ? (
              <>
                <Select
                  options={createOptionsFromArray(item.options)}
                  onChange={(e) =>
                    handleCheckboxChange(
                      itemName,
                      true,
                      e,
                      'applyRedCrossTypeId',
                    )
                  }
                />
                {item?.passRedCross && (
                  <RedCrossAgreement
                    formItemProp={[
                      field.name,
                      'personNotResideReasons',
                      itemName,
                      'agreeRedCross',
                    ]}
                  />
                )}
              </>
            ) : (
              <Checkbox
                checked={checkedIds[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(
                    itemName,
                    e.target.checked,
                    item.id,
                    'id',
                  )
                }
              >
                {item.label}
              </Checkbox>
            )}
          </FormItem>

          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'personNotResideReasons', itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
              label={item?.inputLabel || ''}
            >
              <Input placeholder="Նշել" />
            </FormItem>
          )}

          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormItem
      label={
        <SecondaryText>
          Խնդրում եմ նշել, ձեզ հետ ներկայումս չլինելու (այս կացարանում
          չբնակվելու) պատճառ/ները
        </SecondaryText>
      }
      name={[...parentFormItem, field.name, 'personNotResideReasons']}
    >
      {renderItems(nonResideReasons)}
    </FormItem>
  )
}

export default NonResideReasons
