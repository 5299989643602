import React, { useEffect } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Checkbox } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_FRIEND_SUPPORT_TYPE } from './constants'
import CheckboxWithComment from '../../checkbox/checkbox-with-comment'

const SupportFriend = () => {
  const { data } = useGetQuestions(URL_FRIEND_SUPPORT_TYPE)

  const form = Form.useFormInstance()
  const hasSupportFriend = Form.useWatch(['hasSupportFriend'])

  useEffect(() => {
    form.resetFields(['householdFriendSupportTypeIds'])
  }, [hasSupportFriend, form])

  return (
    <FormSectionCard
      title="Արդյոք Դուք կամ Ձեր ընտանիքի անդամները Հայաստանում ունեն ընկեր, հարազատ, ծանոթ անձ, ով Ձեզ աջակցում է:"
      showRequired={false}
    >
      <FormItem name="hasSupportFriend" rules={[{ required: true }]}>
        <Radio.Group>
          <VerticalSpace>
            <Radio value={1}>Այո</Radio>

            {hasSupportFriend === 1 && (
              <Form.Item
                name="householdFriendSupportTypeIds"
                rules={[{ required: true }]}
                style={{ margin: '0 0 6px' }}
              >
                <Checkbox.Group>
                  <VerticalSpace>
                    {data.map((r) => (
                      <CheckboxWithComment
                        row={r}
                        key={r.id}
                        paddingLeft={30}
                      />
                    ))}
                  </VerticalSpace>
                </Checkbox.Group>
              </Form.Item>
            )}

            <Radio value={2}>Ոչ</Radio>
            <Radio value={3}>Չի ցանկանում պատասխանել</Radio>
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}

export default SupportFriend
