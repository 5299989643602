import { Space, Upload } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'

import styled from 'styled-components'
import { SmallText } from '../typography'

const UploadButtonWrapper = styled.div`
  padding: 12px 10px;
  border: 1px solid #c0c1c2;
  border-radius: 10px;
  cursor: pointer;
`

/** @deprecated please use Upload/index UploadDocument instead */
export const DocumentUpload = (props) => {
  return (
    <Upload className="document-uploader" showUploadList={false} {...props}>
      <UploadButtonWrapper>
        <Space size={14}>
          <SmallText>Կցել տեղեկանք</SmallText>
          <FileAddOutlined style={{ color: '#0F579E' }} />
        </Space>
      </UploadButtonWrapper>
    </Upload>
  )
}
