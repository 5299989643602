import React from 'react'
import { Checkbox } from 'antd'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import { VerticalSpace } from '../../../space/vertical-space'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { URL_CHILDREN_INTERACTING_TYPE } from '../constants'
import CheckboxWithAnswer from './checkbox-with-answer'
import { Info } from '../../../typography/info'

const ChildrenInteracting = () => {
  const { data } = useGetQuestions(URL_CHILDREN_INTERACTING_TYPE)

  return (
    <FormSectionCard title="Ընտանիքի որևէ անդամ կիրառե՞լ է երեխաների հետ շփվելիս հետևյալ ձևերը">
      <VerticalSpace>
        <Info
          style={{ marginBottom: 20 }}
          text="ՑՈՒՑՈՒՄ. Պատասխանների տարբերակները կարդալ, յուրաքանչյուր ենթահարցի համար ընտրել մեկ առավել համապատասխան տարբերակը։"
        />

        <FormItem
          name="householdChildrenInteractingTypes"
          rules={[{ required: true }]}
        >
          <Checkbox.Group>
            <VerticalSpace>
              {data.map((row) => (
                <CheckboxWithAnswer key={row.id} row={row} />
              ))}
            </VerticalSpace>
          </Checkbox.Group>
        </FormItem>
      </VerticalSpace>
    </FormSectionCard>
  )
}

export default ChildrenInteracting
