import { Checkbox, Form, Space } from 'antd'
import { useEffect, useId, useRef, useState } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import { SmallInput } from '../../input/small-input'

const Checkboxes = ({ row, level }) => {
  const formItemName = useId()
  const inputName = useId()

  const value = useRef({ id: row.id, comment: null })
  const [checked, setChecked] = useState(false)
  const hasChildren = !!row.children.length
  const form = Form.useFormInstance()

  useEffect(() => {
    form.resetFields([inputName])
  }, [form, inputName, checked])

  return (
    <VerticalSpace style={{ marginLeft: 30 * level }}>
      <Space>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked)
          }}
          value={value.current}
        >
          {row.name}
        </Checkbox>

        {row.hasComment && checked && (
          <Form.Item name={inputName} rules={[{ required: true }]} noStyle>
            <SmallInput
              size="small"
              onChange={(e) => (value.current.comment = e.target.value)}
            />
          </Form.Item>
        )}
      </Space>

      {hasChildren && checked && (
        <Form.Item name={formItemName} rules={[{ required: true }]}>
          <VerticalSpace>
            {row.children.map((child) => (
              <Checkboxes key={child.id} row={child} level={level + 1} />
            ))}
          </VerticalSpace>
        </Form.Item>
      )}
    </VerticalSpace>
  )
}

export default Checkboxes
