import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Spin } from 'antd'
import Input from 'antd/es/input/Input'
import { Text } from '../../typography'
import { COLORS } from '../../../helpers/constants'
import UploadApplicationDocument from './agreement-file-upload'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_AGREEMENT } from './constans'

const PersonAgrrement = () => {
  const { data, isLoading } = useGetQuestions(URL_GET_AGREEMENT)
  const selection = Form.useWatch('personAgreement')
  const possibleDate = Form.useWatch('possibleDate')
  const rejectionReason = Form.useWatch('rejectionReason')
  const personAgreement = Form.useWatch('personAgreement', {
    preserve: true,
  })

  return (
    <Spin spinning={isLoading}>
      <FormItem hidden name="location">
        <Input />
      </FormItem>
      <VerticalSpace>
        <FormSectionCard title="Անձի համաձայնությունը՝ մասնակցելու գնահատմանը">
          <FormItem name="personAgreement" rules={[{ required: true }]}>
            <Radio.Group>
              <VerticalSpace>
                {data.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Radio key={item.id} value={item}>
                        {item.label}
                      </Radio>
                      {!!(selection?.id === 3 && item.id === 3) && (
                        <FormItem
                          name={'possibleDate'}
                          style={{ marginLeft: '30px' }}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <VerticalSpace>
                              {selection?.children.map((item) => {
                                return (
                                  <Radio key={item.id} value={item}>
                                    {item.label}
                                  </Radio>
                                )
                              })}
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      )}
                      {!!(selection?.id === 2 && item.id === 2) && (
                        <FormItem
                          name={'rejectionReason'}
                          style={{ marginLeft: '30px' }}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group>
                            <VerticalSpace>
                              {selection?.children.map((item) => {
                                return (
                                  <Radio key={item.id} value={item}>
                                    {item.label}
                                  </Radio>
                                )
                              })}
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      )}
                      {!!(rejectionReason?.id === 6 && item.id === 2) && (
                        <FormItem
                          rules={[{ required: true }]}
                          name="rejectionReasonComment"
                        >
                          <Input />
                        </FormItem>
                      )}
                      {!!(rejectionReason?.id === 5 && item.id === 2) && (
                        <FormItem
                          rules={[{ required: true }]}
                          name="rejectionReasonComment"
                          label={
                            <VerticalSpace>
                              <Text color={COLORS.PRIMARY.BLUE} strong>
                                Ձեռնարկված քայլեր
                              </Text>
                            </VerticalSpace>
                          }
                        >
                          <Input />
                        </FormItem>
                      )}
                    </React.Fragment>
                  )
                })}
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
          {personAgreement?.id === 1 && (
            <>
              <FormItem name="fileBase64" hidden />
              <UploadApplicationDocument />
            </>
          )}

          {possibleDate?.id === 3 && (
            <FormItem rules={[{ required: true }]} name="possibleDateComment">
              <Input />
            </FormItem>
          )}
        </FormSectionCard>
      </VerticalSpace>
    </Spin>
  )
}

export default PersonAgrrement
