/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import FormItem from 'antd/es/form/FormItem'
import React, { useCallback, useState } from 'react'
import useGetQuestions, {
  URL_GET_WORK_INVEST,
  URL_GET_WORK_NEEDS,
} from '../../../api/application/use-get-questions'
import { Form } from '../../form/form'
import { Checkbox, Spin } from 'antd'
import { Title } from '../../typography'
import { Input } from '../../input'
import { FormSectionFullCard } from '../../card/form-section-full-card'

const createName = (id) => `item${id}`

const WorkInvestment = ({ parentProp }) => {
  const { data: needQuestions, isLoading: isLoadingNeeds } =
    useGetQuestions(URL_GET_WORK_NEEDS)
  const { data: investQuestions, isLoading: isLoadingInvest } =
    useGetQuestions(URL_GET_WORK_INVEST)
  const form = Form.useFormInstance()
  const [needCheckedIds, setNeedCheckedIds] = useState({})
  const [investCheckIds, setInvestCheckIds] = useState({})

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, parent) => {
      parent === 'workNeeds'
        ? setNeedCheckedIds((prevCheckedIds) => ({
            ...prevCheckedIds,
            [itemName]: checked,
          }))
        : setInvestCheckIds((prevCheckedIds) => ({
            ...prevCheckedIds,
            [itemName]: checked,
          }))

      form.setFields([
        {
          name: [...parentProp, parent, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form, parentProp],
  )

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = useCallback(
    (itemName, value, parent) => {
      form.setFields([
        {
          name: [...parentProp, parent, itemName, 'comment'],
          value: value || '',
        },
      ])
    },
    [form, parentProp],
  )

  const validateInput = (parent, itemName) => {
    const val = form.getFieldValue([...parentProp, parent, itemName, 'comment'])
    if (val?.length) {
      return true
    }
    return false
  }

  const renderItems = (items, parent, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentProp, parent, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(
                    items,
                    parent === 'workNeeds' ? needCheckedIds : investCheckIds,
                  )
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={
                parent === 'workNeeds'
                  ? needCheckedIds[itemName]
                  : investCheckIds[itemName]
              }
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  parent,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {(parent === 'workNeeds'
            ? needCheckedIds[itemName]
            : investCheckIds[itemName]) &&
            item.hasComment && (
              <>
                <FormItem
                  name={[...parentProp, parent, itemName, 'comment']}
                  style={{ marginLeft }}
                  rules={[
                    {
                      validator: () =>
                        validateInput(parent, itemName)
                          ? Promise.resolve()
                          : Promise.reject(new Error('Պարտադիր դաշտ')),
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      handleInputChange(itemName, e.target.value, parent)
                    }
                    placeholder="Նշել"
                  />
                </FormItem>
              </>
            )}
          {item.children &&
            item.children.length > 0 &&
            (parent === 'workNeeds'
              ? needCheckedIds[itemName]
              : investCheckIds[itemName]) &&
            renderItems(item.children, parent, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <Spin spinning={isLoadingNeeds || isLoadingInvest}>
      <FormSectionFullCard>
        <FormItem
          name={[...parentProp, 'workNeeds']}
          label={
            <Title>Զբաղվածության ապահովման հարցում ի՞նչ կարիքներ ունեք</Title>
          }
        >
          {renderItems(needQuestions, 'workNeeds')}
        </FormItem>

        <FormItem
          name={[...parentProp, 'workInvests']}
          label={
            <Title>
              Ի՞նչ ռեսուրսներ կարող եք ներդնել Ձեր զբաղվածության կազմակերպման
              հարցում
            </Title>
          }
        >
          {renderItems(investQuestions, 'workInvests')}
        </FormItem>
      </FormSectionFullCard>
    </Spin>
  )
}

export default WorkInvestment
