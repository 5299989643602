import { Checkbox, Form, Spin } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_INFORMATION_LANGUAGE } from './constant'
import { VerticalSpace } from '../../space/vertical-space'
import Checkboxes from './checkboxes'

const InformationLanguage = () => {
  const { data, isLoading } = useGetQuestions(URL_GET_INFORMATION_LANGUAGE)

  return (
    <FormSectionCard
      title="Ի՞նչ լեզվով եք ցանկանում ստանալ տեղեկությունները"
      showRequired={false}
    >
      <Spin spinning={isLoading}>
        <Form.Item
          name="householdInformationLanguages"
          rules={[{ required: true }]}
        >
          <Checkbox.Group>
            <VerticalSpace>
              {data.map((row) => (
                <Checkboxes key={row.id} row={row} level={0} />
              ))}
            </VerticalSpace>
          </Checkbox.Group>
        </Form.Item>
      </Spin>
    </FormSectionCard>
  )
}

export default InformationLanguage
