/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions, {
  URL_GET_GET_ACCOMMODATION_REQUIREMENT,
} from '../../../api/application/use-get-questions'
import { Form, InputNumber, Spin } from 'antd'
import { FormItem } from '../../form/form-item'

import { Input, TextArea } from '../../input'
import { PersonAddress } from '../../form/person-address'
import { SecondaryText } from '../../typography'
import { Checkbox } from '../../checkbox'
import { adjustmentsForDisabled } from '../constatns'
import { FormSectionCard } from '../../card/form-section-card'

const createName = (id) => `item${id}`

const AccommodationNeeds = () => {
  const { data: questions, isLoading } = useGetQuestions(
    URL_GET_GET_ACCOMMODATION_REQUIREMENT,
  )

  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})
  const disabliltyChecklist = Form.useWatch('accommodationAdaptation', {
    preserve: true,
  })
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)

  useEffect(() => {
    // item1- Որևէ կարիք չկա
    if (checkedIds?.item1 && Object.keys(checkedIds).length > 1) {
      form.setFields([
        {
          name: ['householdAccommodationRequirements'],
          value: { item1: { id: 1 } },
        },
      ])
      setCheckedIds({ item1: true })
    } else if (checkedIds?.item1) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [form, checkedIds])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }
  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: ['householdAccommodationRequirements', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['householdAccommodationRequirements', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['householdAccommodationRequirements', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={disableCheckboxes && item.id !== 1}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            //? backend doesn't send proper fileds, that's why checks are hardcoded
            <FormItem
              name={['householdAccommodationRequirements', itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
              label={
                !!(item.id === 2 || item.id === 3) &&
                'Որքա՞ն է կազմում վարձավճարի փոխհատուցման անհրաժեշտ ամսական չափը՝ ՀՀ դրամով'
              }
            >
              {!!(item.id === 2 || item.id === 3) && (
                <InputNumber min={1} style={{ width: '100%' }} />
              )}
              {!!(item.id === 6 || item.id === 9) && (
                <TextArea placeholder="Նշել ինչպիսի աջակցություն" />
              )}
            </FormItem>
          )}
          {!!(checkedIds.item8 && item.id === 8) && (
            <>
              <FormItem name="accommodationAdaptation">
                {adjustmentsForDisabled.map((item) => {
                  return (
                    <FormItem
                      key={item.id}
                      name={['accommodationAdaptation', item.name]}
                      valuePropName="checked"
                      style={{ marginLeft: '20px' }}
                    >
                      <Checkbox value={item.id} key={item.id}>
                        {item.label}
                      </Checkbox>
                    </FormItem>
                  )
                })}
              </FormItem>
              {!!disabliltyChecklist?.other && (
                <FormItem
                  name={['accommodationAdaptation', 'comment']}
                  rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              )}
            </>
          )}
          {!!(checkedIds.item7 && item.id === 7) && (
            <>
              <FormItem
                name="changeAccommodationReason"
                rules={[{ required: true }]}
                label={'Նկարագրել պատճառը'}
              >
                <Input />
              </FormItem>
              <SecondaryText color="#091024">
                Նշել վայրը, որտեղ ցանկանում է տեղափոխվել
              </SecondaryText>
              <PersonAddress
                formItemCommunity={['prefferedLocation', 'communityId']}
                formItemState={['prefferedLocation', 'regionId']}
                showSettlement={false}
              />
            </>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard title={'Կացարանի հետ կապված ի՞նչ կարիքներ ունեք'}>
        {renderItems(questions)}
      </FormSectionCard>
    </Spin>
  )
}

export default AccommodationNeeds
