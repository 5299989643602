import { createBrowserRouter } from 'react-router-dom'
import { ErrorPage } from './pages/error'
import { MainRoutes } from './components/routes/main-route'
import { UiComponents } from './pages/ui-components'
import { PATHS } from './helpers/constants'
import { SignIn } from './pages/sign-in'
import { PublicRoutes } from './components/routes/public-route'
import FillApplication from './pages/application-fill'
import { ApplicationLayout } from './components/layouts/application/application-layout'
import { PrivateRoute } from './components/routes/private-route'
import { SocEmployeeLayout } from './components/layouts/social-employee/socEmployee-layout'

export const router = createBrowserRouter([
  {
    element: <MainRoutes />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <PublicRoutes />,
        children: [
          {
            path: PATHS.SIGN_IN,
            element: <SignIn />,
          },
        ],
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <ApplicationLayout />,
            children: [
              {
                path: PATHS.FILL,
                element: <FillApplication />,
              },
            ],
          },
          {
            path: PATHS.ROOT,
            element: <SocEmployeeLayout />,
          },
        ],
      },
    ],
  },
  {
    path: PATHS.UI,
    element: <UiComponents />,
  },
  {
    element: <ApplicationLayout />,
    children: [
      {
        path: PATHS.FILL,
        element: <FillApplication />,
      },
    ],
  },
])
