import { useMutation } from '@tanstack/react-query'
import { useAuth } from '../../context/auth-context'
import { client } from '../client'
import { URL_LOGIN_USER } from './constants'
import { errorMessage, noop } from '../../helpers/utils'

export const useSignIn = ({
  onSuccess = noop,
  onError = noop,
  ...options
} = {}) => {
  const { login } = useAuth()
  const mutation = useMutation({
    mutationFn: (values) => {
      return client.post(URL_LOGIN_USER, values)
    },
    onSuccess: ({ data }) => {
      login(data)
      onSuccess(data)
    },
    onError: (error) => {
      onError(error)
      errorMessage(error, noop)
    },
    ...options,
  })
  return mutation
}
