import { Card } from 'antd'
import styled from 'styled-components'

const StyleCard = styled(Card)`
  && {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
  }
`

export const SigninCard = ({ children }) => {
  return (
    <StyleCard
      bordered={false}
      style={{
        width: 476,
      }}
    >
      {children}
    </StyleCard>
  )
}
