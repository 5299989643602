import { Input as InputComponent } from 'antd'
import { placeholderSize } from '../../helpers/styles'
import styled from 'styled-components'

export const Input = styled(InputComponent)`
  ::placeholder {
    ${placeholderSize}
  }
`

export const FormInput = (props) => {
  return <Input {...props} />
}

export { TextArea } from './text-area'
