import { VerticalSpace } from '../../space/vertical-space'
import InformationLanguage from './information-language'
import InformationReceiveType from './information-receive-type'
import InformationAvailability from './information-availability'
import SupportDirection from './support-direction'

const FourteenStep = () => {
  return (
    <VerticalSpace>
      <SupportDirection />
      <InformationReceiveType />
      <InformationLanguage />
      <InformationAvailability />
    </VerticalSpace>
  )
}

export default FourteenStep
