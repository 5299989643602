import React from 'react'
import { FormItem } from '../../form-item'
import { SecondaryText } from '../../../typography'
import { Radio } from 'antd'

const RedCrossAgreement = ({ formItemProp }) => {
  return (
    <FormItem
      name={formItemProp}
      label={
        <SecondaryText>
          Արդյո՞ք համաձայն եք, որ այս տեղեկատվությունը փոխանցվի Կարմիր խաչի
          միջազգային կոմիտե
        </SecondaryText>
      }
      rules={[{ required: true }]}
    >
      <Radio.Group>
        <Radio value={true}>Այո</Radio>
        <Radio value={false}>Ոչ</Radio>
      </Radio.Group>
    </FormItem>
  )
}

export default RedCrossAgreement
