import { useQuery } from '@tanstack/react-query'
import { client } from '../client'

export const URL_GET_GET_ACCOMMODATION_REQUIREMENT =
  '/api/Resource/GetAccommodationRequirement'
export const URL_GET_UTILITY_SERVICES = '/api/Resource/GetUtilityService'
export const URL_GET_WORK_TYPES = '/api/Resource/Page8/GetWorkType'
export const URL_GET_WORK_NEEDS = '/api/Resource/Page8/GetWorkNeed'
export const URL_GET_WORK_INVEST = '/api/Resource/Page8/GetWorkInvest'
export const URL_GET_NON_WORK_REASON = '/api/Resource/Page8/GetNonWorkReason'
export const URL_GET_RELATIONSHIP_GET_VIOLENCE =
  '/api/Resource/Page12/GetViolence'
export const URL_GET_FAMILY_MEMBERS = 'api/Person/GetHouseholdPersons'
export const URL_GET_DOCUMENT_TYPES = '/api/Resource/Page2/GetDocumentType'
export const URL_GET_FAMILY_MEMBER_RELATION =
  '/api/Resource/Page2/GetFamilyMemberType'
export const URL_GET_NON_MEMBER_RELATION =
  '/api/Resource/Page2/GetNonFamilyMemberType'
export const URL_GET_SOCIAL_STATUSES = '/api/Resource/Page2/GetSocialStatus'
export const URL_GET_SOCIAL_EMPLOYEES_DATA =
  'Questionnaire/GetHouseholds/GetHouseholds'
const useGetQuestions = (url, options = { enabled: true }, params = {}) => {
  const queryKey = [url, params]

  const result = useQuery(queryKey, () => client.get(url, { params }), {
    ...options,
    select: (data) => data.data,
  })

  const { data, isSuccess } = result

  return {
    ...result,
    data: isSuccess ? data : [],
  }
}

export default useGetQuestions
