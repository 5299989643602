/* eslint-disable no-unused-vars */
import { Checkbox, Space, Row, Col } from 'antd'
import React, { useRef, useState } from 'react'
import { VerticalSpace } from '../space/vertical-space'
import { SmallInput } from '../input/small-input'
import FormItemWithName from '../form/form-item-with-name'
import { FamilyPersons } from '../select/family-persons'

const CheckboxWithComment = ({
  row,
  paddingLeft = 0,
  isViewFamilySelect = false,
}) => {
  const value = useRef({ id: row.id, comment: null })
  const [checked, setChecked] = useState(false)

  return (
    <VerticalSpace
      style={{
        paddingLeft,
        height: 26,
        margin: '0 0 45px 0',
      }}
    >
      <Space>
        <Row>
          <Col span={24}>
            <Checkbox
              value={value.current}
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              {row.name}
            </Checkbox>

            {row.id === 1 && checked && isViewFamilySelect && (
              <FormItemWithName>
                <FamilyPersons name={''} mode="multiple" />
              </FormItemWithName>
            )}
          </Col>
          <Col span={24} style={{ margin: '10px 0 0 0' }}>
            {row.hasComment && checked && (
              <FormItemWithName noStyle>
                <SmallInput
                  placeholder="Նշել չափը"
                  size="small"
                  onChange={(e) => (value.current.comment = e.target.value)}
                />
              </FormItemWithName>
            )}
          </Col>
        </Row>
      </Space>
    </VerticalSpace>
  )
}

export default CheckboxWithComment
