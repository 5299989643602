import { useEffect, useRef } from 'react'

export default (cb, dep) => {
  const firstUpdate = useRef(true)
  return useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    cb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep)
}
