import { Col, Form, Radio, Row, Space, Typography } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { ReactComponent as Hand } from '../../assets/hand.svg'

import { Datepicker } from '../../components/datepicker'
import { Input } from '../../components/input'
import { VerticalSpace } from '../../components/space/vertical-space'
import { Button } from '../../components/button'
import { Text, Title } from '../../components/typography'
import { Dropdown } from '../../components/dropdown'
import { Checkbox } from '../../components/checkbox'
import { DropdownList } from '../../components/dropdown/dropdown-list'
import { MainNavigationButton } from '../../components/button/main-navigation-button'
import { FormSectionCard } from '../../components/card/form-section-card'
import { Header } from '../../components/layouts/header'
import { SubHeader } from '../../components/layouts/sub-header'
import { ContentWrapper } from '../../components/layouts/content-wrapper'
import { WarningSection } from '../../components/card/warning-section'
import { DocumentUpload } from '../../components/upload/document-upload'
import { Info } from '../../components/typography/info'
import { ErrorText } from '../../components/typography/error-text'
import { LoginTab } from '../../components/tabs/login-tab'
import { Select } from '../../components/select'
import { ContentContainer } from '../../components/form/user/content-container'
import { ContentContainerSmall } from '../../components/form/user/content-container-small'
import { UserPassword } from '../../components/form/user/user-password'
import { UserLogin } from '../../components/form/user/user-login'

const items = [
  {
    key: '1',
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item
      </a>
    ),
  },
  {
    key: '3',
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item
      </a>
    ),
  },
]

export const UiComponents = () => {
  return (
    <VerticalSpace>
      <Header />
      <SubHeader icon={<Hand />} text="ՈՒՆԵՄ ՆՊԱՍՏԻ ԿԱՐԻՔ" />
      <Title level={1}>Վերնագիր</Title>
      <Title level={2}>Վերնագիր</Title>
      <Title level={3}>Վերնագիր</Title>
      <Text>ֆոնտ</Text>
      <Text strong>ֆոնտ</Text>
      <Typography.Title>Inputs</Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <VerticalSpace>
            <Input placeholder="Text Here" />
            <Select style={{ width: '150px' }} />
            <Input placeholder="Text Here" disabled />
          </VerticalSpace>
        </Col>
        <Col span={12}>
          <Datepicker />
        </Col>
      </Row>
      <WarningSection>
        Նույնականացման տվյալները անհրաժեշտ է լրացնել անձը հաստատող փաստաթղթի
        տվյալներին համապատասխան
      </WarningSection>
      <ContentWrapper>
        <Row gutter={16}>
          <Col span={12}>
            <VerticalSpace>
              <Button block type="primary">
                BUTTON
              </Button>
              <Button block size="large" type="primary">
                BUTTON
              </Button>
              <Button block disabled size="large" type="primary">
                BUTTON
              </Button>
              <Button block>BUTTON</Button>
              <Button block disabled>
                BUTTON
              </Button>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomLeft"
              >
                <Button type="primary">
                  <Space>
                    Ֆիլտրել
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              <DropdownList items={items} name="Կարգավիճակ" />
            </VerticalSpace>
          </Col>
          <Col span={12}>
            <MainNavigationButton
              icon={<UserOutlined style={{ fontSize: '30px' }} />}
            >
              Օգտվողներ
            </MainNavigationButton>
          </Col>
        </Row>
      </ContentWrapper>

      <Title level={2}>Dropdown</Title>
      <Row>
        <Col span={24}>
          <VerticalSpace>
            <Input placeholder="Մուտքանուն" />
            <Input placeholder="Մուտքանուն" value="Անուն" disabled />
            <Input placeholder="Մուտքանուն" error />
            <Form name="basic" layout="verti">
              <VerticalSpace>
                <DocumentUpload />
                <Info text="Նույնականացման համար անհրաժեշտ անձնական տվյալները պետք է լրացնել անձը հաստատող փաստաթղթի տվյալներին համապատասխան" />
                <ErrorText text="Նույնականացումը չի հաջողվել" />
              </VerticalSpace>
              <LoginTab />
              <FormSectionCard title="Գրանցել օգտվող">
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Checkbox>Հիշել</Checkbox>
                <Radio>Radio</Radio>
              </FormSectionCard>
            </Form>
          </VerticalSpace>
        </Col>
      </Row>

      <ContentContainer />

      <ContentContainerSmall />

      <UserPassword />

      <UserLogin />
      {/*<Notification title="Ծանուցում" text="Այս տվյալներով անհատական կաբինետ արդեն իսկ գրանցված է համակարգում։ Խնդրում ենք գրանցվել այլ տվյալներով։"/>*/}
    </VerticalSpace>
  )
}
