import React from 'react'
import { Form, Radio } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { chanceChooseLivePermanently, URL_GET_STATES } from './constant'
import { Input } from '../../input'
import { FIELD_NAMES_DEFAULT } from '../../select/constants'
import { Select } from '../../select'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Info } from '../../typography/info'
import { SecondaryText } from '../../typography'

export const ChanceChooseLivePermanently = () => {
  const form = Form.useFormInstance()
  const { data: states } = useGetQuestions(URL_GET_STATES)
  const radioList = Form.useWatch('question134AnswareId', {
    preserve: true,
  })

  const clearChildValues = () => {
    form.setFieldsValue({
      states: [], // Clear the select values
      question134AnswareComment: '', // Clear the input value
    })
  }

  return (
    <FormSectionCard
      title={
        'Եթե լինի հնարավորություն ընտրելու որտե՞ղ կցանկանաք մշտապես բնակվել'
      }
      showRequired={false}
    >
      <VerticalSpace>
        <Info
          text={
            'Հարցվողին ներկայացրեք, որ խոսքը գնում է ներկայիս պայմաններում և իրավիճակում նախընտրելի մշտական բնակության տեղի մասին, ոչ թե որևէ պայմանների փոփոխության դեպքում'
          }
        />
        <FormItem name="question134AnswareId" rules={[{ required: true }]}>
          <Radio.Group onChange={clearChildValues}>
            <VerticalSpace>
              {chanceChooseLivePermanently.map((item) => {
                return (
                  <div key={item.id}>
                    <Radio value={item.id}>{item.label}</Radio>
                    {radioList === 2 && item.id === 2 && (
                      <>
                        <FormItem
                          name={'states'}
                          initialValues={[]}
                          style={{ margin: '10px 0 0 20px' }}
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder="Ընտրեք տարածքը"
                            fieldNames={FIELD_NAMES_DEFAULT}
                            mode="multiple"
                            style={{ width: '100%' }}
                            options={states}
                          />
                        </FormItem>
                        <FormItem
                          name={'area'}
                          label={
                            <SecondaryText>
                              Խնդրում եմ նշել, արդյոք կնախընտրեիք բնակվել
                              գյուղական, թե՞ քաղաքային բնակավայրում
                            </SecondaryText>
                          }
                          style={{ margin: '10px 0 0 20px' }}
                        >
                          <Radio.Group>
                            <VerticalSpace>
                              <Radio value={1}>Գյուղական</Radio>
                              <Radio value={2}>Քաղաքային</Radio>
                              <Radio value={3}>Միևնույնն է</Radio>
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      </>
                    )}
                    {radioList === 6 && item.id === 6 && (
                      <FormItem
                        name={'question134AnswareComment'}
                        style={{ margin: '10px 0 0 20px' }}
                        label={'Այլ (նշել)'}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </FormItem>
                    )}
                  </div>
                )
              })}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
      </VerticalSpace>
    </FormSectionCard>
  )
}
