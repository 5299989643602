import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Checkbox, Form } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_RECEIPT_SUPPORT_TYPE } from './constants'
import CheckboxWithComment from '../../checkbox/checkbox-with-comment'

const SupportWays = () => {
  const { data } = useGetQuestions(URL_RECEIPT_SUPPORT_TYPE)

  return (
    <FormSectionCard
      title="Աջակցության ստացման ո՞ր եղանակներն են նախընտրելի Ձեզ համար"
      showRequired={false}
    >
      <Form.Item
        name="householdReceiptSupportTypes"
        rules={[{ required: true }]}
      >
        <Checkbox.Group>
          <VerticalSpace>
            {data.map((row) => (
              <CheckboxWithComment key={row.id} row={row} />
            ))}
          </VerticalSpace>
        </Checkbox.Group>
      </Form.Item>
    </FormSectionCard>
  )
}

export default SupportWays
