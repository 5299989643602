import React, { useCallback, useState } from 'react'
import { Checkbox, Col, Form, Row } from 'antd'
import { FormItem } from '../../../form/form-item'
import { Input } from '../../../input'
import { Text } from '../../../typography'
import { COLORS } from '../../../../helpers/constants'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { URL_GET_NON_SATISFIED_POLYCLINIC_REASON } from '../constants'

const ReasonsForDissatisfaction = () => {
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)

  const { data: nonPolyclinicReason } = useGetQuestions(
    URL_GET_NON_SATISFIED_POLYCLINIC_REASON,
  )

  // eslint-disable-next-line no-unused-vars
  const selectReasonsForYourDissatisfaction = Form.useWatch(
    'reasonsForYourDissatisfaction',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSatisfiedPolyclinicReasons = useCallback((checked, row) => {
    if (checked) {
      form.setFieldsValue({
        householdNonSatisfiedPolyclinicReasons: [
          ...form.getFieldValue('householdNonSatisfiedPolyclinicReasons'),
          { id: row.id, comment: null },
        ],
      })
    } else {
      form.setFieldsValue({
        householdNonSatisfiedPolyclinicReasons: form
          .getFieldValue('householdNonSatisfiedPolyclinicReasons')
          .filter((r) => r.id !== row.id),
      })
    }
  })

  const onInputChangeSatisfiedPolyclinicReasons = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdNonSatisfiedPolyclinicReasons')
        .findIndex((r) => r.id === id)
      const newData = [
        ...form.getFieldValue('householdNonSatisfiedPolyclinicReasons'),
      ]
      newData[index].comment = value
      form.setFieldsValue({ householdNonSatisfiedPolyclinicReasons: newData })
    },
    [form],
  )

  return (
    <FormItem
      name={['reasonsForYourDissatisfaction']}
      style={{ marginLeft: '25px' }}
      label={
        <Text color={COLORS.PRIMARY.GRAY_DARK}>
          Որո՞նք են Ձեր դժգոհության պատճառները
        </Text>
      }
      rules={[{ required: true }]}
    >
      {nonPolyclinicReason?.map((row) => (
        <FormItem
          name={['reasonsForYourDissatisfaction', row.label]}
          key={row.id}
          valuePropName="checked"
          style={{ marginLeft: '25px' }}
        >
          <FormItem
            name={['reasonsForYourDissatisfaction', row.label, 'id']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                setChecked(e.target.checked)
                onChangeSatisfiedPolyclinicReasons(e.target.checked, row)

                form.resetFields([
                  `reasonsForYourDissatisfactionInput${row.id}`,
                ])
              }}
              value={row.id}
            >
              {row.name}
            </Checkbox>
          </FormItem>

          {selectReasonsForYourDissatisfaction?.[row.label]?.id ? (
            row?.hasComment === true ? (
              <Row
                style={{
                  marginTop: '20px',
                  marginLeft: '25px',
                }}
              >
                <Col span={24}>
                  <FormItem
                    rules={[{ required: true }]}
                    name={`reasonsForYourDissatisfactionInput${row.id}`}
                  >
                    <Input
                      placeholder={'Նշել'}
                      onChange={(e) =>
                        onInputChangeSatisfiedPolyclinicReasons(
                          e.target.value,
                          row.id,
                        )
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
            ) : (
              <FormItem hidden />
            )
          ) : (
            <FormItem hidden />
          )}
        </FormItem>
      ))}
    </FormItem>
  )
}

export default ReasonsForDissatisfaction
