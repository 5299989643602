import { Skeleton } from 'antd'
import { forwardRef } from 'react'
import { Select } from '.'
import { FIELD_NAMES_DEFAULT } from './constants'
import { URL_ADDRESSES_BY_COMMUNITY_ID } from '../../api/application/constants'
import { useGetPublic } from '../../api/application/use-get-public'

export const AddressSelect = forwardRef(({ communityId, ...props }, ref) => {
  const { data, isInitialLoading } = useGetPublic(
    URL_ADDRESSES_BY_COMMUNITY_ID,
    { stateId: communityId },
    { enabled: !!communityId },
  )
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />
  }
  return (
    <Select
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      options={data}
      placeholder="Ընտրել"
      {...props}
      ref={ref}
    />
  )
})
