import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { SecondaryText } from '../../typography'
import { Radio } from 'antd'
import { Form } from '../../form/form'
import AddNonResideMemberForm from '../../form/application/family/add-non-reside-member'
import { AddFamilyMember } from '../../form/application/family/add-family-member'
import LostMemberForm from './has-lost-member-form'

import Notes from '../../form/application/notes'

const SecondStepForm = () => {
  const hasDisplacedPerson = Form.useWatch(
    ['familyData', 'notResidePersonsStayInSameAccommodation'],
    {
      preserve: true,
    },
  )
  const hasNonResidePerson = Form.useWatch(
    ['familyData', 'hasNonResidePerson'],
    {
      preserve: true,
    },
  )

  return (
    <>
      <VerticalSpace>
        <AddFamilyMember isFamilyMember={true} />
        <FormItem
          label={
            <SecondaryText>
              Կա՞ Ձեր ընտանիքի հետ ապրող ԼՂ-ից բռնի տեղահանված անձ, ով Ձեր
              ընտանիքի անդամ չէ
            </SecondaryText>
          }
          name={['familyData', 'notResidePersonsStayInSameAccommodation']}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
        {hasDisplacedPerson && <AddFamilyMember isFamilyMember={false} />}
        <FormItem
          label={
            <SecondaryText>
              Արդյո՞ք նախքան բռնի տեղահանումը, Ձեզ հետ միասին ապրել է այլ անձ,
              ով ներկայումս Ձեզ հետ այս նույն վայրում չի բնակվում
            </SecondaryText>
          }
          name={['familyData', 'hasNonResidePerson']}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
        {hasNonResidePerson && <AddNonResideMemberForm />}
        <LostMemberForm />
        <Notes formItemName={'page2notes'} />
      </VerticalSpace>
    </>
  )
}

export default SecondStepForm
