import React from 'react'
import { FormSectionFullCard } from '../../../card/form-section-full-card'
import { Title } from '../../../typography'
import { FormItem } from '../../form-item'
import { Radio, Space } from 'antd'

const NeedToSearchMember = ({ field }) => {
  return (
    <FormSectionFullCard>
      <Title>
        Արդյո՞ք անհրաժեշտ է որոնել այդ անձի ընտանիքի անդամներին (խնամակալներին,
        հարազատներին, այլ)
      </Title>
      <FormItem name={[field.name, 'searchFamilyMember']}>
        <Radio.Group>
          <Space>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Space>
        </Radio.Group>
      </FormItem>
    </FormSectionFullCard>
  )
}

export default NeedToSearchMember
