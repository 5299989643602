import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
// import { Header } from '../header'
import { ApplicationFillProvider } from '../../../context/applicaton-steps-context'
// import { SubHeader } from '../sub-header'
import { ApplicationSider } from '../../sider/application-sider'
import { ApplicationFillContentWrapper } from './application-content-wrapper'

export const ApplicationLayout = () => {
  return (
    <Layout style={{ minHeight: '80vh' }}>
      <ApplicationFillProvider>
        <ApplicationSider />
        <ApplicationFillContentWrapper>
          <Outlet />
        </ApplicationFillContentWrapper>
      </ApplicationFillProvider>
    </Layout>
  )
}
