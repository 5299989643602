import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const { Content: ContentComponent } = Layout

const Content = styled(ContentComponent)`
  background-image: url('./images/content-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
`

export const ContentWithBg = () => {
  return (
    <Content>
      <Outlet />
    </Content>
  )
}
