/* eslint-disable prettier/prettier */
import { Input as InputComponent } from 'antd'
import styled from 'styled-components'

export const Input = styled(InputComponent)`
  border-radius: 8px;
`

export const SmallInput = (props) => {
  return <Input {...props} />
}

export { TextArea } from './text-area'
