import { Checkbox, Radio } from 'antd'
import { useRef, useState } from 'react'
import { VerticalSpace } from '../../../space/vertical-space'
import FormItemWithName from '../../../form/form-item-with-name'

const CheckboxWithAnswer = ({ row }) => {
  const value = useRef({ id: row.id, answerId: null })
  const [checked, setChecked] = useState(false)
  return (
    <>
      <Checkbox
        value={value.current}
        onChange={(e) => setChecked(e.target.checked)}
      >
        {row.name}
      </Checkbox>

      {checked ? (
        <FormItemWithName noStyle>
          <Radio.Group
            style={{ margin: '12px 30px 0' }}
            onChange={(e) => (value.current.answerId = e.target.value)}
          >
            <VerticalSpace>
              <Radio value={1}>
                Այո, ես կամ ՏՏ այլ անդամ կիրառել ենք այս մեթոդը վերջին մեկ ամսվա
                ընթացքում
              </Radio>
              <Radio value={2}>
                Ոչ, ՏՏ-ում ոչ ոք չի կիրառել այս մեթոդը վերջին մեկ ամսվա
                ընթացքում
              </Radio>
            </VerticalSpace>
          </Radio.Group>
        </FormItemWithName>
      ) : null}
    </>
  )
}

export default CheckboxWithAnswer
