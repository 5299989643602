import { Select as SelectComponent } from 'antd'
import { placeholderSize } from '../../helpers/styles'
import styled from 'styled-components'

export const Select = styled(SelectComponent)`
  && {
    .ant-select-selector {
      background: linear-gradient(
        91.78deg,
        rgba(255, 255, 255, 0.64) 6.81%,
        rgba(255, 255, 255, 0.16) 100%
      );
      .ant-select-selection-placeholder {
        ${placeholderSize}
        display: flex;
        align-items: center;
        @media (max-width: 801px) {
          font-size: 14px;
        }
      }
      @media (max-width: 801px) {
        font-size: 14px;
        overflow: hidden;
      }
      .ant-select-selection-item {
        @media (max-width: 801px) {
          overflow-x: scroll;
          text-overflow: clip;
          margin-right: 10px;
        }
      }
    }
  }
`

export const StyledMenu = styled.div`
  .rc-virtual-list-holder-inner {
    .ant-select-item {
      @media (max-width: 801px) {
        font-size: 14px;
      }
      white-space: normal;
      word-wrap: break-word;
    }
  }
  .ant-select-item-option-content {
    white-space: normal;
    word-wrap: break-word;
  }
`
