import { Form } from 'antd'
import { useId } from 'react'

const FormItemWithName = ({ children, ...props }) => {
  const name = useId()
  return (
    <Form.Item name={name} rules={[{ required: true }]} {...props}>
      {children}
    </Form.Item>
  )
}

export default FormItemWithName
