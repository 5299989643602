import { Input } from '../../../components/input'
import { Col, Row } from 'antd'
import { Button } from '../../button'
import { Checkbox } from '../../checkbox'
import { Form } from '../form'
import { Password } from '../../input/password'

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

export const UserLogin = () => {
  return (
    <Form name="user-login" layout="verti">
      <Row gutter={30}>
        <Col span={24}>
          <Form.Item
            {...formItemLayout}
            label="Էլ․ հասցե"
            name="userLoginEmail"
          >
            <Input placeholder="example@gmail.com" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            {...formItemLayout}
            label="Գաղտնաբառ"
            name="userLoginPassword"
          >
            <Password />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="userLoginRemember">
            <Checkbox>Հիշել</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12} align="right">
          <a href="#">Մոռացել եմ գաղտնաբառը</a>
        </Col>
        <Col span={24}>
          <Button block size="large" type="primary">
            Մուտք
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
