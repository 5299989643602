import React, { useCallback, useState } from 'react'
import { Form, Radio, Spin } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { GET_TERMINATION_REASON } from './constants'
import NestedCheckboxes from './nested-checkboxes'
import useGetQuestions from '../../../api/application/use-get-questions'
import { SecondaryText } from '../../typography'
import { InformationAffectedFactor } from './information-affected-factor'

export const NeedsAssessmentTerminationCheck = () => {
  const [value, setValue] = useState(null)
  const form = Form.useFormInstance()
  const { data: questions, isLoading } = useGetQuestions(GET_TERMINATION_REASON)

  const onCheckboxChange = useCallback(
    (checked, row) => {
      if (checked) {
        if (!row.children.length) {
          form.setFieldsValue({
            householdTerminationReasons: [
              ...form.getFieldValue('householdTerminationReasons'),
              { terminationReasonId: row.id, comment: null },
            ],
          })
        }
      } else {
        form.setFieldsValue({
          householdTerminationReasons: form
            .getFieldValue('householdTerminationReasons')
            .filter((r) => r.terminationReasonId !== row.id),
        })
      }
    },
    [form],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdTerminationReasons')
        .findIndex((r) => r.terminationReasonId === id)
      const newData = [...form.getFieldValue('householdTerminationReasons')]
      newData[index].comment = value
      form.setFieldsValue({ householdTerminationReasons: newData })
    },
    [form],
  )

  return (
    <FormSectionCard
      showRequired={false}
      title="Արդյոք կարիքների գնահատման ընթացքը դադարեցվել է նախատեսված ժամանակից շուտ (հարցաշարը լրացվել է ոչ ամբողջական)"
    >
      <FormItem name="householdTerminationReasons" hidden initialValue={[]} />
      <Spin spinning={isLoading}>
        <FormItem name={'isTermination'} rules={[{ required: true }]}>
          <Radio.Group
            style={{ marginBottom: 20 }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
          >
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>

        {value === true ? (
          <React.Fragment>
            <FormItem
              label={<SecondaryText>Ո՞րն էր պատճառը</SecondaryText>}
              style={{ marginLeft: '20px' }}
              rules={[{ required: true }]}
              name={['householdTerminationReasons', 'terminationReasonId']}
            >
              <VerticalSpace>
                {questions.map((row) => (
                  <FormItem
                    key={row.id}
                    name={[
                      'householdTerminationReasons',
                      row.id,
                      'terminationReasonId',
                    ]}
                  >
                    <NestedCheckboxes
                      row={row}
                      onCheckboxChange={onCheckboxChange}
                      onInputChange={onInputChange}
                      level={0}
                      rules={[{ required: true }]}
                    />
                  </FormItem>
                ))}
              </VerticalSpace>
            </FormItem>
          </React.Fragment>
        ) : null}
        <InformationAffectedFactor />
      </Spin>
    </FormSectionCard>
  )
}
