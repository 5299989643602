import { VerticalSpace } from '../../space/vertical-space'
import { BeforeIncomeTypes } from './before-income-types'
import { AfterIncomeTypes } from './after-income-types'
import { AverageMonthlyIncome } from './average-monthly-income'
import { DailyHouseholdNeeds } from './daily-household-needs'
// import { DoesTheFamilyHave } from './does-the-family-have'
import { IncomeAfterDisplacement } from './income-after-displacement'

export const NinthStep = () => {
  return (
    <VerticalSpace>
      <BeforeIncomeTypes />

      <AfterIncomeTypes />

      <AverageMonthlyIncome />

      <IncomeAfterDisplacement />

      {/*<DoesTheFamilyHave />*/}

      <DailyHouseholdNeeds />
    </VerticalSpace>
  )
}
