import React from 'react'
import { router } from './router'
import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { antTheme } from './helpers/ant-theme'
import { antForm } from './helpers/ant-form'

import './index.css'
import { AuthProvider } from './context/auth-context'
import hy from 'antd/locale/hy_AM'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const App = () => {
  return (
    <ConfigProvider theme={antTheme} form={antForm} locale={hy}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <React.StrictMode>
            <RouterProvider router={router} />
          </React.StrictMode>
        </QueryClientProvider>
      </AuthProvider>
    </ConfigProvider>
  )
}
