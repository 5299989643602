export const personInfoFields = [
  {
    label: 'Անուն',
    name: 'personName',
  },
  {
    label: 'Ազգանուն',
    name: 'personSurname',
  },
  {
    label: 'Հայրանուն',
    name: 'personPatronymic',
  },
]

export const genders = [
  {
    label: 'Իգական',
    id: 1,
  },
  {
    label: 'Արական',
    id: 2,
  },
]

// 4
export const roomAppliances = [
  {
    label: 'Լոգախցիկ կամ լոգանքի համար որոշակի տարածք (ցնցուղի առկայություն)',
    name: 'bathroom',
    id: 1,
  },
  {
    label: 'Կոյուղի ',
    name: 'sewage',
    id: 2,
  },
  {
    label: 'Զուգարան ',
    name: 'toilet',
    id: 3,
  },
  {
    label: 'Խոհանոց',
    name: 'kitchen',
    id: 4,
  },
  {
    label: 'Լվացարան',
    name: 'WC',
    id: 5,
  },
  {
    id: 6,
    label: 'Օճառ',
    name: 'soap',
    subQuestions: [],
  },
  {
    id: 7,
    label: 'Հոսող (ծորակից) տաք ջուր',
    name: 'hotDrinkingWater',
  },
  {
    id: 8,
    label: 'Հոսող (ծորակից) սառը ջուր',
    name: 'coldDrinkingWater',
  },
  {
    id: 9,
    label: 'Կենցաղային օգտագործման նպատակով հոսող սառը ջուր ',
    name: 'coldWaterHousehold',
  },
  {
    id: 10,
    label: 'Կենցաղային օգտագործման նպատակով հոսող տաք ջուր ',
    name: 'hotWaterHousehold',
  },
  {
    id: 11,
    label: 'Ննջասենյակ կամ քնելու համար տարածք',
    name: 'bedroom',
  },
  {
    id: 12,
    label: 'Մահճակալ/քնելու հարմարանք',
    name: 'bed',
  },
  {
    id: 13,
    label: 'Գազ',
    name: 'gas',
  },
  {
    id: 14,
    label: 'Էլեկտրաէներգիա',
    name: 'electricity',
  },
  {
    id: 15,
    label: 'Ջեռուցում',
    name: 'heatingSystem',
  },
  {
    id: 16,
    label: 'Օդափոխություն (տարածքը օդափոխելու որևէ հնարավորություն) ',
    name: 'ventilation',
  },
  {
    id: 17,
    label: 'Ինտերնետ',
    name: 'internet',
  },
  {
    id: 18,
    label: 'Ֆիքսված հեռախոսակապ',
    name: 'phoneLine',
  },
  {
    id: 19,
    label: 'Բջջային կապ',
    name: 'cellPhoneLine',
  },
]

// 4
export const adjustmentsForDisabled = [
  {
    id: 1,
    label: 'Թեքահարթակ',
    name: 'ramp',
  },
  {
    id: 2,
    label: 'Բռնակների տեղադրում տան տարբեր հատվածներում',
    name: 'handles',
  },
  {
    id: 3,
    label: 'Դռների մատչելիություն',
    name: 'door',
  },
  {
    id: 4,
    label: 'Խոհանոցի հարմարեցում',
    name: 'kitchen',
  },
  {
    id: 5,
    label: 'Սանհանգույցի հարմարեցում',
    name: 'bathroom',
  },
  {
    id: 6,
    label: 'Այլ (նշել)',
    name: 'other',
  },
]

// 4
export const waterSupply = [
  {
    label: 'Գնում է /առնում է',
    value: 1,
  },
  {
    label: 'Բնական աղբյուրից է բերում ',
    value: 2,
  },
  {
    label: 'Տարաներով (բակերով) հավաքում է տանը',
    value: 3,
  },
  {
    label: 'Վերցնում է հարևանից',
    value: 4,
  },
  {
    label: 'Այլ (նշել)',
    value: 5,
  },
]

// 4
export const heatingSystemOptions = [
  {
    label: 'Ջեռուցման համակարգը լավ չի աշխատում',
    value: 1,
  },
  {
    label: 'Ջերմամեկուսացումն ապահովված չէ',
    value: 2,
  },
  {
    label: 'Վառելիքի գին կամ հասանելիություն',
    value: 3,
  },
  {
    label: 'Այլ (նշել)',
    value: 4,
  },
]

// 4
export const utilityServices = [
  {
    id: 1,
    label: 'Էլեկտրաէներգիա',
    name: 'electricity',
  },
  {
    id: 2,
    label: 'Գազ',
    name: 'gas',

    children: [],
  },
  {
    id: 3,
    label: 'Ջուր',
    name: 'water',
  },
  {
    id: 4,
    label: 'Ինտերնետ կապ',
    name: 'internet',
  },
]

// 4
export const utilityProducts = [
  {
    id: 1,
    name: 'Լվացքի մեքենա',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 2,
    name: 'Սառնարան',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 3,
    name: 'Վառարան (սնունդ պատրաստելու)՝',
    label: '',
    hasComment: false,
    children: [
      {
        id: 4,
        name: 'Գազօջախ',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 5,
        name: 'Էլեկտրական /պլիտա/',
        label: '',
        hasComment: false,
        children: [],
      },
    ],
  },
  {
    id: 6,
    name: 'Ջեռոց /դուխովկա/',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 7,
    name: 'Տաքացուցիչ/վառարան',
    label: '',
    hasComment: false,
    children: [
      {
        id: 8,
        name: 'Էլեկտրական',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 9,
        name: 'Փայտով աշխատող',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 10,
        name: 'Գազով աշխատող',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 11,
        name: 'Հեղուկ վառելիքով աշխատող',
        label: '',
        hasComment: false,
        children: [],
      },
    ],
  },
  {
    id: 12,
    name: 'Պահարան',
    label: '',
    hasComment: false,
    children: [
      {
        id: 13,
        name: 'Զգեստապահարան',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 14,
        name: 'Խոհանոցային',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 15,
        name: 'Գրապահարան',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 16,
        name: 'Այլ (նշել)',
        label: '',
        hasComment: true,
        isCommentNumeric: false,
        children: [],
      },
    ],
  },
  {
    id: 17,
    name: 'Վառելիք',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 18,
    name: 'Սեղան',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 19,
    name: 'Աթոռ',
    label: 'Նշել թիվը',
    hasComment: true,
    isCommentNumeric: true,
    children: [],
  },
  {
    id: 20,
    name: 'Խոհանոցային սպասք',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 21,
    name: 'Մահճակալ',
    label: '',
    hasComment: false,
    children: [
      {
        id: 22,
        name: 'Մահճակալ՝ մեծահասակի',
        label: 'Նշել թիվը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 23,
        name: 'Մահճակալ՝ երեխայի',
        label: 'Նշել թիվը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 24,
        name: 'Ծալվող մահճակալ',
        label: 'Նշել թիվը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 25,
        name: 'Ներքնակ/մատրաս',
        label: 'Նշել թիվը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
    ],
  },
  {
    id: 26,
    name: 'Հիգիենայի պարագաներ',
    label: '',
    hasComment: false,
    children: [
      {
        id: 27,
        name: 'Անձնական հիգիենայի պարագաների փաթեթ',
        label: 'Նշել թիվը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 28,
        name: 'Կենցաղային մաքրման պարագաներ',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 29,
        name: 'Ախտահանիչ նյութեր',
        label: '',
        hasComment: false,
        children: [],
      },
    ],
  },
  {
    id: 30,
    name: 'Տակդիր/միջադիր/ջրակայուն սավան',
    label: '',
    hasComment: false,
    children: [
      {
        id: 31,
        name: 'Մեծահասակի համար նախատեսված',
        label: 'Նշել չափսը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 32,
        name: 'Երեխայի համար նախատեսված',
        label: 'Նշել չափսը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 33,
        name: 'Կնոջ միջադիր',
        label: 'Նշել թիվը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
      {
        id: 34,
        name: 'Ջրակայուն սավան',
        label: 'Նշել չափսը',
        hasComment: true,
        isCommentNumeric: true,
        children: [],
      },
    ],
  },
  {
    id: 35,
    name: 'Անկողնային պարագաներ',
    label: '',
    hasComment: false,
    children: [
      {
        id: 36,
        name: 'Բարձ',
        label: 'Նշել փաթեթի թիվը',
        hasComment: true,
        isCommentNumeric: true,

        children: [],
      },
      {
        id: 37,
        name: 'Վերմակ',
        label: 'Նշել փաթեթի թիվը',
        hasComment: true,
        isCommentNumeric: true,

        children: [],
      },
      {
        id: 38,
        name: 'Ծածկոց',
        label: 'Նշել փաթեթի թիվը',
        hasComment: true,
        isCommentNumeric: true,

        children: [],
      },
      {
        id: 39,
        name: 'Սպիտակեղեն(սավան, բարձի երես, այլ)',
        label: 'Նշել փաթեթի թիվը',
        hasComment: true,
        isCommentNumeric: true,

        children: [],
      },
      {
        id: 40,
        name: 'Այլ (նշել)',
        label: '',
        hasComment: true,
        isCommentNumeric: false,

        children: [],
      },
    ],
  },
  {
    id: 41,
    name: 'Սնունդ',
    label: '',
    hasComment: false,
    children: [
      {
        id: 42,
        name: 'Չոր (չեփված) սննդամթերք',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 43,
        name: 'Պատրաստի եփած սնունդ',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 44,
        name: 'Մանկական սնունդ',
        label: '',
        hasComment: false,
        children: [],
      },
      {
        id: 45,
        name: 'Դիետիկ սնունդ',
        label: 'Նշել առանձնահատկությունները',
        hasComment: true,
        isCommentNumeric: false,
        children: [],
      },
      {
        id: 46,
        name: 'Այլ (նշել)',
        label: '',
        hasComment: true,
        isCommentNumeric: false,
        children: [],
      },
    ],
  },
  {
    id: 47,
    name: 'Սեզոնային հագուստ',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 48,
    name: 'Սեզոնային կոշիկ',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 49,
    name: 'Դեղեր',
    label: 'Նշել',
    hasComment: true,
    children: [],
  },
  {
    id: 50,
    name: 'Բջջային հեռախոս',
    label: '',
    hasComment: false,
    children: [],
  },
  {
    id: 51,
    name: 'Այլ ',
    label: 'Նշել',
    hasComment: true,
    children: [],
  },
  {
    id: 52,
    name: 'Կենցաղի կազմակերպման հարցում որևէ կարիք չունի',
    label: '',
    hasComment: false,
    children: [],
  },
]

// 2
export const nonResideReasons = [
  {
    label: 'Գտնվում է կալանավայրում կամ քրեակատարողական հիմնարկում',
    id: 1,
    hasComment: false,
  },
  {
    label: 'Գտնվում է արտերկրում',
    id: 2,
    hasComment: false,
  },
  {
    label:
      'Պարտադիր զինվորական ծառայության զորակոչված շարքային կազմի ժամկետային զինծառայող է կամ պահեստազորային կամ զորահավաքային զինվորական ծառայության զորակոչված զինծառայող է ',
    id: 3,
    hasComment: false,
  },
  {
    label: 'Ռազմաուսումնական հաստատություններում սովորող կուրսանտ է',
    id: 4,
    hasComment: false,
  },
  {
    label: 'Գտնվելու վայրն անհայտ է',
    id: 5,
    hasComment: false,
  },
  {
    label: 'Գերեվարված է ',
    id: 6,
    hasComment: false,
    children: [
      {
        type: 'select',
        passRedCross: true,
        id: 6,
        title:
          'Արդյո՞ք այս հարցով դիմել եք Կարմիր խաչի միջազգային կոմիտե / Հայկական կարմիր խաչի ընկերություն',
        options: [
          {
            label: 'Այո դիմել է և լրացրել է հայտ',
            id: 1,
          },
          {
            label: 'Այո դիմել է, սակայն չի լրացրել հայտ',
            id: 2,
          },
          {
            label: 'Ոչ',
            id: 3,
          },
          {
            label: 'Դժվարանում է պատասխանել',
            id: 4,
          },
        ],
      },
    ],
  },
  {
    label: 'Շարունակում է մնալ ԼՂ-ում',
    id: 7,
    hasComment: false,
    children: [
      {
        title:
          'Արդյո՞ք այս հարցով դիմել եք Կարմիր խաչի միջազգային կոմիտե / Հայկական կարմիր խաչի ընկերություն',
        type: 'select',
        passRedCross: true,
        id: 7,
        options: [
          {
            label: 'Այո դիմել է և լրացրել է հայտ',
            id: 1,
          },
          {
            label: 'Այո դիմել է, սակայն չի լրացրել հայտ',
            id: 2,
          },
          {
            label: 'Ոչ',
            id: 3,
          },
          {
            label: 'Դժվարանում է պատասխանել',
            id: 4,
          },
        ],
      },
    ],
  },
  {
    label:
      'Բնակվում է այլ կացարանում (ներառյալ նաև խնամքի հաստատություններում և աջակցության կենտրոններում)',
    id: 10,
    hasComment: false,

    children: [
      {
        label: 'Ուսման նպատակով',
        id: 13,
        hasComment: false,
        title:
          'Ի՞նչ հանգամանքներում է (ի՞նչ նպատակով է)  նա բնակվել այլ կացարանում',
      },
      {
        label: 'Համայնքի/պետության կողմից ուղղորդվել է բնակության այլ վայր',
        id: 14,
        hasComment: false,
      },
      {
        label: 'Մասնագիտական աջակցություն ստանալու նպատակով',
        id: 15,
        hasComment: false,
      },
      {
        label: 'Այլ',
        id: 16,
        hasComment: true,
      },
    ],
  },
  {
    label:
      'Գտնվում է առողջապահական հաստատությունում (հիվանդանոցում, հոգեկան առողջության կենտրոնում, այլ)',
    id: 11,
    hasComment: false,
  },
  {
    label: 'Այլ',
    id: 12,
    hasComment: true,
  },
]

export const physycalSupportOptions = [
  {
    id: 1,
    label: 'Հիվանդանոցային պայմաններում',
    hasComment: false,
  },
  {
    id: 2,
    label: 'Պոլիկլինիկա (ամբուլատորիա, բուժկետ) այցելությունների միջոցով',
    hasComment: false,
  },
  {
    id: 3,
    label: 'Տնային պայմաններում',
    hasComment: false,
  },
  {
    id: 4,
    label: 'Շտապ օգնության կանչ',
    hasComment: false,
  },
  {
    id: 5,
    label: 'Այլ',
    hasComment: true,
  },
]

export const medicalSupportFrequency = [
  {
    id: 1,
    label: 'Ամեն օր',
    hasComment: false,
  },
  {
    id: 2,
    label: 'Շաբաթվա մեջ մի քանի օր',
    hasComment: false,
  },
  {
    id: 3,
    label: 'Ամսվա մեջ մի քանի անգամ',
    hasComment: false,
  },
  {
    id: 4,
    label: 'Ըստ կարիքի',
    hasComment: false,
  },
  {
    id: 5,
    label: 'Այլ',
    hasComment: true,
  },
]

export const nonCarerReason = [
  {
    id: 1,
    label: 'Կարիքը չկա',
    hasComment: false,
  },
  {
    id: 2,
    label:
      'Կարիքը կա, սակայն խնամող չկա՝ հարազատներ չունենալու կամ նրանց կողմից՝ հիվանդանոցում խնամք իրականացնելը հարմար չլինելու պատճառով',
    hasComment: false,
  },
  {
    id: 3,
    label: 'այլ (նշել)',
    hasComment: true,
  },
]

export const childNonResideReasons = [
  {
    id: 1,
    hasComment: false,
    label: 'Երեխան ծնողներ չունի',
  },
  {
    id: 2,
    hasComment: false,
    label: 'Երեխան կորցրել է կապը ծնողների հետ, նրանց գտնվելու վայրը հայտնի չէ',
  },
  {
    id: 3,
    hasComment: false,
    label:
      'Երեխայի ծնողների բնակության պայմանները հարմար չեն, ուստի ժամանակավոր այլ տեղում է ապրում',
  },
  {
    id: 4,
    hasComment: false,
    label:
      'Երեխայի ծնողները վատ են վարվել նրա հետ, այդ պատճառով չի ցանկանում նրանց հետ ',
  },
  {
    id: 5,
    hasComment: true,
    label: 'Այլ',
  },
]

export const nonMedicalSupportReasons = [
  {
    id: 1,
    label:
      'Վնասվածքը ծանր չի եղել և հնարավոր է եղել հաղթահարել առանց բժշկական օգնության և սպասարկմանԵղել են փաստաթղթային խնդիրներ',
    hasComment: false,
  },
  {
    id: 2,
    label: 'Եղել են փաստաթղթային խնդիրներ',
    hasComment: false,
  },
  {
    id: 3,
    label: 'Եղել են ֆինանսական խնդիրներ',
    hasComment: false,
  },
  {
    id: 4,
    label:
      'Անձը կամ նրա հարազատները հրաժարվել են բժշկական օգնությունից և սպասարկումից',
    hasComment: false,
  },
  {
    id: 5,
    label: 'Տվյալ ծառայությունը համայնքում չկա',
    hasComment: false,
  },
  {
    id: 6,
    label: 'Դեռևս այդ հարցին ոչ ոք չի հասցրել անդրադառնալ',
    hasComment: false,
  },
  {
    id: 7,
    label: 'Դեռ չի դիմել բժշկական օգնություն ու սպասարկում ստանալու համար',
    hasComment: false,
  },
  {
    id: 8,
    label: 'Բժշկական օգնության և սպասարկման կարիքն այլևս չկա',
    hasComment: false,
  },
  {
    id: 9,
    label: 'Տեղյակ չեն, թե որտեղ է ծառայությունը',
    hasComment: false,
  },
  {
    id: 10,
    label: 'Տեղյակ չեն, թե ինչպես դիմել այդ ծառայությանը',
    hasComment: false,
  },
  {
    id: 11,
    label: 'Այլ',
    hasComment: true,
  },
]

export const psychicSpecialistTypes = [
  {
    id: 1,
    label: 'Հոգեբույժ',
    hasComment: false,
  },
  {
    id: 2,
    label: 'Հոգեբան',
    hasComment: false,
  },
  {
    id: 3,
    label: 'Սոցիալական աշխատող',
    hasComment: false,
  },
  {
    id: 4,
    label: 'Ընտանեկան բժիշկ',
    hasComment: false,
  },
  {
    id: 5,
    label: 'Այլ',
    hasComment: true,
  },
]

export const psychicSupportTerminationReasons = [
  {
    label: 'Բնակության վայրից/կացարանից մեկ այլ վայր տեղափոխվելը',
    id: 1,
    hasComment: false,
  },
  {
    label: 'Մասնագիտական միջամտության արդյունավետ չլինելը (ըստ շահառուի)',
    id: 2,
    hasComment: false,
  },
  {
    label:
      'Մասնագիտական միջամտության արդյունավետ չլինելը (ըստ ծառայություն տրամադրող մասնագետի)',
    id: 3,
    hasComment: false,
  },
  {
    label: 'Ծառայությունը տրամադրող մասնագետի հանդեպ վստահության կորուստ',
    id: 4,
    hasComment: false,
  },
  {
    label: 'Ծառայությունը տրամադրող մասնագետն է խորհուրդ տվել դադարեցնել',
    id: 5,
    hasComment: false,
  },
  {
    label: 'Այլ',
    id: 6,
    hasComment: true,
  },
]

export const nonPsychicSupportReasons = [
  {
    label: 'Տվյալ աջակցությունը համայնքում չկա',
    id: 1,
    hasComment: false,
  },
  {
    label: 'Դեռևս այդ հարցին ոչ-ոք չի հասցրել անդրադառնալ',
    id: 2,
    hasComment: false,
  },
  {
    label: 'Չունեն վստահություն մասնագիտական աջակցության հանդեպ',
    id: 3,
    hasComment: false,
  },
  {
    label: 'Ունեն ենթադրություն, որ որոշ ժամանակ անց իրավիճակը կկայունանա',
    id: 4,
    hasComment: false,
  },
  {
    label:
      'Տեղյակ չեն, թե ինչպես դիմեն՝ մասնագիտական աջակցություն ստանալու համար',
    id: 5,
    hasComment: false,
  },
  {
    label: 'Եղել են ֆինանսական խնդիրներ',
    id: 6,
    hasComment: false,
  },
  {
    label: 'Մասնագիտական աջակցության կարիքն այլևս չկա',
    id: 7,
    hasComment: false,
  },
  {
    label: 'Այլ',
    id: 8,
    hasComment: true,
  },
]

export const reletionshipTypesWithFamMembers = [
  {
    id: 1,
    name: 'Աջակցային',
    children: [],
  },
  {
    id: 2,
    name: 'Անտարբեր',
    children: [],
  },
  {
    id: 3,
    name: 'Լարված',
    children: [],
  },
  {
    id: 4,
    name: 'Բռնություն կամ բռնության վտանգ',
    children: [],
  },
  {
    id: 5,
    name: 'Չի ցանկանում պատասխանել',
    children: [],
  },
  {
    id: 6,
    name: 'Այլ ',
    children: [],
  },
]

export const reletionshipTypesWithAccomodationMembers = [
  {
    id: 1,
    name: 'Աջակցային',
    children: [],
    hasComment: false,
  },
  {
    id: 2,
    name: 'Անտարբեր',
    children: [],
    hasComment: false,
  },
  {
    id: 3,
    name: 'Լարված',
    children: [],
    hasComment: false,
  },
  {
    id: 4,
    name: 'Բռնություն կամ բռնության վտանգ',
    children: [],
    hasComment: false,
  },
  {
    id: 5,
    name: 'Չի ցանկանում պատասխանել',
    children: [],
    hasComment: false,
  },
  {
    id: 7,
    name: 'Դժվարանում է պատասխանել',
    children: [],
    hasComment: false,
  },
  {
    id: 6,
    name: 'Այլ ',
    children: [],
    hasComment: true,
  },
]

export const manifestationBy = [
  {
    id: 1,
    name: 'Ընտանիքի անդամի',
    children: [],
    hasComment: false,
  },
  {
    id: 2,
    name: 'Նախկին զուգընկեր/ամուսին/կին',
    children: [],
    hasComment: false,
  },
  {
    name: 'Այլ',
    children: [],
    id: 6,
    hasComment: true,
  },
]

export const whatSupportDoesItNeed = [
  {
    id: 1,
    name: 'Բռնարարից հեռու ապահով վայր',
    children: [],
  },
  {
    id: 2,
    name: 'Սոցիալ-հոգեբանական աջակցություն',
    children: [],
  },
  {
    name: 'Զբաղվածության հարցում աջակցություն',
    children: [],
    id: 3,
  },
  {
    name: 'Բժշկական օգնություն և սպասարկում',
    children: [],
    id: 4,
  },
  {
    name: 'Կրթությունը կազմակերպելու հարցում աջակցություն',
    children: [],
    id: 5,
  },
  {
    name: 'Խորհրդատվություն',
    children: [],
    id: 6,
  },
  {
    name: 'Ընտանիքում բռնության ենթարկված անձանց աջակցության կենտրոնին դիմում',
    children: [],
    id: 7,
  },
  {
    name: 'Ինքնախնամքի միջոցներ/ռազմավարություններ',
    children: [],
    id: 8,
  },
  {
    name: 'Այլ',
    children: [],
    id: 9,
  },
]

export const accommodationTypes = [
  {
    id: 1,
    label: 'Պետության կամ համայնքի կողմից առաջարկված կամ փոխհատուցված կացարան',
    hasComment: false,
    children: [
      {
        id: 2,
        label: 'հյուրանոց/հյուրատուն',
        hasComment: false,
      },
      {
        id: 3,
        label:
          'բնակության համար չնախատեսված շենք, որը ծառայում է որպես կացարան (օրինակ՝ մանկապարտեզ)',
        hasComment: false,
      },
      {
        id: 4,
        label: 'առանձնատուն',
        hasComment: false,
      },
      {
        id: 5,
        label: 'խնամքի ընդհանուր տիպի հաստատություն',
        hasComment: false,
      },
      {
        id: 6,
        label:
          'խնամքի մասնագիտացված հաստատություն ընտանիքում բռնության ենթարկված աջակցության կենտրոն',
        hasComment: false,
      },
      {
        id: 7,
        label: 'Այլ',
        hasComment: true,
      },
    ],
  },
  {
    label: 'Բարեկամի/հարազատի տուն',
    id: 8,
    hasComment: false,
  },
  {
    label: 'Իր կամ իր ընտանիքի անդամների սեփականություն հանդիսացող բնակարան',
    id: 9,
    hasComment: false,
  },
  {
    label: 'Վարձակալված կացարան/բնակարան',
    id: 10,
    hasComment: false,
  },
  {
    label:
      'Կոմունալ (ընդհանուր սանհանգույց, զուգարան, այլ) պայմաններով կացարան',
    id: 11,
    hasComment: false,
  },
  {
    label: 'Ավտո(տնակ)',
    id: 12,
    hasComment: false,
  },
  {
    label: 'Բժշկական հաստատություն (նշել)',
    id: 13,
    hasComment: true,
  },
  {
    label: 'Այլ',
    id: 14,
    hasComment: true,
  },
  {
    label: 'Տեղեկություն չունեմ',
    id: 15,
    hasComment: false,
  },
]

export const howDidItManifestItself = [
  {
    id: 1,
    name: 'Ծառայությունը կամ աջակցությունը չի տրամադրվել',
    children: [],
  },
  {
    id: 2,
    name: 'Վիրավորանք',
    children: [],
  },
  {
    id: 3,
    name: 'Ատելության խոսք',
    children: [],
  },
  {
    id: 4,
    name: 'Այլ',
    children: [],
    hasComment: true,
  },
]

export const reuniteSupport = [
  {
    id: 1,
    label: 'ՈրևԷ աջակցության կարիք չկա',
    hasComment: false,
  },
  {
    id: 2,
    label: 'Իրավական խորհրդատվություն',
    hasComment: false,
  },
  {
    id: 3,
    label: 'Կացարանի հարցում աջակցություն',
    hasComment: false,
    children: [
      {
        id: 4,
        label: 'Մասնակի վերանորոգում',
        hasComment: false,
      },
      {
        id: 5,
        label: 'Ամբողջական վերանորոգում',
        hasComment: false,
      },
      {
        id: 6,
        label: 'Տարածքի ընդլայնում',
        hasComment: false,
      },
      {
        id: 7,
        label: 'Մատչելիության ապահվում',
        hasComment: true,
      },
      {
        id: 8,
        label: 'Այլ',
        hasComment: true,
      },
    ],
  },
  {
    id: 9,
    label: 'Այլ',
    hasComment: true,
  },
]

export const nonReuinteReasons = [
  {
    id: 1,
    label:
      'Վերամիավորման դեպքում՝ ընտանիքի որևէ անդամի կենսական ծառայությունների տրամադրումը չի կարող իրականացվել',
    hasComment: false,
    children: [
      {
        id: 2,
        label: 'Բժշկական օգնություն և սպասարկում',
        hasComment: false,
        children: [
          {
            id: 3,
            label: 'Հոգեբուժական բժշկական օգնություն',
            hasComment: false,
          },
          {
            id: 4,
            label: 'Վերականգնողական բժշկական օգնություն',
            hasComment: false,
          },
          {
            id: 5,
            label: 'Բժշկական օգնություն և սպասարկում',
            hasComment: false,
          },
          {
            id: 6,
            label: 'Այլ',
            hasComment: true,
          },
        ],
      },
      {
        id: 7,
        label: 'Կրթական ծառայություններ',
        hasComment: false,
      },
      {
        id: 8,
        label: 'Սոցիալ-հոգեբանական օգնություն',
        hasComment: false,
      },
      {
        id: 9,
        label: 'Խնամքի ծառայություններ',
        hasComment: false,
      },
      {
        id: 10,
        label: 'Դժվարանում է պատասխանել',
        hasComment: false,
      },
      {
        id: 11,
        label: 'Այլ',
        hasComment: true,
      },
    ],
  },
  {
    id: 12,
    label: 'Առկա են տեղափոխման/տրանսպորտի հետ կապված խնդիրներ',
    hasComment: false,
  },
  {
    id: 13,
    label:
      'Ընտանիքի անդամների հետ առկա են կոնֆլիկտային/լարված հարաբերություններ',
    hasComment: false,
  },
  {
    id: 14,
    label: 'Առկա են կացարանի հետ կապված խնդիրներ',
    hasComment: false,
    children: [
      {
        id: 15,
        label: 'կացարանի տարածքը փոքր է',
        hasComment: false,
      },
      {
        id: 16,
        label:
          'Կացարանի մատչելիությունն ապահովված չէ (օրինակ՝ հաշմանդամություն ունեցող անձանց,տեղաշարժման դժվարություններ ունեցող անձանց համար)',
        hasComment: false,
      },
      {
        id: 17,
        label:
          'Կացարանում կոմունալ կենցաղային տարրական պայմանները բացակայում են',
        hasComment: false,
      },
      {
        id: 18,
        label: 'Այլ',
        hasComment: true,
      },
    ],
  },
  {
    id: 19,
    label: 'Այլ',
    hasComment: true,
  },
  {
    id: 20,
    label: 'Դժվարանում է պատասխանել',
    hasComment: false,
  },
]

export const reuniteOptions = [
  {
    id: 1,
    label: 'Այո',
  },
  {
    id: 2,
    label: 'Ոչ',
  },
  {
    id: 3,
    label: 'Դժվարանում է պատասխանել',
  },
  {
    id: 4,
    label: 'Կիրառելի չէ',
  },
]

export const deathReasons = [
  {
    label: 'Ռազմական գործողության',
    id: 1,
    hasComment: false,
  },
  {
    label: 'Պայթյունի',
    id: 2,
    hasComment: false,
  },
  {
    label: 'Գերեվարման',
    id: 3,
    hasComment: false,
  },
  {
    label: 'Բռնի տեղահանման',
    id: 4,
    hasComment: false,
  },
  {
    label: 'Բժշկական աջակցության, դեղորայքի պակասի պատճառով',
    id: 5,
    hasComment: false,
  },
  {
    label: 'Բնական մահ/ծերության մահ',
    id: 6,
    hasComment: false,
  },
  {
    label: 'Հուզված է և չի կարողանում պատասխանել',
    id: 7,
    hasComment: false,
  },
  {
    label: 'Այլ',
    id: 8,
    hasComment: true,
  },
]
