import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { URL_VIOLENCE } from '../constants'
import { Form } from '../../../form/form'
import { FormItem } from '../../../form/form-item'
import { Checkbox } from 'antd'
import { Info } from '../../../typography/info'
import ManifestationForm from './manifestation'
import { FormSectionCard } from '../../../card/form-section-card'

const createName = (id) => `item${id}`

const ViolenceForm = ({ title = '', parentFormItem }) => {
  const { data } = useGetQuestions(URL_VIOLENCE)
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)

  useEffect(() => {
    // item19 - Սոցիալական առանձին խումբ չունի
    if (checkedIds?.item19 && Object.keys(checkedIds).length > 1) {
      form.setFields([
        {
          name: [...parentFormItem, 'personSocialStatuses'],
          value: { item19: { id: 19 } },
        },
      ])
      setCheckedIds({ item19: true })
    } else if (checkedIds?.item19) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [checkedIds, form, parentFormItem])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [parentFormItem, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form, parentFormItem],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[itemName]}
            style={{ marginLeft }}
            label={
              !!(level === 2 && item.id === 13) && (
                <Info
                  text={
                    'ՑՈՒՑՈՒՄ. Հարցը տալ, եթե հարցվողը հարմար է զգում պատասխանել։ Եթե իրավիջակից ելնելով կարծում եք, որ անձը կարող է անհարմար զգալ կամ իրեն կարող է վտանգ սպառնալ, եթե տաք հաջորդ հարցերը, ապա մի հարցրեք, նշումներ արեք ձեր դիտարկումների մասին համապատասխան դաշտում'
                  }
                />
              )
            }
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={disableCheckboxes && item.id !== 19}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          <FormItem name={[parentFormItem, itemName, 'id']} hidden />
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
          {!!(
            item.id === 1 ||
            item.id === 2 ||
            item.id === 11 ||
            item.id === 12
          ) &&
            checkedIds[itemName] && (
              <ManifestationForm parentFormItem={[parentFormItem, itemName]} />
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard showRequired={false} title={title ? title : null}>
      <Info text="ՑՈՒՑՈՒՄ․ Եթե հարմար եք զգում, խնդրում եմ նշել, թե ինչ բնույթի գենդերային հիմքով (այդ թվում բռնություն) բռնության եք ենթարկվել կամ բռնության է ենթարկել տան այլ անդամ" />
      {renderItems(data, 1)}
    </FormSectionCard>
  )
}

export default ViolenceForm
