import { Form, Radio, Row } from 'antd'
import { FormItem } from '../../form/form-item'
import React, { useState } from 'react'
import { COLORS } from '../../../helpers/constants'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_GET_FOOD_ACTION } from './constant'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions from '../../../api/application/use-get-questions'
import { BoldText } from '../../typography/text'

export const NotEnoughMoneyHousehold = () => {
  const form = Form.useFormInstance()
  const [foodActionsValue, setFoodActions] = useState([])
  const { data: questions } = useGetQuestions(URL_GET_FOOD_ACTION)

  form.setFieldsValue({ foodActionsValue: foodActionsValue })

  const handleRadioChange = (item, selectedValue) => {
    const selectedResponse = {
      id: item.id,
      // label: item.label,
      answerId: selectedValue,
    }

    setFoodActions((prevState) => {
      const updatedActions = prevState.filter((action) => action.id !== item.id)
      return [...updatedActions, selectedResponse]
    })
  }

  return (
    <FormSectionCard
      title={
        'Վերջին 30 օրվա ընթացքում, Ձեր տնային տնտեսության անդամներից որևէ մեկը ստիպված եղե՞լ է կատարել ստորև թվարկված գործողություններից որևէ մեկն այն պատճառով, որ տնային տնտեսությունում չի եղել բավականաչափ սնունդ կամ բավականաչափ փող/գումար սնունդ գնելու համար'
      }
      showRequired={false}
    >
      <FormItem hidden initialValue={[]} name={'foodActionsValue'}></FormItem>
      <Row>
        <FormItem name={'foodActions'}>
          {questions.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <FormItem
                  name={['foodActions', item.label]}
                  rules={[{ required: true }]}
                  label={
                    <BoldText color={COLORS.PRIMARY.BLUE}>
                      {item.id}. {item.name}
                    </BoldText>
                  }
                >
                  <Radio.Group
                    onChange={(e) => {
                      handleRadioChange(item, e.target.value)
                    }}
                    style={{ marginLeft: 25 }}
                  >
                    <FormItem
                      name={'answerId'}
                      rules={[{ required: true, message: '' }]}
                    >
                      <VerticalSpace direction="vertical">
                        <Radio value={1}>
                          Ոչ, որովհետև սննդի պակաս չեմ զգացել
                        </Radio>
                        <Radio value={2}>
                          Ոչ, որովհետև վերջին 12 ամիսների ընթացքում արդեն
                          իրականացրել եմ այդ գործողությունը
                        </Radio>
                        <Radio value={3}>Այո</Radio>
                        <Radio value={4}>Կիրառելի չէ (ՉԿԱՐԴԱԼ)</Radio>
                      </VerticalSpace>
                    </FormItem>
                  </Radio.Group>
                </FormItem>
              </React.Fragment>
            )
          })}
        </FormItem>
      </Row>
    </FormSectionCard>
  )
}
