export const URL_GET_FOOD_SOURCE = 'api/Resource/GetFoodSource'
export const URL_GET_FOOD_ACTION = 'api/Resource/GetFoodAction'
export const URL_GET_FOOD_STRATEGY = 'api/Resource/GetFoodStrategy'

export const foodSupplies = [
  {
    id: 1,
    label: 'Սննդի պաշար ընդհանրապես չունի',
  },
  {
    id: 2,
    label: 'Ունի միայն այսօրվա համար',
  },
  {
    id: 3,
    label: 'Սննդի պաշարը շատ չէ, բայց գնելու խնդիր չունի',
  },
  {
    id: 4,
    label: 'Մինչև 7 օր',
  },
  {
    id: 5,
    label: '8-14 օր',
  },
  {
    id: 6,
    label: '15-ից մեկ ամիս',
  },
  {
    id: 7,
    label: 'Մեկ ամսից ավելի',
  },
  {
    id: 8,
    label: 'Դժվարանում է պատասխանել',
  },
]

export const followingStrategies = [
  {
    label: 'Բավարարվել ավելի քիչ նախընտելի և ավելի էժան սնունդով',
    name: 'cheaperFood',
    id: 1,
  },
  {
    label:
      'Պարտքով սնունդ ձեռք բերել կամ դիմել հարազատի/ների և ընկերոջ/ների օգնությանը',
    name: 'buyingFoodCredit',
    id: 2,
  },
  {
    label: 'Ուտելիքի չափաբաժինը սահմանափակել',
    name: 'limitFood',
    id: 3,
  },
  {
    label:
      'Սահմանափակել մեծերի կողմից սննդի սպառումը, որպեսզի փոքր երեխաները սնվեն',
    name: 'limitAdultsFood',
    id: 4,
  },
  {
    label:
      'Նվազեցնել մեկ օրվա ընթացքում ընդունվող ճաշի քանակը (նախաճաշ, ճաշ, ընթրիք)',
    name: 'reduceNumberMeals',
    id: 5,
  },
]

export const numberOfDays = [
  {
    label: 0,
    id: 0,
  },
  {
    label: 1,
    id: 1,
  },
  {
    label: 2,
    id: 2,
  },
  {
    label: 3,
    id: 3,
  },
  {
    label: 4,
    id: 4,
  },
  {
    label: 5,
    id: 5,
  },
  {
    label: 6,
    id: 6,
  },
  {
    label: 7,
    id: 7,
  },
]

export const checkboxInfo = [
  {
    id: 11,
    label: 'Կանխիկ',
    name: 'useCash',
  },
  {
    id: 12,
    label: 'Անկանխիկ միջոցով (քարտ, հավելված և այլն)',
    name: 'useCashless',
  },
  {
    id: 13,
    label: 'Փոխառումով (ապառիկ) եմ վերցրել գնումը, չեմ վճարել',
    name: 'useCredit',
  },
]
