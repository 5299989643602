import React, { useCallback, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Title } from '../../typography'
import { FormItem } from '../../form/form-item'
import useGetQuestions from '../../../api/application/use-get-questions'
import {
  GET_OBSERVATION_DIRECTION,
  observationDirectionAnswers,
} from './constants'
import { Form, Spin } from 'antd'
import { COLORS } from '../../../helpers/constants'
import { Checkbox } from '../../checkbox'

export const ObservationDirection = () => {
  const [incomeTypeValue] = useState([])
  const form = Form.useFormInstance()

  form.setFieldsValue({ incomeTypeValue: incomeTypeValue })

  const { data: questions, isLoading } = useGetQuestions(
    GET_OBSERVATION_DIRECTION,
  )
  const handleCheckboxChange = useCallback(
    (questionId, answerId, checked) => {
      const currentValue =
        form.getFieldValue('householdObservationDirections') || []
      const updatedValue = checked
        ? [...currentValue, { id: questionId, answerId }]
        : currentValue.filter((item) => item && item.id !== questionId)
      form.setFieldsValue({ householdObservationDirections: updatedValue })
    },
    [form],
  )

  const renderItems = (items, question) => {
    return items.map((item) => {
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['householdObservationDirections', item.id, 'answerId']}
          >
            <Checkbox
              onChange={(e) =>
                handleCheckboxChange(question.id, item.id, e.target.checked)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionCard title="Դիտարկման արդյունքները" showRequired={false}>
      <Spin spinning={isLoading}>
        <Title>
          Սոցիալական աշխատողի կողմից իրականացվող դիտարկման ուղղությունները
        </Title>
        <Title style={{ marginBottom: 20 }}>Փոխհարաբերությունները </Title>
        {questions.map((question) => {
          return (
            <FormItem
              rules={[{ required: true }]}
              name={'householdObservationDirections'}
              key={question.id}
            >
              <input hidden />
              <React.Fragment>
                <Title
                  style={{ marginTop: 20, marginBottom: 20 }}
                  color={COLORS.PRIMARY.BLUE}
                >
                  {question.id}. {question.name}
                </Title>
                {renderItems(observationDirectionAnswers, question)}
              </React.Fragment>
            </FormItem>
          )
        })}
      </Spin>
    </FormSectionCard>
  )
}
