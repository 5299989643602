import React, { useEffect } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { URL_ACCOMMONDATION_STAY_PERIOD } from './constants'
import { Input } from '../../input'
import useGetQuestions from '../../../api/application/use-get-questions'

const StayPeriod = () => {
  const { data, isLoading } = useGetQuestions(URL_ACCOMMONDATION_STAY_PERIOD)

  const selectedId = Form.useWatch(['accommodationStayPeriodId'])
  const form = Form.useFormInstance()

  useEffect(() => {
    form.resetFields(['accommodationStayPeriodComment'])
  }, [selectedId, form])

  return (
    <FormSectionCard
      title="Որքա՞ն ժամանակ կարող եք բնակվել այս կացարանում"
      showRequired={false}
    >
      <Spin spinning={isLoading}>
        <FormItem name="accommodationStayPeriodId" rules={[{ required: true }]}>
          <Radio.Group>
            <VerticalSpace>
              {data.map((row) => (
                <Radio key={row.id} value={row.id}>
                  {row.name}
                </Radio>
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>

        {selectedId === 7 && (
          <FormItem
            name="accommodationStayPeriodComment"
            rules={[{ required: true }]}
          >
            <Input placeholder="Նշել" />
          </FormItem>
        )}
      </Spin>
    </FormSectionCard>
  )
}

export default StayPeriod
