import { Layout } from 'antd'
import styled from 'styled-components'

const { Content } = Layout

const WrapperContent = styled(Content)`
  margin: 50px 0px 0px 0px;
`

export const VerticalFormSpace = (props) => <WrapperContent {...props} />
