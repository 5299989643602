import { Button, Checkbox, Divider, Form, Space } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
import { FamilyPersons } from '../../../select/family-persons'
import { MinusCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import React from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { URL_NON_SAFETY_TYPES } from '../constants'
import CheckboxWithComment from './checkboxe-with-comment'
import { Title } from '../../../typography'

const NonSafetyTypeReasons = () => {
  const { data } = useGetQuestions(URL_NON_SAFETY_TYPES)

  return (
    <Form.List name="householdNonSafetyPeople" initialValue={['']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <VerticalSpace key={key}>
              <Space>
                <Form.Item
                  {...restField}
                  style={{ marginBottom: 0 }}
                  name={[name, 'personId']}
                  rules={[{ required: true }]}
                >
                  <FamilyPersons name="householdNonSafetyPeople" />
                </Form.Item>

                {fields.length > 1 ? (
                  <MinusCircleFilled
                    style={{ fontSize: 24, color: '#456FAE' }}
                    className="dynamic-delete-button"
                    onClick={() => remove(name)}
                  />
                ) : null}
              </Space>

              <Form.Item
                label={<Title>Խնդրում եմ նկարագրել իրավիճակը</Title>}
                name={[name, 'personNonSafetyTypes']}
                rules={[{ required: true }]}
              >
                <Checkbox.Group>
                  <VerticalSpace>
                    {data.map((type) => (
                      <CheckboxWithComment
                        row={type}
                        key={type.id}
                        formItemName={[name]}
                      />
                    ))}
                  </VerticalSpace>
                </Checkbox.Group>
              </Form.Item>
              <Divider />
            </VerticalSpace>
          ))}

          <Form.Item>
            <Button type="primary" onClick={() => add()}>
              <PlusCircleOutlined />
              Ավելացնել ընտանիքի անդամ
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default NonSafetyTypeReasons
