export const GET_TERMINATION_REASON = 'api/Resource/Page15/GetTerminationReason'
export const GET_INFORMATION_AFFECTED_FACTOR =
  'api/Resource/Page15/GetInformationAffectedFactor'
export const GET_OBSERVATION_DIRECTION =
  'api/Resource/Page15/GetObservationDirection'
export const GET_RESIDENCE_CONDITION =
  'api/Resource/Page15/GetResidenceCondition'
export const GET_WORKER_ACTION = 'api/Resource/Page15/GetWorkerAction'

export const observationDirectionAnswers = [
  {
    id: 1,
    name: 'Դրական/աջակցային',
    label: 'observationDirection1',
    hasComment: false,
    children: [],
  },
  {
    id: 2,
    name: 'Լարված',
    label: 'observationDirection2',
    hasComment: false,
    children: [],
  },
  {
    id: 3,
    name: 'Կոնֆլիկտային',
    label: 'observationDirection3',
    hasComment: false,
    children: [],
  },
  {
    id: 4,
    name: 'Բռնությամբ (այդ թվում անտեսում)',
    label: 'observationDirection4',
    hasComment: false,
    children: [],
  },
  {
    id: 5,
    name: 'Չի նկատել ոչինչ',
    label: 'observationDirection5',
    hasComment: false,
    children: [],
  },
  {
    id: 6,
    name: 'Տվյալ հարաբերությունները հարցման ընթացքում հնարավոր չի եղել դիտարկել',
    label: 'observationDirection6',
    hasComment: false,
    children: [],
  },
  {
    id: 7,
    name: 'Կիրառելի չէ',
    label: 'observationDirection7',
    hasComment: false,
    children: [],
  },
]
export const anyFamilyMember = [
  {
    id: 1,
    name: 'Ունի ճնշված կամ ահաբեկված/վախեցած տեսք',
    label: 'isDepressed',
    hasComment: false,
    children: [],
  },
  {
    id: 2,
    name: 'Կրում է տարածքի ջերմաստիճանին/եղանակին ոչ համապատասխան հագուստ',
    label: 'isInappropriateCloth',
    hasComment: false,
    children: [],
  },
  {
    id: 3,
    name: 'Մարմնի վրա առկա են կապտուկներ',
    label: 'isBruises',
    hasComment: false,
    children: [],
  },
  {
    id: 4,
    name: 'Տեղաշարժման ազատությունը սահմանափակված է',
    label: 'isMovementRestricted',
    hasComment: false,
    children: [],
  },
  {
    id: 5,
    name: 'Անվտանգության համար անհանգստության այլ պատճառներ կան',
    label: 'IsOtherReasonsToWorryAboutSecurity',
    hasComment: true,
    children: [],
  },
  {
    id: 6,
    name: 'Վերոնշյալ որևէ դատողությունը կիրառելի չէ, խնդիր չի նկատվել',
    label: 'IsNoneAboveJudgmentsApply',
    hasComment: false,
    children: [],
  },
]
