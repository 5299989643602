/* eslint-disable indent */
import { Layout as LayoutComponent } from 'antd'

import styled from 'styled-components'
import { Header } from '../layouts/header'
import { Outlet } from 'react-router-dom'
import { SubHeader } from '../layouts/sub-header'

const Layout = styled(LayoutComponent)`
  background: #fdfdfd;
`

export const MainRoutes = ({ subHeaderText = '', showSubHeader = true }) => {
  return (
    <Layout>
      <Header />
      {showSubHeader && <SubHeader text={subHeaderText} />}
      <Outlet />
    </Layout>
  )
}
