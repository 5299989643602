import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import PeopleCommunication from './people-communication'
import LanguagePart from './language-part'
import DrivingLicense from './driving-license'
import RequestMemberName from './request-member-name'
import { Form } from 'antd'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_HOUSE_HOLD_PERSONS } from '../sixth-step/constants'

const EleventhStep = () => {
  const form = Form.useFormInstance()

  const { data: dataFamily } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )

  return (
    <VerticalSpace>
      <PeopleCommunication dataFamily={dataFamily} />
      <LanguagePart dataFamily={dataFamily} />
      <DrivingLicense dataFamily={dataFamily} />
      <RequestMemberName dataFamily={dataFamily} />
    </VerticalSpace>
  )
}

export default EleventhStep
