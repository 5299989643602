import { Checkbox } from 'antd'
import { Input } from '../../../input'
import { Form } from '../../form'
import { FormItem } from '../../form-item'
import React, { useCallback, useEffect, useState } from 'react'
import { accommodationTypes } from '../../../fill-application/constatns'
import { SecondaryText } from '../../../typography'

const createName = (id) => `item${id}`

const AccommodationType = ({ field, parentFormItem }) => {
  const [checkedIds, setCheckedIds] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)
  const form = Form.useFormInstance()

  useEffect(() => {
    // item15- Տեղեկություն չունեմ
    if (checkedIds?.item15 && Object.keys(checkedIds).length > 1) {
      form.setFields([
        {
          name: ['householdNotResidePersonAccommodationTypes'],
          value: { item15: { id: 15 } },
        },
      ])
      setCheckedIds({ item15: true })
    } else if (checkedIds?.item15) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [form, checkedIds])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [
            ...parentFormItem,
            field.name,
            'householdNotResidePersonAccommodationTypes',
            itemName,
            'id',
          ],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [
              field.name,
              'householdNotResidePersonAccommodationTypes',
              itemName,
              'comment',
            ],
            value: '',
          },
        ])
      }
    },
    [form, parentFormItem, field.name],
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[
              field.name,
              'householdNotResidePersonAccommodationTypes',
              itemName,
            ]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={disableCheckboxes && item.id !== 15}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.label}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[
                field.name,
                'householdNotResidePersonAccommodationTypes',
                itemName,
                'comment',
              ]}
              rules={[{ required: true }]}
              style={{ marginLeft }}
              label={item?.inputLabel || ''}
            >
              <Input placeholder="Նշել" />
            </FormItem>
          )}

          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  return (
    <FormItem
      name={[
        ...parentFormItem,
        field.name,
        'householdNotResidePersonAccommodationTypes',
      ]}
      label={
        <SecondaryText>
          Նշել կացարանի տեսակը, որում նա/նրանք բնակվում են
        </SecondaryText>
      }
    >
      {renderItems(accommodationTypes)}
    </FormItem>
  )
}

export default AccommodationType
