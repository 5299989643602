/* eslint-disable no-unused-vars */
import { Select } from './index'
import { createOptionsFromArray } from '../../helpers/utils'
import { FormItem } from '../form/form-item'
import useGetQuestions from '../../api/application/use-get-questions'
import { URL_GET_EDUCATION } from '../fill-application/second-step/constants'
import { SmallText, Text } from '../typography'

export const EducationSelect = ({ formItemProp }) => {
  const { data } = useGetQuestions(URL_GET_EDUCATION)

  return (
    <>
      <FormItem
        wrapperCol={{ span: 8 }}
        label={
          <Text>
            Կրթությունը{' '}
            <SmallText>
              (տվյալ պահին ձեռքբերված կրթական ամենաբարձր աստիճանը)
            </SmallText>
          </Text>
        }
        name={formItemProp}
        rules={[{ required: true }]}
      >
        <Select options={createOptionsFromArray(data, 'name')} />
      </FormItem>
    </>
  )
}
