import React, { useCallback, useState } from 'react'
import useGetQuestions from '../../../api/application/use-get-questions'
import { GET_WORKER_ACTION } from './constants'
import { FormSectionCard } from '../../card/form-section-card'
import { Flex, Form, Radio, Row, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { SecondaryText, Title } from '../../typography'
import { TextArea } from '../../input'
import { VerticalSpace } from '../../space/vertical-space'
import { Button } from '../../button'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { COLORS } from '../../../helpers/constants'
import NestedCheckboxes from './nested-checkboxes'

export const Summary = () => {
  const form = Form.useFormInstance()
  const { data: answers, isLoading } = useGetQuestions(GET_WORKER_ACTION)

  const [value, setValue] = useState(null)
  const [textareas, setTextareas] = useState([
    { id: 1, placeholder: 'Նշել', note: '' },
    { id: 2, placeholder: 'Նշել', note: '' },
    { id: 3, placeholder: 'Նշել', note: '' },
  ])
  const [nextId, setNextId] = useState(4) // Track the next unique ID

  const handleTxtAreaNote = (e, id) => {
    const updatedTextareas = textareas.map((textarea) =>
      textarea.id === id ? { ...textarea, note: e.value } : textarea,
    )
    setTextareas(updatedTextareas)
  }
  const deleteTextarea = (id) => {
    setTextareas(textareas.filter((textarea) => textarea.id !== id))
  }
  const addTextarea = () => {
    const newTextareas = [
      ...textareas,
      { id: nextId, placeholder: 'Նշել', note: '' },
    ]
    setTextareas(newTextareas)
    setNextId(nextId + 1)
  }

  const onCheckboxChange = useCallback(
    (checked, row) => {
      if (checked) {
        if (!row.children.length) {
          form.setFieldsValue({
            householdWorkerActions: [
              ...form.getFieldValue('householdWorkerActions'),
              { id: row.id, comment: null },
            ],
          })
        }
      } else {
        form.setFieldsValue({
          householdWorkerActions: form
            .getFieldValue('householdWorkerActions')
            .filter((r) => r.id !== row.id),
        })
      }
    },
    [form],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdWorkerActions')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('householdWorkerActions')]
      newData[index].comment = value
      form.setFieldsValue({ householdWorkerActions: newData })
    },
    [form],
  )

  return (
    <FormSectionCard title="Ամփոփում" showRequired={false}>
      <Spin spinning={isLoading}>
        <FormItem rules={[{ required: true }]} name="hasUrgentNeed">
          <Radio.Group
            value={value}
            rules={[{ required: true }]}
            onChange={(e) => {
              setValue(e.target.value)
            }}
          >
            <VerticalSpace>
              <Radio value={true}>Ընտանիքն ունի հրատապ կարիք</Radio>
              <Radio value={false}>Ընտանիքը հրատապ կարիք չունի</Radio>
            </VerticalSpace>
            {value && (
              <>
                <FormItem>
                  <SecondaryText>
                    Խնդրում ենք առաջնահերթությամբ առանձնացնել 3 հրատապ լուծում
                    պահանջող կարիքները, որ ունի ընտանիքը
                  </SecondaryText>
                </FormItem>
                {textareas?.map((item) => (
                  <FormItem
                    name={[`urgentNeedDescription` + item?.id]}
                    rules={[{ required: true }]}
                    key={item.id}
                  >
                    <Flex style={{ alignItems: 'flex-end' }}>
                      <TextArea
                        onChange={(e) => handleTxtAreaNote(e.target, item?.id)}
                        placeholder={item.placeholder}
                        showRequired={true}
                      />
                      {textareas.length > 3 && (
                        <Button
                          onClick={() => deleteTextarea(item.id)}
                          style={{ borderRadius: '0', marginLeft: '10px' }}
                        >
                          <MinusOutlined
                            style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
                          />
                        </Button>
                      )}
                    </Flex>
                  </FormItem>
                ))}
                <Button
                  onClick={(text) => addTextarea(text)}
                  style={{ borderRadius: '0' }}
                >
                  <PlusOutlined
                    style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
                  />
                </Button>
              </>
            )}
          </Radio.Group>
        </FormItem>
        <Row>
          <React.Fragment>
            <FormItem
              rules={[{ required: true }]}
              initialValue={[]}
              name={'householdWorkerActions'}
            >
              <Title>
                Կարիքների գնահատման ընթացքում ի՞նչ գործողություններ եք կատարել
              </Title>
              {answers.map((row) => (
                <FormItem
                  name={['householdWorkerActions', 'id']}
                  key={row.id}
                  style={{ marginTop: 20 }}
                >
                  <VerticalSpace>
                    <NestedCheckboxes
                      row={row}
                      onCheckboxChange={onCheckboxChange}
                      onInputChange={onInputChange}
                      level={0}
                    />
                  </VerticalSpace>
                </FormItem>
              ))}
            </FormItem>
          </React.Fragment>
        </Row>
      </Spin>
    </FormSectionCard>
  )
}
