import { Navigate } from 'react-router-dom'
import { useAuth } from '../../context/auth-context'
import { ContentWithBg } from '../layouts/content-with-bg'
import { PATHS } from '../../helpers/constants'

export const PublicRoutes = () => {
  const { user } = useAuth()

  if (user) {
    return <Navigate to={PATHS.ROOT} replace />
  }

  return (
    <>
      <ContentWithBg />
    </>
  )
}
