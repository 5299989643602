export const URL_GET_INCOME_TYPE = 'api/Resource/Page9/GetIncomeType'
export const URL_GET_GOOD = 'api/Resource/Page9/GetGood'
export const URL_GET_ACTION = 'api/Resource/Page9/GetAction'

export const averageMonthlyIncome = [
  {
    id: 1,
    label: 'Մինչև 50,000 ՀՀ դրամ',
  },
  {
    id: 2,
    label: '51,000-ից 100,000 ՀՀ դրամ',
  },
  {
    id: 3,
    label: '101,000-ից 200,000 ՀՀ դրամ',
  },
  {
    id: 4,
    label: '201,000-ից 400,000 ՀՀ դրամ',
  },
  {
    id: 5,
    label: '401, 000-ից 600,000 ՀՀ դրամ',
  },
  {
    id: 6,
    label: '601,000 ՀՀ դրամ և ավել',
  },
  {
    id: 7,
    label: 'Հրաժարվում եմ պատասխանել',
  },
  {
    id: 8,
    label: 'Չգիտի',
  },
]

export const subItems = [
  {
    label: 'Մինչև 1 շաբաթ',
    id: 1,
  },
  {
    label: '1-2 շաբաթ',
    id: 2,
  },
  {
    label: '3 շաբաթից մինչև 1 ամիս',
    id: 3,
  },
  {
    label: '1-3 ամիս',
    id: 4,
  },
  {
    label: '3-6 ամիս',
    id: 5,
  },
  {
    label: '6-12 ամիս',
    id: 6,
  },
  {
    label: '1 տարուց ավել',
    id: 7,
  },
]
