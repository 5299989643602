import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import FamilyMemberOccupancy from '../../form/application/family/family-member-occupancy-form'

const EighthStepForm = () => {
  return (
    <VerticalSpace>
      <FamilyMemberOccupancy />
    </VerticalSpace>
  )
}

export default EighthStepForm
