import React, { useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Col, Form, Radio, Row } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { Input } from '../../input'
import { Select } from '../../select'
import { Button } from '../../button'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

const PeopleCommunication = ({ dataFamily }) => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(null)

  const radioPeopleCommunication = Form.useWatch('isDifficultyLanguage', {
    preserve: true,
  })

  return (
    <FormSectionCard
      title="Ընտանիքում կա՞ անձ, ով հայերենով և բարբառով կամ օտար լեզվով պայմանավորված դժվարություններ է ունենում այլ անձանց հետ հաղորդակցվելիս"
      showRequired={false}
    >
      <Row>
        <FormItem
          name={'isDifficultyLanguage'}
          style={{ width: '100%' }}
          rules={[{ required: true }]}
        >
          <Radio.Group
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              form.resetFields(['householdDifficultyLanguages'])
            }}
            style={{ width: '100%' }}
          >
            <VerticalSpace>
              <Radio value={true}>Այո</Radio>
              {radioPeopleCommunication === true ? (
                <Form.List
                  name="householdDifficultyLanguages"
                  initialValue={[{}]}
                >
                  {(fields, { add, remove }) =>
                    fields.map((field, index) => (
                      <Row
                        gutter={16}
                        justify={'center'}
                        key={index}
                        style={{ marginLeft: '20px' }}
                      >
                        <Col
                          sm={24}
                          lg={20}
                          xl={11}
                          style={{ alignSelf: 'center' }}
                        >
                          <FormItem
                            name={[field.name, 'personId']}
                            rules={[{ required: true }]}
                          >
                            <Select
                              placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                            >
                              {dataFamily?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.firstName + ' ' + item.lastName}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col
                          sm={24}
                          lg={20}
                          xl={11}
                          style={{ alignSelf: 'center' }}
                        >
                          <FormItem
                            rules={[{ required: true }]}
                            name={[field.name, 'reason']}
                            style={{ marginLeft: '25px', width: '350px' }}
                          >
                            <Input placeholder={'Նշել պատճառը'} />
                          </FormItem>
                        </Col>
                        {fields.length > 1 && index !== 0 ? (
                          <Col
                            span={2}
                            style={{
                              alignSelf: 'center',
                              marginBottom: '25px',
                            }}
                          >
                            <Button
                              style={{ borderRadius: '0' }}
                              onClick={() => {
                                remove(field.name)
                              }}
                            >
                              <MinusOutlined />
                            </Button>
                          </Col>
                        ) : (
                          <Col
                            span={2}
                            style={{
                              alignSelf: 'center',
                              marginBottom: '25px',
                            }}
                          >
                            <Button
                              style={{ borderRadius: '0' }}
                              onClick={() => add()}
                            >
                              <PlusOutlined
                                style={{
                                  padding: 0,
                                }}
                              />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    ))
                  }
                </Form.List>
              ) : (
                <FormItem hidden />
              )}
              <Radio value={false}>Ոչ</Radio>
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
      </Row>
    </FormSectionCard>
  )
}

export default PeopleCommunication
