import React, { useCallback, useEffect, useState } from 'react'
import { FormItem } from '../../form/form-item'
import { utilityProducts } from '../constatns'
import { Checkbox } from '../../checkbox'
import { Form, InputNumber, Radio } from 'antd'
import { Input } from '../../input'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { Title } from '../../typography'

const createName = (id) => `item${id}`

const UtilityNeeds = () => {
  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)
  const hasHousekeepingNeeds = Form.useWatch('hasHousekeepingNeedsRadio', {
    preserve: true,
  })
  useEffect(() => {
    if (checkedIds?.item52 && Object.keys(checkedIds).length > 1) {
      form.setFields([
        {
          name: ['products'],
          value: { item52: { id: 52 } },
        },
      ])
      setCheckedIds({ item52: true })
    } else if (checkedIds?.item52) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [checkedIds, form])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['products', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['products', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['products', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              disabled={disableCheckboxes && item.id !== 52}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  item.children,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={['products', itemName, 'comment']}
              label={item.label || 'Նշել '}
              style={{ marginLeft }}
              rules={[{ required: true }]}
            >
              {item.isCommentNumeric ? <InputNumber min={1} /> : <Input />}
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  const handleRadioChange = (value) => {
    if (!value) {
      form.resetFields(['hasHousekeepingNeeds'])
      setCheckedIds({})
    }
  }

  return (
    <FormSectionFullCard>
      <FormItem
        name={'hasHousekeepingNeedsRadio'}
        label={
          <Title>
            Արդյո՞ք կենցաղի կազմակերպման հարցում ունեք կարիքներ, այդ թվում՝
            սնունդ, դեղեր, տան կահավորանք և պարագաներ.
          </Title>
        }
        rules={[{ required: true }]}
      >
        <Radio.Group onChange={(e) => handleRadioChange(e.target.value)}>
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {hasHousekeepingNeeds && renderItems(utilityProducts)}
    </FormSectionFullCard>
  )
}

export default UtilityNeeds
